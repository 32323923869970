import React, { useState, useRef } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Tooltip, NumberInput, NumberInputProps } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { DangerIcon } from 'Tokens/Icons/Status';
import { Typography } from 'Tokens';
import { useHandleMarketBlur, useInvestmentValidation } from './PortfolioFinancials.hooks';

export const MarketValueInput = React.memo(
  (
    props: NumberInputProps & {
      isAuditor?: boolean;
      isYear?: boolean;
      minValue: number;
    }
  ) => {
    const [localValue, setLocalValue] = useState<number>(props.value ? Number(props.value) : 0);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('portfolio');

    const { error } = useInvestmentValidation({
      value: localValue,
      maxValue: props.minValue,
      isYear: props.isYear,
    });

    const handleBlur = useHandleMarketBlur();

    return (
      <Tooltip
        label={
          !props.isAuditor && !props.isYear ? t('portfolio:investmentValue.editTooltipYearly') : ''
        }
      >
        <Box display="flex" flexDirection="column">
          <NumberInput
            isInvalid={!!error}
            {...props}
            value={localValue}
            onChange={(value) => setLocalValue(isNaN(value) ? 0 : Number(value))}
            ref={inputRef}
            format={(value) => value.toLocaleString('en', { useGrouping: true })}
            onBlur={() =>
              handleBlur(localValue, {
                id: props.id,
                value: props.value ? Number(props.value) : undefined,
              })
            }
          />
          {error && (
            <HStack mt="4px" gap="4px" alignItems="flex-start" width="80%">
              <DangerIcon color="text.critical" boxSize="10px" mt="2px" />
              <Typography color="text.critical" variant="detail">
                {error}
              </Typography>
            </HStack>
          )}
        </Box>
      </Tooltip>
    );
  }
);
