import { Box, VStack, useDisclosure } from '@chakra-ui/react';
import { ContentLayout, Modal } from 'Molecules';
import { ActionFields, DREnums, DRQuestionsTable, MDR, MDRControllerType } from 'containers/Esrs';
import { DocumentationFileDetailsFragment_, ShortUser } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionGeneralInformation } from './ActionGeneralInformation';
import { ActionTimeline } from './ActionTimeline';
import {
  getEstimates,
  getReportingUnits,
  getSubsidiaries,
  getTargets,
  useDeleteAction,
  useGetAddActionData,
  useUpsertAction,
} from '../Actions.hooks';
import { useUserData } from '@nhost/react';
import { Button } from 'Atoms';
import { ActionDocumentation } from './ActionDocumentation';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { RelatedTargets } from './RelatedTargets';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { getDisclosureRequirementLink } from 'utils/links';
import { useCurrentCompany } from 'utils/hooks';
import { Typography } from 'Tokens';

export const AddActionPage = () => {
  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
    actionId,
  } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState<string | null>();
  const [filesAttached, setFilesAttached] = useState<DocumentationFileDetailsFragment_[]>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const company = useCurrentCompany();
  const user: ShortUser | null = useUserData();
  const upsertAction = useUpsertAction();
  const deleteAction = useDeleteAction();
  const companyCurrency = useMemo(() => company.company?.currency, [company]);

  const {
    action,
    questions,
    targetOptions,
    targetsData,
    subsidiaries,
    reportingUnits,
    materialityAssessmentId,
    isGroup,
    loading,
  } = useGetAddActionData();

  const { reset, handleSubmit, control, watch, setValue } = useForm<ActionFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      dueDate: new Date(),
      startDate: new Date(),
      reportingUnits: [],
      targets: [],
    },
  });
  const { isValid } = useFormState({ control });

  useEffect(() => {
    reset({
      title: action?.title ?? '',
      dueDate: action?.deadline ? new Date(action.deadline) : new Date(),
      startDate: action?.startDate ? new Date(action.startDate) : new Date(),
      ownerId: action?.ownerId ?? user?.id,
      timeHorizon: action?.timeHorizon,
      capex: action?.capex,
      noteReference: action?.noteReference,
      reportingUnits: getReportingUnits(action),
      subsidiaries: getSubsidiaries(action),
      targets: getTargets(action),
      estimates: getEstimates(action),
      answers: action?.answers,
      relatedIRO: action?.relatedIRO,
      relatedPolicy: action?.relatedPolicy,
      statusOfKeyAction: action?.statusOfKeyAction,
      scope: action?.scope,
      expectedOutcome: action?.expectedOutcome,
      provisionOfRemedy: action?.provisionOfRemedy,
      provisionOfRemedyExplanation: action?.provisionOfRemedyExplanation,
      progressInfo: action?.progressInfo,
      investmentsAndResources: action?.investmentsAndResources,
      hasPreconditions: action?.hasPreconditions,
      hasPreconditionsExplanation: action?.hasPreconditionsExplanation,
      opex: action?.opex,
      opexShortTerm: action?.opexShortTerm,
      opexMediumTerm: action?.opexMediumTerm,
      opexLongTerm: action?.opexLongTerm,
      capexShortTerm: action?.capexShortTerm,
      capexMediumTerm: action?.capexMediumTerm,
      capexLongTerm: action?.capexLongTerm,
      scopeValueChain: action?.scopeValueChain,
      coverage: action?.coverage,
      coverageExplanation: action?.coverageExplanation,
      sustainabilityMatter: action?.sustainabilityMatter,
      sustainabilityMatterExplanation: action?.sustainabilityMatterExplanation,
      statusOfKeyActionExplanation: action?.statusOfKeyActionExplanation,
    });
  }, [action]);

  const onSubmit = (data: ActionFields) => {
    upsertAction({
      data,
      actionToEdit: action,
      disclosureRequirementRef,
      userId: user?.id,
      isGroup,
      materialityAssessmentId,
      isDrawer: false,
      note,
      setNote,
      filesAttached,
      setFilesAttached,
    });
  };

  const backLink = useMemo(
    () =>
      getDisclosureRequirementLink({
        companyId,
        assessmentId: esrsAssessmentId,
        standardRef,
        disclosureRequirement: {
          reference: disclosureRequirementRef ?? '',
          type: DREnums.action,
        },
      }),
    [companyId, esrsAssessmentId, standardRef, disclosureRequirementRef]
  );

  return (
    <ContentLayout
      header={action?.title ?? 'Add action'}
      isLoading={loading}
      backButton
      onBackNavigate={() => navigate(backLink)}
      paddingBottom="0px !important"
      actions={
        actionId && (
          <Button leftIcon={<DeleteIcon />} size="md" variant="ghost" mr="8px" onClick={onOpen}>
            Delete
          </Button>
        )
      }
    >
      <VStack alignItems="flex-start" w="100%" flexGrow="1">
        <form onSubmit={handleSubmit(onSubmit)} id="actionForm" noValidate>
          <VStack
            alignItems="flex-start"
            maxW="796px"
            pt="16px"
            pl="0px"
            ml="16px"
            mb="16px"
            spacing="0px"
          >
            <Box width="552px">
              <ActionGeneralInformation
                control={control}
                isReadOnly={false}
                isGroup={isGroup}
                reportingUnits={reportingUnits}
                subsidiariesData={subsidiaries}
              />
            </Box>
            <ActionTimeline control={control} watch={watch} isReadOnly={false} />
            <Box
              position="relative"
              borderLeft="1px dashed"
              pl="32px"
              borderColor="border.hover"
              pb="40px"
              w="100%"
            >
              <StepLabels title="Minimum Disclosure Requirements for Actions (MDR-A)" ml="33px" />
              <Box>
                <Typography variant="h3">Scope and effectiveness</Typography>
                <MDR
                  control={control as MDRControllerType}
                  type="actionScope"
                  standardRef={standardRef}
                />
              </Box>
              <Box mt={'16px'}>
                <Typography variant="h3">Financial Information</Typography>
                <MDR
                  control={control as MDRControllerType}
                  type="actionFinancial"
                  unit={companyCurrency}
                  standardRef={standardRef}
                />
              </Box>
            </Box>
            {questions?.length && (
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
                w="100%"
              >
                <StepLabels title="Other disclosure requirements" ml="33px" />
                <DRQuestionsTable
                  questions={questions ?? []}
                  answers={watch('answers')}
                  setAnswers={(val) => setValue('answers', val)}
                />
              </Box>
            )}
            <RelatedTargets
              control={control}
              watch={watch}
              targetOptions={targetOptions}
              targetsData={targetsData}
            />
            <ActionDocumentation
              action={action}
              control={control}
              setNote={setNote}
              filesAttached={filesAttached}
              setFilesAttached={setFilesAttached}
              user={user}
            />
          </VStack>
        </form>
        <VStack
          position="sticky"
          bottom="0"
          left="0"
          right="0"
          w="calc(100% + 26px)"
          bg="bg.default"
          borderTop="1px solid"
          borderColor="border.decorative"
          borderBottomRadius="8px"
          padding="16px"
          alignItems="start"
          ml="-15px"
        >
          <Button variant="primary" isDisabled={!isValid} type="submit" form="actionForm" ml="42px">
            Done
          </Button>
        </VStack>
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Are you sure you want to delete this action?"
        size="xs"
        confirmVariant="destructive"
        confirmText="Delete"
        onConfirm={() => {
          if (actionId) deleteAction(actionId);
        }}
      >
        <></>
      </Modal>
    </ContentLayout>
  );
};
