import {
  Esrs_MaterialMetric_Insert_Input_,
  useEsrsAssessmentQuery,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetMetricAnswersQuery,
  useGetMetricReportStatusSubscription,
  useUpsertMaterialMetricsMutation,
  GetMetricAnswersQuery_,
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetReportMetricsPerDisclosureQuery_,
  QuestionType_Enum_,
  useGetReportGeneralDisclosureRequirementsQuery,
  TargetFieldsFragment_,
  useGetCompanyLevelMdrmAnswersQuery,
  GetCompanyLevelMdrmAnswersQuery_,
  useGetAssessmentCurrencyConversionQuery,
  GetAssessmentCurrencyConversionQuery_,
  useGetRequiredGroupMaterialMetricsQuery,
  useGetSubMaterialMetricsOnGroupLevelQuery,
  useGetSubAnswersOtherQuery,
  useGetSubAnswersEnvironmentQuery,
  useGetEsrsAssessmentShortDetailsQuery,
} from 'models';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CategoriesType,
  EsrsReportCategory,
  EsrsReportData,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
  MaterialReportDisclosure,
  MaterialReportMetric,
  MaterialReportStandard,
  ReportAnswers,
  ReportChildMetrics,
  ReportMetric,
  SimplifiedReportNarrativeMetric,
} from './Report.types';
import { useAssessmentReportingUnits } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  AggregatedMetricsTableData,
  DREnums,
  MaybeHasChild,
  SubsidiaryAssessmentsType,
  getMaterialMetricsTags,
  getNestedRows,
  isFullMetricOnGroupLevel,
  isFullMetricOnSubLevel,
} from '../DisclosureRequirements';
import { getMetricMdrmAnswers, getMetricResult } from './MetricAnswerCalculation';
import { QuestionType } from 'utils/scores/questions';
import { useCompanyType } from 'utils/hooks';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { DataCollectionLevel } from '../DataCollection';
import { StructureNode } from '../Assessment/Structure';
import { uniq, uniqBy } from 'lodash';
import { MARKED_GENERAL, toRomanNumeral } from './ReportUtils';
import {
  useGetEsrsReportStructure,
  useGetReportMaterialStandards,
} from './ReportCloudFunctions.hooks';
import { AssessableMetrics } from '../DisclosureRequirements/Metrics/Metrics';
import { environmentDRRefs, getIsMetricLocked, percentageToNumber } from 'containers/Esrs/utils';
import { nhost } from 'utils/nhost';
import { GroupMaterialMetrics } from '../DisclosureRequirements/Metrics/AggregatedMetrics/AggregatedMetricsUtils';

type ReportMaterialMetric =
  GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number];

export const filterQualitativeChildren = (metric: ReportMaterialMetric): ReportMaterialMetric => {
  const removeQualitativeChildren = (
    childMetric?: ReportMaterialMetric['childrenMetrics'][number]['childMetric']
  ): ReportMaterialMetric['childrenMetrics'][number]['childMetric'] | undefined => {
    if (childMetric?.childrenMetrics?.length) {
      return {
        ...childMetric,
        childrenMetrics:
          childMetric.childrenMetrics
            .filter((met) => met.childMetric?.metricType === QuestionType_Enum_.Decimal_)
            .map((met) => ({
              childMetric: removeQualitativeChildren(
                met.childMetric as ReportMaterialMetric['childrenMetrics'][number]['childMetric']
              ),
            })) ?? [],
      };
    }
    return childMetric;
  };
  return metric?.childrenMetrics?.length
    ? {
        ...metric,
        childrenMetrics: metric.childrenMetrics
          .filter((met) => met.childMetric?.metricType === QuestionType_Enum_.Decimal_)
          .map((met) => ({ childMetric: removeQualitativeChildren(met.childMetric) })),
      }
    : metric;
};

const addPercentagesToMetricAndFilterOptedOut = (
  metric: AggregatedMetricsTableData,
  answers: GetMetricAnswersQuery_ | undefined
): AggregatedMetricsTableData => {
  const calculatePercentage = (
    child?: AggregatedMetricsTableData,
    parent?: AggregatedMetricsTableData
  ) => {
    const percent = ((child?.result?.Year ?? 0) / (parent?.result?.Year ?? 1)) * 100;
    return isNaN(percent) ? '0' : percent.toFixed(2).replace(/[.,]00$/, '');
  };
  const mapSubrows = (
    subRow?: AggregatedMetricsTableData,
    parent?: AggregatedMetricsTableData
  ): AggregatedMetricsTableData => {
    if (subRow?.subRows) {
      return {
        ...subRow,
        percentage: subRow?.metric.showAsPercentage
          ? calculatePercentage(subRow, parent)
          : undefined,
        subRows: subRow.subRows
          .filter(
            (row) =>
              answers?.esrs_Answer.find((answer) => answer.metricRef === row.metric.reference)
                ?.hasOptedOut !== true
          )
          ?.map((sub) => mapSubrows(sub, subRow)),
      };
    }
    return {
      ...subRow,
      metric: subRow?.metric ?? ({} as AggregatedMetricsTableData['metric']),
      percentage: subRow?.metric.showAsPercentage ? calculatePercentage(subRow, parent) : undefined,
    };
  };
  return {
    ...metric,
    percentage: metric.metric.showAsPercentage ? `100` : undefined,
    subRows: metric.subRows
      ?.filter(
        (row) =>
          answers?.esrs_Answer.find((answer) => answer.metricRef === row.metric.reference)
            ?.hasOptedOut !== true
      )
      ?.map((subRow) => mapSubrows(subRow, metric)),
  };
};

export const filterOptedOutRows = (
  metric: AggregatedMetricsTableData,
  answers: GetMetricAnswersQuery_ | undefined
): AggregatedMetricsTableData => {
  const mapSubrows = (subRow?: AggregatedMetricsTableData): AggregatedMetricsTableData => {
    if (subRow?.subRows) {
      return {
        ...subRow,
        subRows: subRow.subRows
          .filter(
            (row) =>
              answers?.esrs_Answer.find((answer) => answer.metricRef === row.metric.reference)
                ?.hasOptedOut !== true
          )
          ?.map((sub) => mapSubrows(sub)),
      };
    }
    return {
      ...subRow,
      metric: subRow?.metric ?? ({} as AggregatedMetricsTableData['metric']),
    };
  };
  return {
    ...metric,
    subRows: metric.subRows
      ?.filter(
        (row) =>
          answers?.esrs_Answer.find((answer) => answer.metricRef === row.metric.reference)
            ?.hasOptedOut !== true
      )
      ?.map((subRow) => mapSubrows(subRow)),
  };
};

const addReportMaterialMetricTags = (
  metric: ReportMetric,
  groupAnswersData: SubsidiaryAssessmentsType[],
  standardRef: string
) => {
  let updatedMetric = {
    ...metric,
    materialMetrics: metric?.materialMetrics.map((materialMetric) => ({
      ...materialMetric,
      materialMetricTags: getMaterialMetricsTags(groupAnswersData, standardRef, metric.reference),
    })),
  };

  if (!!metric?.childrenMetrics?.length) {
    const updatedChildren: AssessableMetrics[number]['childrenMetrics'] =
      metric.childrenMetrics.map(({ childMetric }) => ({
        childMetric: childMetric
          ? addReportMaterialMetricTags(
              childMetric as ReportChildMetrics[number]['childMetric'],
              groupAnswersData,
              standardRef
            )
          : childMetric,
      })) as AssessableMetrics[number]['childrenMetrics'];
    updatedMetric = {
      ...updatedMetric,
      childrenMetrics: updatedChildren as AssessableMetrics[number]['childrenMetrics'],
    };
  }

  return updatedMetric;
};

const addSubsidiaryTags = (
  metric: ReportMetric,
  subsidiaryAssessments: SubsidiaryAssessmentsType[],
  standardRef: string
) => {
  return isFullMetricOnSubLevel(metric as unknown as MaybeHasChild)
    ? addReportMaterialMetricTags(metric, subsidiaryAssessments ?? [], standardRef)
    : metric;
};

export const useEsrsReportData = (isClicked: boolean) => {
  const { isGroup } = useCompanyType();
  const { esrsAssessmentId = '' } = useParams();
  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);
  const { data: currencyConversionData } = useGetAssessmentCurrencyConversionQuery({
    fetchPolicy: 'network-only',
    variables: {
      assessmentId: esrsAssessmentId,
    },
    skip: !isGroup,
  });

  const [companyReportingUnitId, firstBUReportingUnitId] = useMemo(
    () => [
      reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
      reportingUnitAssessments.filter((ru) => !ru.isCompanyLevel)?.[0]?.id ?? '',
    ],
    [reportingUnitAssessments]
  );

  const { data: esrsAssessmentData, loading: assessmentLoading } = useEsrsAssessmentQuery({
    variables: {
      esrsAssessmentId,
    },
    fetchPolicy: 'cache-first',
    skip: !esrsAssessmentId,
  });

  const { data: reportingUnitsAnswers, loading: reportingUnitsAnswersLoading } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: {
        esrsAssessmentId,
      },
      fetchPolicy: 'cache-first',

      skip: !esrsAssessmentId || !companyReportingUnitId,
    });

  const { data: subAnswersEnvironmentData, loading: subAnswersEnvironmentLoading } =
    useGetSubAnswersEnvironmentQuery({
      variables: { esrsAssessmentId, environmentDisclosures: environmentDRRefs },
      fetchPolicy: 'cache-first',
      skip: !isGroup || !esrsAssessmentId,
    });

  const isCollectOnly = esrsAssessmentData?.esrsAssessment?.collectOnly ?? false;

  const { data: parentAssessmentData, loading: parentAssessmentDataLoading } =
    useGetRequiredGroupMaterialMetricsQuery({
      variables: {
        parentAssessmentId: esrsAssessmentData?.esrsAssessment?.parentAssessment?.id,
      },
      skip: !isCollectOnly,
    });

  const groupMaterialMetrics = isCollectOnly
    ? (parentAssessmentData?.esrs_MaterialMetric ?? [])
    : [];

  const groupMaterialMetricsRefs = groupMaterialMetrics.map((mm) => mm.metric.reference);
  const groupMaterialStandardsRefs = uniq(
    groupMaterialMetrics.flatMap((mm) => mm.metric.requirement.group.standardRef)
  );

  const { data: subAnswersOtherData, loading: subAnswersOtherLoading } = useGetSubAnswersOtherQuery(
    {
      variables: { esrsAssessmentId, environmentDisclosures: environmentDRRefs },
      fetchPolicy: 'cache-first',
      skip: !isGroup || !esrsAssessmentId,
    }
  );

  const { data: subMaterialMetricsData, loading: subMaterialMetricsLoading } =
    useGetSubMaterialMetricsOnGroupLevelQuery({
      variables: { esrsAssessmentId },
      fetchPolicy: 'cache-first',
      skip: !isGroup || !esrsAssessmentId,
    });

  const groupAnswers = useMemo(() => {
    const reportingUnits =
      subAnswersEnvironmentData?.EsrsAssessment_by_pk?.subsidiaryAssessments?.map((assessment) => ({
        ...assessment,
        reportingUnits: assessment.reportingUnits?.map((rua) => ({
          ...rua,
          answers: [
            ...rua.answers,
            ...(subAnswersOtherData?.EsrsAssessment_by_pk?.subsidiaryAssessments
              ?.find((sa) => sa.id === assessment.id)
              ?.reportingUnits?.find((ru) => ru.id === rua.id)?.answers ?? []),
          ],
        })),
      })) ?? [];
    const data = {
      EsrsAssessment_by_pk: {
        ...subMaterialMetricsData?.EsrsAssessment_by_pk,
        id: subMaterialMetricsData?.EsrsAssessment_by_pk?.id ?? '',
        subsidiaryAssessments:
          subMaterialMetricsData?.EsrsAssessment_by_pk?.subsidiaryAssessments?.map((a) => ({
            ...a,
            reportingUnits:
              reportingUnits?.find((assessment) => assessment.id === a.id)?.reportingUnits ?? [],
          })) ?? [],
      },
    };
    return data ?? [];
  }, [subAnswersEnvironmentData, subAnswersOtherData, subMaterialMetricsData]);

  const { data, loading: materialStandardsLoading } = useGetReportMaterialStandards({
    esrsAssessmentId,
    isClicked,
    groupMaterialMetricsRefs,
    groupMaterialStandardsRefs,
  });

  const { data: answers, loading: answersLoading } = useGetMetricAnswersQuery({
    variables: {
      reportingUnitId: companyReportingUnitId,
    },
    fetchPolicy: 'cache-first',
    skip: !companyReportingUnitId,
  });

  const { data: mdrmAnswersData, loading: mdrmAnswersLoading } = useGetCompanyLevelMdrmAnswersQuery(
    {
      variables: {
        reportingUnitId: companyReportingUnitId,
      },
      skip: !companyReportingUnitId,
    }
  );

  const allMdrmAnswers = useMemo(
    () => mdrmAnswersData?.esrs_DatapointMdrm ?? [],
    [mdrmAnswersData]
  );

  const { companyName, reportingYear } = useMemo(() => {
    const assessmentData = esrsAssessmentData?.esrsAssessment;
    return {
      companyName: assessmentData?.company.name,
      reportingYear: assessmentData?.reportingYear,
    };
  }, [esrsAssessmentData]);

  const materialStandards = useMemo(
    () => data?.EsrsAssessment_by_pk?.materialStandards ?? [],
    [data]
  );
  const allMaterialMetrics = materialStandards
    .flatMap((std) => std.materialMetrics)
    .filter(
      (mm) => mm.materialStandard.standardRef === mm.metric.requirement.group.standard.reference
    );

  const categories: CategoriesType = useMemo(() => {
    const set = [
      ...new Set(
        materialStandards.map((std) =>
          JSON.stringify({
            title: std.standard.category.title,
            ref: std.standard.categoryRef,
          })
        )
      ),
    ].map((s) => JSON.parse(s));

    const generalDisclosure = set.find((category) => category.ref === 'general');
    const otherCategories = set.filter((category) => category.ref !== 'general');
    otherCategories.sort((a, b) => a.title.localeCompare(b.title));
    const cats = generalDisclosure ? [generalDisclosure, ...otherCategories] : otherCategories;
    return cats.sort((a, b) => ORDER_OF_CATEGORIES?.[a.title] - ORDER_OF_CATEGORIES?.[b.title]);
  }, [materialStandards]);

  const answersMap = useMemo(() => {
    const map = new Map();
    answers?.esrs_Answer.forEach((ans) => {
      map.set(ans.metricRef, ans.datapoints.find((dp) => !!dp.value)?.value);
    });
    return map;
  }, [answers]);

  const checkIfCompleted = (
    metric: MaterialReportMetric,
    textAnswer?: string,
    result?: AggregatedMetricsTableData
  ) => {
    if (metric.metric.metricType === QuestionType.Decimal_) {
      return { completed: !!result?.result?.Year, answered: !!result?.result?.Year };
    }
    let completed = true;
    let answered = !!textAnswer;

    const checkChildren = (childrenMetrics: MaterialReportMetric['childrenMetrics']) => {
      for (const { childMetric } of childrenMetrics) {
        if (childMetric) {
          const childAnswer = answersMap.get(childMetric.reference);
          const isCurrentMetricCompleted = !!childAnswer;
          completed = completed && isCurrentMetricCompleted;
          answered = answered || isCurrentMetricCompleted;

          if (childMetric.childrenMetrics.length > 0) {
            checkChildren(childMetric.childrenMetrics as MaterialReportMetric['childrenMetrics']);
          }
        }
      }
    };

    if (metric.childrenMetrics.length) {
      checkChildren(metric.childrenMetrics);
    } else {
      completed = !!textAnswer;
    }

    return { completed, answered };
  };

  const getMetricData = (metric: MaterialReportMetric, standardId: string, standardRef: string) => {
    const filteredMetric =
      metric.metric.metricType !== QuestionType.Decimal_
        ? metric
        : filterQualitativeChildren(metric);

    const metricObject = {
      ...metric.metric,
      childrenMetrics: filteredMetric.childrenMetrics,
      materialMetrics: [metric],
    };

    const metricWithTags = addSubsidiaryTags(
      metricObject,
      groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [],
      standardRef
    );

    const textAnswer = answersMap.get(metric.metricRef);

    const answer = answers?.esrs_Answer?.find((a) => a.metricRef === metric.metricRef);
    const datapoint = answer?.datapoints.find((dp) => dp.timeframe === 'Year');
    const textBefore = datapoint?.metadata?.before?.text;
    const textAfter = datapoint?.metadata?.after?.text;
    const isBeforeVisible = datapoint?.metadata?.before?.isVisible === true && !!textBefore;
    const isAfterVisible = datapoint?.metadata?.after?.isVisible === true && !!textAfter;
    const isLocked = getIsMetricLocked(metricObject.unlockCondition, datapoint?.factValue ?? '');
    const isMdrmDisclosed = datapoint?.isMdrmDisclosed;
    const hasOptedOut = answer?.hasOptedOut;

    const materialMetrics = groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments.flatMap(
      (company) =>
        company.materialStandards
          .find((ms) => ms.standardRef === standardRef)
          ?.materialMetrics.find((mm) => mm?.metricRef === metric.metricRef)
    );

    const nestedMetric = getNestedRows({
      metric: metricWithTags as AssessableMetrics[number],
      materialStandardId: standardId,
      isGroup,
      materialMetrics: !isFullMetricOnGroupLevel(metricWithTags as MaybeHasChild)
        ? (materialMetrics as GroupMaterialMetrics)
        : undefined,
    });
    const metricResult = getMetricResult({
      metric: metricWithTags as AssessableMetrics[number],
      nestedMetric,
      isGroup,
      reportingUnitsAnswers,
      groupAnswers,
      standardRef,
      materialStandardId: standardId,
      currencyConversionData: currencyConversionData?.esrs_AssessmentCurrencyConversion,
    });

    const result = addPercentagesToMetricAndFilterOptedOut(metricResult, answers);

    const mdrmAnswers = getMetricMdrmAnswers(metricObject, allMdrmAnswers);
    const { completed, answered } = checkIfCompleted(metric, textAnswer, result);

    return {
      metric,
      textAnswer: metric.metric.metricType !== QuestionType.Decimal_ ? textAnswer : undefined,
      tableData: result,
      reportingUnitId:
        metric.dataCollection === DataCollectionLevel.company
          ? companyReportingUnitId
          : firstBUReportingUnitId,
      completed,
      isAnswered: answered,
      mdrmAnswers: mdrmAnswers,
      isBeforeVisible,
      isAfterVisible,
      textBefore,
      textAfter,
      isLocked,
      isMdrmDisclosed,
      hasOptedOut,
    };
  };

  const getDisclosureData = (
    dr: MaterialReportDisclosure,
    category: string,
    standardTitle: string,
    standardId: string,
    standardRef: string
  ): EsrsReportDisclosureRequirement => {
    const filteredDrs = materialStandards.find(
      (stand) =>
        stand.standard.category.title === category && stand.standard.title === standardTitle
    );
    const materialMetrics =
      standardRef === 'ESRS-2' ? allMaterialMetrics : filteredDrs?.materialMetrics;
    const metrics =
      materialMetrics
        ?.filter((met) => dr.metrics.find((rq) => met.metricRef === rq.reference))
        .filter((metric) => {
          const isTopLevelMetric = metric.metric.parentMetrics.length === 0;
          const isAssessable = metric.metric.isAssessable;
          if (metric.metric.metricType === QuestionType.Decimal_) {
            return isTopLevelMetric ? isAssessable : true;
          }
          return true;
        })
        .map((metric) => getMetricData(metric, standardId, standardRef)) ?? [];
    return {
      title: dr.title,
      type: dr.type,
      reference: dr.reference,
      order: dr.order,
      description: dr.description,
      additionalTypes: dr.additionalTypes,
      isHidden:
        filteredDrs?.materialMetrics
          .filter((metric) => dr.metrics.find((met) => met.reference === metric.metricRef))
          .every((metric) => metric.isHidden) ?? false,
      metrics: metrics?.filter((m) => m.hasOptedOut !== true) ?? [],
      targets: dr.type === DREnums.target ? dr.targets : [],
      actions: dr.type === DREnums.action ? dr.actions : [],
      metricsIncludingOptedOut: metrics,
    };
  };

  const getStandardsData = (
    standard: MaterialReportStandard,
    category: string
  ): EsrsReportStandard => {
    const materialMetrics =
      standard.standardRef === 'ESRS-2'
        ? allMaterialMetrics.filter(
            (metric) =>
              metric.materialStandard.standardRef === 'ESRS-2' ||
              MARKED_GENERAL.includes(metric.metric.disclosureRequirementRef)
          )
        : standard.materialMetrics.filter(
            (met) => !MARKED_GENERAL.includes(met.metric.disclosureRequirementRef)
          );

    const disclosureRequirementsMap = materialMetrics.reduce((acc, mm) => {
      const standardDR = standard.standard.disclosureRequirementGroups
        .flatMap((req) => req.requirements)
        .find((r) => r.reference === mm.metric.disclosureRequirementRef);
      const disclosureRequirement = {
        reference: mm.metric.disclosureRequirementRef,
        title: mm.metric.requirement.title,
        shortTitle: mm.metric.requirement.shortTitle,
        order: mm.metric.requirement.order,
        type: mm.metric.requirement.type,
        additionalTypes: mm.metric.requirement.additionalTypes,
        metrics: [],
        targets: standardDR?.type === DREnums.target ? standardDR.targets : [],
        actions: standardDR?.type === DREnums.action ? standardDR.actions : [],
      };
      if (!acc.has(disclosureRequirement.reference)) {
        acc.set(disclosureRequirement.reference, disclosureRequirement);
      }

      acc.get(disclosureRequirement.reference).metrics.push(mm.metric);

      return acc;
    }, new Map());

    const disclosureRequirementsArray: MaterialReportDisclosure[] = Array.from(
      disclosureRequirementsMap.values()
    );

    const disclosureRequirements = disclosureRequirementsArray
      .flat()
      .sort((a, b) => a.order - b.order || a.reference.localeCompare(b.reference));

    return {
      id: standard.id,
      title: standard.standard.title,
      reference: standard.standardRef,
      targetQuestions: standard.standard.targetQuestions,
      actionQuestions: standard.standard.actionQuestions,
      disclosureRequirements: disclosureRequirements.map((dr) =>
        getDisclosureData(
          dr as MaterialReportDisclosure,
          category,
          standard.standard.title,
          standard.id,
          standard.standardRef
        )
      ),
    };
  };

  const getCategoryData = (category: CategoriesType[number]): EsrsReportCategory => {
    const filteredStandards = materialStandards.filter(
      (std) => std.standard.category.title === category.title
    );

    return {
      title: category.title,
      reference: category.ref,
      standards: filteredStandards
        .map((std) => getStandardsData(std, category.title))
        .sort((a, b) => a.reference.localeCompare(b.reference)),
    };
  };

  const reportData: EsrsReportData = useMemo(
    () => ({
      categories: categories?.map((category) => getCategoryData(category)) ?? [],
      companyName: companyName ?? '',
      reportingYear: reportingYear ?? new Date().getFullYear(),
      materialStandards: esrsAssessmentData?.esrsAssessment?.materialStandards,
    }),
    [
      categories,
      answers,
      data,
      companyName,
      reportingYear,
      reportingUnitsAnswers,
      materialStandards,
      subAnswersOtherData,
      subAnswersEnvironmentData,
      subMaterialMetricsData,
    ]
  );

  const loading = useMemo(() => {
    return (
      materialStandardsLoading ||
      answersLoading ||
      reportingUnitsAnswersLoading ||
      assessmentLoading ||
      mdrmAnswersLoading ||
      parentAssessmentDataLoading ||
      subAnswersEnvironmentLoading ||
      subAnswersOtherLoading ||
      subMaterialMetricsLoading
    );
  }, [
    materialStandardsLoading,
    answersLoading,
    reportingUnitsAnswersLoading,
    assessmentLoading,
    mdrmAnswersLoading,
    parentAssessmentDataLoading,
    subAnswersEnvironmentLoading,
    subAnswersOtherLoading,
    subMaterialMetricsLoading,
  ]);

  return { data: reportData, loading };
};

export const useHideShowMetric = () => {
  const [upsertMetric, { loading }] = useUpsertMaterialMetricsMutation();

  const hideShowMetric = useCallback(
    (metric: AggregatedMetricsTableData, standardId: string, isMetricHidden: boolean) => {
      if (
        metric.metric.childrenMetrics.length &&
        metric.metric.metricType !== QuestionType.Decimal_
      ) {
        const mapChildren = (
          m: EsrsReportMetric['metric']['childrenMetrics'][number]
        ): Esrs_MaterialMetric_Insert_Input_[] => {
          return m.childMetric?.childrenMetrics.length
            ? [
                ...m.childMetric.childrenMetrics.flatMap((child) =>
                  mapChildren(child as EsrsReportMetric['metric']['childrenMetrics'][number])
                ),
                {
                  id: m.childMetric.materialMetrics[0].id,
                  metricRef: m.childMetric.reference,
                  materialStandardId: standardId,
                  isHidden: !isMetricHidden,
                },
              ]
            : [
                {
                  isHidden: !isMetricHidden,
                  id: m.childMetric?.materialMetrics[0]?.id,
                  materialStandardId: standardId,
                  metricRef: m.childMetric?.reference,
                },
              ];
        };

        const objects = [
          ...metric.metric.childrenMetrics.flatMap((child) => mapChildren(child)),
          {
            id: metric.metric.materialMetrics[0].id,
            metricRef: metric.metric.reference,
            materialStandardId: standardId,
            isHidden: !isMetricHidden,
          },
        ];

        return upsertMetric({
          variables: {
            objects: objects,
          },
        });
      } else {
        return upsertMetric({
          variables: {
            objects: {
              id: metric.metric.materialMetrics[0].id,
              metricRef: metric.metric.reference,
              materialStandardId: standardId,
              isHidden: !isMetricHidden,
            },
          },
        });
      }
    },
    [upsertMetric]
  );
  return { hideShowMetric, loading };
};

export const useIsMetricHidden = (metricRef: string, assessmentId: string) => {
  const { data, loading } = useGetMetricReportStatusSubscription({
    variables: {
      esrsAssessmentId: assessmentId,
    },
  });
  const resultMap = useMemo(() => {
    return data?.esrs_MaterialMetric.reduce(
      (acc, { metricRef: currentRef, isHidden }) => {
        acc[currentRef] = isHidden;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
  }, [data]);

  const isHidden = useMemo(() => {
    return resultMap?.[metricRef] ?? false;
  }, [resultMap, metricRef]);

  return { isHidden, loading };
};

export const useIsDrHidden = (metricRefs: string[], assessmentId?: string) => {
  const { data, loading } = useGetMetricReportStatusSubscription({
    variables: {
      esrsAssessmentId: assessmentId,
    },
    skip: !assessmentId,
  });
  const resultMap = useMemo(() => {
    return data?.esrs_MaterialMetric.reduce(
      (acc, { metricRef: currentRef, isHidden }) => {
        acc[currentRef] = isHidden;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
  }, [data]);

  const isHidden = useMemo(() => {
    return metricRefs.every((ref) => resultMap?.[ref] ?? false);
  }, [resultMap, metricRefs]);

  return { isHidden, loading };
};

export const useESRSReportStructure = (assessmentId?: string) => {
  const { data: esrsAssessmentData, loading: assessmentDetailsLoading } =
    useGetEsrsAssessmentShortDetailsQuery({
      variables: {
        esrsAssessmentId: assessmentId,
      },
      skip: !assessmentId,
    });

  const isCollectOnly = esrsAssessmentData?.assessment?.collectOnly ?? false;

  const { data: parentAssessmentData, loading: parentAssessmentDataLoading } =
    useGetRequiredGroupMaterialMetricsQuery({
      variables: {
        parentAssessmentId: esrsAssessmentData?.assessment?.parentAssessmentId,
      },
      skip: !isCollectOnly,
    });

  const groupMaterialMetrics = isCollectOnly
    ? (parentAssessmentData?.esrs_MaterialMetric ?? [])
    : [];

  const groupMaterialMetricsRefs = groupMaterialMetrics.map((mm) => mm.metric.reference) ?? [];
  const groupMaterialStandardsRefs =
    uniq(groupMaterialMetrics.flatMap((mm) => mm.metric.requirement.group.standardRef)) ?? [];

  const { data, loading } = useGetEsrsReportStructure({
    assessmentId: assessmentId ?? '',
    groupMetrics: groupMaterialMetricsRefs,
    groupStandards: groupMaterialStandardsRefs,
    isCollectOnly,
  });

  const { data: generalDrDisclosures, loading: generalDrLoading } =
    useGetReportGeneralDisclosureRequirementsQuery({
      variables: {
        assessmentId: assessmentId,
      },
      skip: !assessmentId,
    });

  const categoriesData = uniqBy(
    data?.esrs_MaterialStandard?.flatMap((std) => std.standard?.category),
    'reference'
  ).sort((a, b) => ORDER_OF_CATEGORIES?.[a.title] - ORDER_OF_CATEGORIES?.[b.title]);
  const standardsData = data?.esrs_MaterialStandard?.map((std) => std.standard);

  const structure: (StructureNode & { type?: string })[][][] = useMemo(() => {
    return (categoriesData ?? []).map((category) => {
      return [
        [
          {
            title: `${category.title === 'Environment' ? 'Environmental' : category.title} information`,
            key: category.reference,
            reference: category.reference,
            description: null,
            isHidden: false,
          },
        ],
        ...(standardsData?.filter((std) => std.category.reference === category.reference) ?? [])
          .sort((a, b) => a.reference.localeCompare(b.reference))
          .map((std) => {
            const requirements = uniq(
              std.disclosureRequirementGroups
                .flatMap((drG) => {
                  return std.reference === 'ESRS-2'
                    ? (generalDrDisclosures?.DisclosureRequirement ?? [])
                    : drG.requirements;
                })
                .sort((a, b) => a.reference.localeCompare(b.reference)) ?? []
            );
            return [
              {
                title: `${std.reference.replace(/-/g, ' ')} ${std.title}`,
                key: std.reference,
                reference: std.reference,
                isHidden: requirements.every(
                  (req) => (req.notHiddenCount.aggregate?.count ?? 0) === 0
                ),
              },

              ...requirements
                .sort((a, b) => a.order - b.order)
                .map((dr) => {
                  const isEntitySpecific = dr.additionalTypes?.some(
                    (type) => type.additionalTypeRef === 'Entity-specific'
                  );
                  return {
                    title: isEntitySpecific ? `${dr.title}` : `${dr.reference} ${dr.title}`,
                    key: dr.reference,
                    reference: dr.reference,
                    isHidden: (dr.notHiddenCount.aggregate?.count ?? 0) === 0,
                    type: dr.type,
                  };
                }),
            ];
          }),
      ];
    });
  }, [data]);

  const standards = useMemo(() => {
    return structure.flatMap((cat, index) =>
      cat.slice(1).map((std) => ({
        title: std[0].title,
        reference: std[0].reference,
        disclosureRequirementRefs: std.slice(1),
        category: `${toRomanNumeral(index + 1)}. ${cat[0][0].title}`,
      }))
    );
  }, [structure]);

  return {
    structure,
    standards,
    groupMaterialMetricsRefs,
    loading: loading || generalDrLoading || assessmentDetailsLoading || parentAssessmentDataLoading,
  };
};

export const useReportProgress = (assessmentId: string) => {
  const { isGroup } = useCompanyType();
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    const res = await nhost.functions.call('esrs/progress/company-progress', {
      assessmentId,
      type: isGroup ? 'group' : 'company',
    });

    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
  };
  useEffect(() => {
    getProgress();
  }, [isGroup, assessmentId]);
  return progress;
};

export const useDisclosureProgress = (
  drRef: string,
  esrsAssessmentId: string,
  standardRef: string,
  parentAssessmentId?: string
) => {
  const { isGroup } = useCompanyType();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/disclosure-requirement-progress', {
      drRef,
      assessmentId: esrsAssessmentId,
      standardRef,
      parentAssessmentId,
      type: isGroup ? 'group' : 'company',
    });
    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRef, esrsAssessmentId, standardRef, parentAssessmentId, isGroup]);

  return {
    progress,
    loading,
  };
};

export const getMetricData = (
  metric: GetReportMetricsPerDisclosureQuery_['EsrsMetric'][number]['materialMetrics'][number],
  standardId: string,
  standardRef: string,
  answers: GetMetricAnswersQuery_ | undefined,
  reportingUnitsAnswers: GetAnswersForMetricsOnCompanyLevelQuery_ | undefined,
  groupAnswers: GetAnswersForMetricsOnGroupLevelQuery_ | undefined,
  isGroup: boolean,
  buReportingUnitId: string,
  companyReportingUnitId: string,
  allMdrmAnswers: GetCompanyLevelMdrmAnswersQuery_['esrs_DatapointMdrm'] | undefined,
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion']
) => {
  const metricObject = {
    ...metric.metric,
    childrenMetrics: metric.childrenMetrics,
    materialMetrics: [metric],
  };

  const metricWithTags = addSubsidiaryTags(
    metricObject,
    groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [],
    standardRef
  );

  const answer = answers?.esrs_Answer.find((ans) => ans.metricRef === metric.metricRef);

  const answerDP = answer?.datapoints?.find((dp) => !!dp.value);

  const textAnswer = answerDP?.value;

  const factValueAnswer = answerDP?.factValue;

  const datapoint = answers?.esrs_Answer
    .find((ans) => ans.metricRef === metric.metricRef)
    ?.datapoints?.find((dp) => dp.timeframe === 'Year');

  const textBefore = datapoint?.metadata?.before?.text;
  const textAfter = datapoint?.metadata?.after?.text;
  const isBeforeVisible = datapoint?.metadata?.before?.isVisible === true && !!textBefore;
  const isAfterVisible = datapoint?.metadata?.after?.isVisible === true && !!textAfter;
  const isMdrmDisclosed = datapoint?.isMdrmDisclosed;

  const nestedMetric = getNestedRows({
    metric: metricWithTags as AssessableMetrics[number],
    materialStandardId: standardId,
    isGroup,
  });
  const metricResult = getMetricResult({
    metric: metricWithTags as AssessableMetrics[number],
    nestedMetric,
    isGroup,
    reportingUnitsAnswers,
    groupAnswers,
    standardRef,
    materialStandardId: standardId,
    currencyConversionData: currencyConversionData,
  });

  const result = filterOptedOutRows(metricResult, answers);

  const mdrmAnswers = getMetricMdrmAnswers(metricObject, allMdrmAnswers);

  return {
    metric: metric,
    textAnswer: metric.metric.metricType !== QuestionType.Decimal_ ? textAnswer : undefined,
    factValue: factValueAnswer,
    tableData: result,
    reportingUnitId:
      metric.dataCollection === DataCollectionLevel.company
        ? companyReportingUnitId
        : buReportingUnitId,
    completed: !!(
      textAnswer ||
      (result.result?.Year && metric.metric.metricType === QuestionType.Decimal_)
    ),
    mdrmAnswers: mdrmAnswers,
    isBeforeVisible,
    isAfterVisible,
    textBefore,
    textAfter,
    isMdrmDisclosed,
    hasOptedOut: answer?.hasOptedOut,
  };
};

export const useReportAnswers = (assessmentId?: string) => {
  const { isGroup } = useCompanyType();
  const { reportingUnitAssessments } = useAssessmentReportingUnits(assessmentId);

  const [companyReportingUnitId, firstBUReportingUnitId] = useMemo(
    () => [
      reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
      reportingUnitAssessments.filter((ru) => !ru.isCompanyLevel)?.[0]?.id ?? '',
    ],
    [reportingUnitAssessments]
  );

  const { data: reportingUnitsAnswers, loading: reportingUnitLoading } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: {
        esrsAssessmentId: assessmentId,
      },
      fetchPolicy: 'cache-first',
      skip: !assessmentId,
    });

  const { data: subAnswersEnvironmentData, loading: loadingEnvironmentAnswers } =
    useGetSubAnswersEnvironmentQuery({
      variables: { esrsAssessmentId: assessmentId, environmentDisclosures: environmentDRRefs },
      fetchPolicy: 'cache-first',
      skip: !isGroup || !assessmentId,
    });

  const { data: subAnswersOtherData, loading: loadingOtherAnswers } = useGetSubAnswersOtherQuery({
    variables: { esrsAssessmentId: assessmentId, environmentDisclosures: environmentDRRefs },
    fetchPolicy: 'cache-first',
    skip: !isGroup || !assessmentId,
  });

  const { data: subMaterialMetricsData, loading: loadingSubMaterialMetrics } =
    useGetSubMaterialMetricsOnGroupLevelQuery({
      variables: { esrsAssessmentId: assessmentId },
      fetchPolicy: 'cache-first',
      skip: !isGroup || !assessmentId,
    });

  const groupAnswers = useMemo(() => {
    const reportingUnits =
      subAnswersEnvironmentData?.EsrsAssessment_by_pk?.subsidiaryAssessments?.map((assessment) => ({
        ...assessment,
        reportingUnits: assessment.reportingUnits?.map((rua) => ({
          ...rua,
          answers: [
            ...rua.answers,
            ...(subAnswersOtherData?.EsrsAssessment_by_pk?.subsidiaryAssessments
              ?.find((sa) => sa.id === assessment.id)
              ?.reportingUnits?.find((ru) => ru.id === rua.id)?.answers ?? []),
          ],
        })),
      })) ?? [];
    const data = {
      EsrsAssessment_by_pk: {
        ...subMaterialMetricsData?.EsrsAssessment_by_pk,
        id: subMaterialMetricsData?.EsrsAssessment_by_pk?.id ?? '',
        subsidiaryAssessments:
          subMaterialMetricsData?.EsrsAssessment_by_pk?.subsidiaryAssessments?.map((a) => ({
            ...a,
            reportingUnits:
              reportingUnits?.find((assessment) => assessment.id === a.id)?.reportingUnits ?? [],
          })) ?? [],
      },
    };
    return data ?? [];
  }, [subAnswersEnvironmentData, subAnswersOtherData, subMaterialMetricsData]);

  const { data: companyAnswers, loading: companyLoading } = useGetMetricAnswersQuery({
    variables: {
      reportingUnitId: companyReportingUnitId,
    },
    fetchPolicy: 'cache-first',
    skip: !companyReportingUnitId,
  });

  const { data: mdrmAnswersData, loading: mdrmAnswersLoading } = useGetCompanyLevelMdrmAnswersQuery(
    {
      variables: {
        reportingUnitId: companyReportingUnitId,
      },
      fetchPolicy: 'cache-first',
      skip: !companyReportingUnitId,
    }
  );

  const allMdrmAnswers = useMemo(
    () => mdrmAnswersData?.esrs_DatapointMdrm ?? [],
    [mdrmAnswersData]
  );

  const reportAnswers: ReportAnswers = {
    reportingUnitsAnswers,
    groupAnswers,
    companyAnswers,
    allMdrmAnswers,
  };

  return {
    reportAnswers,
    loading: isGroup
      ? loadingEnvironmentAnswers ||
        loadingOtherAnswers ||
        loadingSubMaterialMetrics ||
        mdrmAnswersLoading
      : companyLoading || reportingUnitLoading || mdrmAnswersLoading,
    companyReportingUnitId,
    firstBUReportingUnitId,
  };
};

export const simplifyNarrativeMetrics = (
  metrics: EsrsReportMetric[]
): SimplifiedReportNarrativeMetric[] => {
  const metricMap = new Map<string, EsrsReportMetric>();

  metrics.forEach((narrativeMetric) => {
    metricMap.set(narrativeMetric.metric.metricRef, narrativeMetric);
  });

  function buildSimplifiedMetric(metric: EsrsReportMetric): SimplifiedReportNarrativeMetric {
    return {
      materialMetric: metric.metric,
      textAnswer: metric.textAnswer,
      children:
        (metric.metric.childrenMetrics
          ?.map((child) => {
            const childMetric = metricMap.get(child.childMetric?.reference ?? '');
            return childMetric ? buildSimplifiedMetric(childMetric) : null;
          })
          .filter(Boolean) as SimplifiedReportNarrativeMetric[]) ?? [],
      isBeforeVisible: metric.isBeforeVisible,
      isAfterVisible: metric.isAfterVisible,
      textBefore: metric.textBefore,
      textAfter: metric.textAfter,
      isLocked: metric.isLocked,
      // For children quantitative metrics
      tableData: metric.tableData,
    };
  }

  return metrics
    .filter(
      (m) => !m.tableData.isChild && m.metric.metric.metricType !== QuestionType_Enum_.Decimal_
    )
    .map((m) => buildSimplifiedMetric(m));
};

export const useFilterDrMetrics = (
  isPreview: boolean,
  drMetrics?: GetReportMetricsPerDisclosureQuery_
) => {
  const materialMetrics = useMemo(
    () => drMetrics?.EsrsMetric?.flatMap((metric) => metric.materialMetrics) ?? [],
    [drMetrics]
  );

  const [parentMetrics, childMetrics] = useMemo(
    () => [
      materialMetrics
        .filter((met) => !met.metric.parentMetrics.length)
        .filter((m) => (isPreview ? !m.isHidden : true)),
      materialMetrics
        .filter((met) => met.metric.parentMetrics.length)
        .filter((m) => (isPreview ? !m.isHidden : true)),
    ],
    [drMetrics, materialMetrics]
  );

  const filteredMetrics = useMemo(
    () =>
      parentMetrics.map((metric) =>
        metric.metric.metricType === QuestionType.Decimal_
          ? filterQualitativeChildren(metric)
          : metric
      ),
    [parentMetrics]
  );

  return { filteredMetrics, materialMetrics, childMetrics };
};

export const useFirstViewportEntry = (
  observerOptions: IntersectionObserverInit,
  ref: MutableRefObject<HTMLDivElement | null>
) => {
  const [entered, setEntered] = useState(false);
  const observer = useRef(
    new IntersectionObserver(([entry]) => setEntered(entry.isIntersecting), observerOptions)
  );

  useEffect(() => {
    const element = ref?.current;
    const ob = observer.current;
    if (entered) {
      ob.disconnect();
      return;
    }
    if (element && !entered) ob.observe(element);
    return () => ob.disconnect();
  }, [entered, ref]);
  return entered;
};

export const getMilestoneValue = (target: TargetFieldsFragment_, year: number) => {
  const milestone = target.milestones.find((m) => m.year === year);
  const baseline = target.keyResults.find((kr) => kr.reportingUnitId === null);

  const milestoneResult = milestone?.milestoneResults.find(
    (result) => result.reportingUnitId === null
  );

  const hasValue =
    !!milestoneResult?.value && baseline?.baseline !== undefined && baseline?.baseline !== 'null';

  const milestoneValue = hasValue
    ? Number(baseline?.baseline) !== 0
      ? percentageToNumber(milestoneResult.value, Number(baseline?.baseline))
      : milestoneResult.value
    : undefined;

  return milestoneValue;
};
