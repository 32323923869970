import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import { BooleanSelector } from 'Molecules';
import { Typography } from 'Tokens';
import { MetricsTableData } from 'containers/Esrs/pieces/DisclosureRequirements/Metrics';
import { AggregatedQualitativeAnswers } from 'containers/Esrs/pieces/DisclosureRequirements/Metrics/AggregatedMetrics';
import { TimePeriodsEnums } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { useState, useMemo, useEffect } from 'react';
import { booleanToText, textToBoolean } from 'utils/strings';
import { useGetDatapointValues } from '../../QuantitativeInputs';
import { LongTextMetricInput } from './LongTextMetricInput';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { ESRSInputArticle } from 'containers/Esrs/EsrsUtilComponents';

export const BooleanMetricInput = ({
  metric,
  companyReportingUnitId,
  setRowData,
  isReadOnly = false,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnitId?: string;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  isReadOnly?: boolean;
}) => {
  const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnitId
  );

  const [booleanAnswer, setBooleanAnswer] = useState<boolean | undefined>();

  const [hasManuallyChanged, setHasManuallyChanged] = useState<boolean>(false);

  const handleChangeAnswer = (asnwer: boolean | undefined) => {
    return onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: booleanToText(asnwer),
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
    });
  };

  const dpBooleanAnswer = useMemo(
    () => textToBoolean(dataPointPerYear?.factValue ?? ''),
    [dataPointPerYear?.factValue]
  );

  useEffect(() => {
    setBooleanAnswer(dpBooleanAnswer);
  }, [dpBooleanAnswer]);

  useEffect(() => {
    if (hasManuallyChanged) {
      handleChangeAnswer(booleanAnswer);
      setHasManuallyChanged(false);
    }
  }, [booleanAnswer]);

  const handleManualChange = (newAnswer: boolean | undefined) => {
    setHasManuallyChanged(true);
    setBooleanAnswer(newAnswer);
  };

  if (loading) {
    return <Skeleton height="20px" width="100%" />;
  }

  if (answer?.hasOptedOut) {
    return (
      <HStack py="8px">
        <MetricTypeIcon type={MetricTypes.optedout} />
        <Typography variant="body" color="text.hint" w="100%">
          Marked irrelevant
        </Typography>
      </HStack>
    );
  }

  return (
    <>
      {isReadOnly ? (
        <VStack p="8px" alignItems="start">
          {dataPointPerYear?.factValue && (
            <Typography variant="body">{dataPointPerYear?.factValue ?? 'N/A'}</Typography>
          )}
          <Typography variant="body">{dataPointPerYear?.value ?? 'N/A'}</Typography>
        </VStack>
      ) : (
        <VStack alignItems="start" w="100%">
          <BooleanSelector
            answer={booleanAnswer}
            handleChangeAnswer={handleManualChange}
            isDisabled={answer?.hasOptedOut}
            extra={<ESRSInputArticle type="boolean" />}
          />
          <LongTextMetricInput
            metric={metric}
            companyReportingUnit={companyReportingUnitId}
            setRowData={setRowData}
            hasRef={false}
          />
        </VStack>
      )}
    </>
  );
};
