import { Tabs } from 'Atoms';
import { ContentHeader, ContentLayout } from 'Molecules';
import { useLocalStorage } from 'usehooks-ts';
import { MaterialityAssessment } from '.';
import { DataCollectionSetup } from './pieces/DataCollection/DataCollectionSetup';
import { CurrencyConversion } from './pieces/Assessment/CurrencyConversion/CurrencyConversion';
import { useCurrentCompany } from 'utils/hooks';
import { useMemo } from 'react';
import { useGetSubCurrenciesQuery } from 'models';
import { useParams } from 'react-router-dom';

export const ESRSAssessmentSettings = () => {
  const [selectedSettingsTab, setSelectedSettingsTab] = useLocalStorage(
    'selectedSettingsTab',
    'materiality'
  );
  const { esrsAssessmentId = '' } = useParams();

  const onChangeTab = (tabId: string): void => {
    setSelectedSettingsTab(tabId);
  };
  const company = useCurrentCompany();
  const groupCurrency = useMemo(() => company.company?.currency, [company]);

  const { data: subCurrencyData } = useGetSubCurrenciesQuery({
    variables: {
      assessmentId: esrsAssessmentId,
    },
    skip: !company.company?.isGroupOwner,
  });

  const subCurrencies = useMemo(
    () => subCurrencyData?.EsrsAssessment.filter((curr) => curr.company.currency !== groupCurrency),
    [subCurrencyData]
  );

  const selectedTab = useMemo(
    () =>
      selectedSettingsTab === 'currency-setup' && !subCurrencies?.length
        ? 'materiality'
        : selectedSettingsTab,
    [selectedSettingsTab, subCurrencies]
  );

  return (
    <ContentLayout
      backButton
      onBackButtonClick={() => {
        localStorage.removeItem('selectedSettingsTab');
      }}
      header={<ContentHeader title="Configure assessment" />}
    >
      <Tabs
        defaultIndex={0}
        onChange={(tabId) => {
          if (typeof tabId == 'string') {
            onChangeTab(tabId);
          }
        }}
        currentTab={selectedTab}
        items={[
          {
            title: 'Materiality assessment',
            id: 'materiality',
            content: <MaterialityAssessment isOnboarding={false} />,
          },
          {
            title: 'Data collection setup',
            id: 'data-collection',
            content: <DataCollectionSetup isOnboarding={false} onChangeTab={onChangeTab} />,
          },
          {
            title: 'Currency rate setup',
            id: 'currency-setup',
            content: (
              <CurrencyConversion
                isOnboarding={false}
                companyName={company.company?.name}
                groupCurrency={groupCurrency}
                subCurrencies={subCurrencies}
              />
            ),
          },
        ].filter((tab) => (!subCurrencies?.length ? tab.id !== 'currency-setup' : true))}
      />
    </ContentLayout>
  );
};
