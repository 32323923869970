import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'Molecules';
import { CurrencyConversionTableRow } from './CurrencyConversion.d';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { CurrencyConversionInput } from './CurrencyConversionInput';
import { VStack } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';

export const CurrencyConversionTable = ({
  currencies,
}: {
  currencies: CurrencyConversionTableRow[];
}) => {
  const { t } = useTranslation('common');
  const columns: ColumnDef<CurrencyConversionTableRow>[] = [
    {
      header: 'Currencies',
      meta: {
        width: '260px',
      },
      accessorKey: 'currencies',
      enableSorting: false,
      cell: ({ row }) => (
        <VStack alignItems="start" gap="0px">
          <Typography variant="bodyStrong">
            {t(`fields.currencyNames.${row.original.from}`)}
          </Typography>
          <TruncatableText variant="micro" text={row.original.companies.join(', ')} />
        </VStack>
      ),
    },
    {
      header: 'From currency',
      meta: {
        width: '100px',
      },
      accessorKey: 'from',
      enableSorting: false,
      cell: ({ row }) => <Typography>{row.original.from}</Typography>,
    },
    {
      header: 'To currency',
      accessorKey: 'to',
      meta: {
        width: '100px',
      },
      enableSorting: false,
      cell: ({ row }) => <Typography>{row.original.to}</Typography>,
    },
    {
      header: 'Conversion rate',
      accessorKey: 'rate',
      meta: {
        width: '55%',
      },
      enableSorting: false,
      cell: ({ row }) => <CurrencyConversionInput row={row.original} />,
    },
  ];
  return (
    <Table
      columns={columns}
      data={currencies}
      withBorder
      headerTextVariant="detailStrong"
      headerHeight="48px"
      headerBorderColor="border.decorative"
      headerPadding="1px 16px"
      bottomRowBorder={false}
      rowProps={{ maxHeight: '48px', height: '48px' }}
      cellProps={{ maxHeight: '48px', height: '48px', padding: '1px 16px' }}
    />
  );
};
