import { Box, HStack, Spinner } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Button, EmptyState, Infobox, Tag, Tooltip, VisibleIf } from 'Atoms';
import { useCurrentCompanyId } from 'utils/hooks';
import { ContentLayout, AvatarGroup, Table, CelsiaSpinner } from 'Molecules';
import { useNavigate } from 'react-router-dom';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  usePaiRequests,
  PaiCompanyRequest,
  useCompanyPaiReport,
  usePaiCategories,
} from '../CompanyPai.hooks';
import { uniq } from 'lodash';
import { ReportPeriods } from 'Features/PortfolioView';
import { useUserSetting } from 'containers/Navigation';
import { useKnowledgeBase } from 'Features';
import { ARTICLE_PAI_INDICATORS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';
import { WarningIcon } from 'Tokens/Icons/Status.tsx';

export const PAI_LEARN_MORE_MODAL = 'pai_learn_more_modal_is_closed';

const StatusTag = ({
  year,
  period,
  isShared,
}: {
  year: number;
  period: ReportPeriods;
  isShared: boolean;
}) => {
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation('pai');
  const { report, isLoading } = useCompanyPaiReport(year, period, companyId ?? '');
  const categories = usePaiCategories(report);

  if (isLoading) return <Spinner />;
  if (!isShared) {
    return (
      <Tooltip label="The assessment has not yet been shared by the company and the progress is therefore not available">
        <Tag variant="warning" rightIcon={<WarningIcon color={'text.warning'} />}>
          {t('common:assessment.isLocked.notShared')}
        </Tag>
      </Tooltip>
    );
  } else {
    return (
      <>
        {categories.every((c) => c.isDone === true) ? (
          <Tag variant="success">{t('status.done')}</Tag>
        ) : (
          <Tag variant="info">{t('table.status.started')}</Tag>
        )}
      </>
    );
  }
};

export const PaiReportTable = ({ requests }: { requests: PaiCompanyRequest[] }) => {
  const { t } = useTranslation('pai');
  const navigate = useNavigate();

  const columns: ColumnDef<PaiCompanyRequest>[] = [
    {
      header: t('table.name.header'),
      id: 'name',
      cell: ({ row }) => (
        <HStack spacing="8px">
          <Typography variant="bodyStrong">{t('report')}</Typography>
          <Tag size="xs">
            {row?.original?.year}{' '}
            {row?.original?.period !== ReportPeriods.year &&
              `${row?.original?.period?.toLocaleUpperCase()}`}
          </Tag>
        </HStack>
      ),
    },
    {
      header: t('table.status.header'),
      id: 'status',
      cell: ({ row }) => {
        const reportExists =
          (row.original.report !== null &&
            row?.original?.report?.answers?.some((answer) => answer.data !== null)) ??
          0 > 0;
        const isShared = row?.original?.requests?.some((r) =>
          r?.portfolio?.companyMemberships?.some(
            (m) => m?.sharedPaiCompanyReportId === row?.original?.report?.id
          )
        );
        if (reportExists) {
          return (
            <StatusTag
              year={row.original.year}
              period={row?.original?.period}
              isShared={isShared}
            />
          );
        }
        return <Tag variant="undefined">{t('table.status.reported')}</Tag>;
      },
    },
    {
      header: t('table.investors.header'),
      id: 'investors',
      cell: ({ row }) => (
        <AvatarGroup
          size="sm"
          names={uniq(row?.original?.requests.map((r) => r.portfolio?.ownerCompany?.name))}
        />
      ),
    },
  ];

  return (
    <Table<PaiCompanyRequest>
      data={requests}
      columns={columns}
      rowProps={{ _hover: { bg: 'bg.hover' } }}
      onRowClick={(row) => {
        navigate(`${row?.year}/${row?.period}`);
      }}
    />
  );
};

export const InvestorsEmptyState = () => {
  const { t } = useTranslation('pai');
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('table.empty.title')}
        description={t('table.empty.description')}
        icon={<PaiIndicatorsIllustration boxSize="120px" />}
      />
    </Box>
  );
};

export const PaiReportList = () => {
  const { companyId } = useCurrentCompanyId();
  const { requests, loading } = usePaiRequests(companyId ?? undefined);
  const { t } = useTranslation('home');
  const { onOpen } = useKnowledgeBase();
  const [isPaiLearnMoreClosed, onTogglePaiModal] = useUserSetting(PAI_LEARN_MORE_MODAL, false);
  if (loading) return <CelsiaSpinner />;

  return (
    <ContentLayout header={'PAI'}>
      {!isPaiLearnMoreClosed && (
        <Infobox
          status="neutral"
          withIcon={false}
          title={t('home:emptyState.pai.subTitle')}
          description={t('home:pai.description')}
          onClose={() => onTogglePaiModal(true)}
          extra={
            <Button
              ml="-8px"
              variant="ghost"
              onClick={() => onOpen(ARTICLE_PAI_INDICATORS.slug)}
              size="sm"
              color="text.selected"
            >
              {t('home:emptyState.pai.infoButton')}
            </Button>
          }
        />
      )}
      <VisibleIf condition={requests.length >= 1}>
        <PaiReportTable requests={requests} />
      </VisibleIf>
      <VisibleIf condition={requests.length < 1}>
        <InvestorsEmptyState />
      </VisibleIf>
    </ContentLayout>
  );
};
