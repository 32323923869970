import { Box } from '@chakra-ui/react';
import { ColumnDefResolved, HeaderContext } from '@tanstack/react-table';
import { Loader } from 'Molecules';
import { NestedTable } from 'Molecules/NestedTable';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { EditIcon } from 'Tokens/Icons/Function';
import { useCompanyType } from 'utils/hooks';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  DataCollectionRowEnum,
  MaterialMetricPerDisclosure,
  MetricTag,
  TableMetricData,
} from '../DataCollection.d';
import { BreakDownTag } from '../DataCollectionUtils';
import { DREnums } from '../../DisclosureRequirements';
import { BulkEditModal } from '../MetricConfigModal/BulkEditModal';
import { useMaterialMetricsPerDR } from '../DataCollection.hooks';
import {
  DataCollectionDataGatheringLevelCell,
  DataCollectionFrequencyCell,
  MetricRefOrderNumber,
  MetricShortTitleView,
  DataCollectionCheckboxCell,
  DataCollectionCheckboxHeader,
  DataCollectionLearnMoreCell,
} from './DisclosureRequirementMetricsTableUtils';
import { StickyToolbar } from 'Atoms';

const updateIsExpandedNotAggregatableMetrics = (rows: TableMetricData[]) => {
  return rows.map((row) => {
    if (row.subRows && row.subRows.length > 0) {
      row.subRows = updateIsExpandedNotAggregatableMetrics(row.subRows);

      row.isExpanded = row.subRows.some((subrow) => {
        return (
          subrow.isExpanded ||
          subrow.subRows?.some((cm) => cm.notAggregatable) ||
          subrow.notAggregatable
        );
      });
    }
    return row;
  });
};

const updateDefaultExpandedRows = (rows: TableMetricData[], parentKey: string = '') => {
  const expandedRows: Record<string, boolean> = {};
  rows?.forEach((row, index) => {
    const currentKey = parentKey ? `${parentKey}.${index}` : `${index}`;
    if (row.isExpanded) {
      expandedRows[currentKey] = true;
    }
    if (row.subRows && row.subRows.length > 0) {
      Object.assign(expandedRows, updateDefaultExpandedRows(row.subRows, currentKey));
    }
  });
  return expandedRows;
};

export const DisclosureRequirementMetricsTable = ({
  metrics,
  disclosureRequirementRef,
  setSelectedMetric,
  onDrawerOpen,
  disclosureRequirementType,
  parentEsrsAssessmentId,
  companyStandardId,
  parentStandardId,
  selectedRows,
  setSelectedRows,
  isStandardMandatory,
  hasReportingUnits,
  isTagsOnly,
}: {
  metrics: MaterialMetricPerDisclosure[];
  disclosureRequirementRef: string;
  setSelectedMetric: (metric: SelectedMetric) => void;
  onDrawerOpen: () => void;
  disclosureRequirementType: string;
  parentEsrsAssessmentId: string | undefined;
  companyStandardId: string;
  parentStandardId: string;
  selectedRows: TableMetricData[];
  setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  isStandardMandatory: boolean;
  hasReportingUnits: boolean;
  isTagsOnly?: boolean;
}) => {
  const { isGroup } = useCompanyType();

  const { metricDataLoading, requiredMetrics, tagDetailForSubsidiaries } = useMaterialMetricsPerDR({
    disclosureRequirementRef,
    companyStandardId,
    parentStandardId,
    isGroup,
    isStandardMandatory,
  });

  const hasParentCompany = useMemo(() => !!parentEsrsAssessmentId, [parentEsrsAssessmentId]);

  const recurseMetricData = (
    metric: MaterialMetricPerDisclosure,
    tag?: MetricTag
  ): TableMetricData | null => {
    if (
      (!metric.reference || !requiredMetrics.includes(metric.reference)) &&
      !metric.childrenMetrics.length
    ) {
      return null;
    }

    if (tag) {
      const { title, shortTitle, ...restOfMetric } = metric;
      const mappedSubRows =
        tag.subTags[0]?.materialTagValues
          .map((val) => {
            const subRow = recurseMetricData(metric, {
              value: val.tagValue,
              type: DataCollectionRowEnum.tag,
              subTags: tag.subTags.slice(1),
              parentMetric: metric,
            });
            return subRow ? { parentMetric: metric, ...subRow } : null;
          })
          .filter(
            (subRow): subRow is TableMetricData & { parentMetric: MaterialMetricPerDisclosure } =>
              !!subRow
          ) ?? [];

      return {
        title: tag.value,
        shortTitle: tag.value,
        isChild: true,
        type: DataCollectionRowEnum.tag,
        subRows: mappedSubRows,
        ...restOfMetric,
      };
    }

    const tags = metric.materialMetrics.find(
      (mm) => mm.materialStandardId === companyStandardId
    )?.materialMetricTags;

    // For the case of tags and children, display children metrics
    const mappedSubRows =
      tags?.length && !metric.childrenMetrics.length
        ? (tags[0].materialTagValues
            .map((tagVal) => {
              const subRow = recurseMetricData(metric, {
                value: tagVal.tagValue,
                type: DataCollectionRowEnum.tag,
                subTags: tags.slice(1),
                parentMetric: metric,
              });
              return subRow ? { parentMetric: metric, ...subRow } : null;
            })
            .filter(
              (subRow): subRow is TableMetricData & { parentMetric: MaterialMetricPerDisclosure } =>
                subRow !== null
            ) ?? [])
        : (metric.childrenMetrics
            ?.map((cMetric) => {
              const subRow = recurseMetricData(cMetric.childMetric as MaterialMetricPerDisclosure);
              return subRow ? { parentMetric: metric, ...subRow } : null;
            })
            .filter(
              (subRow): subRow is TableMetricData & { parentMetric: MaterialMetricPerDisclosure } =>
                subRow !== null
            ) ?? []);
    return {
      ...metric,
      type: DataCollectionRowEnum.metric,
      isChild: (metric.parentMetricsCount?.aggregate?.count ?? 0) > 0,
      isExpanded: metric.childrenMetrics.some((cm) => !!cm.childMetric?.adminPanelTags.length),
      subRows: mappedSubRows,
    };
  };
  const tableRows = useMemo(() => {
    const metricData = metrics
      .map((metric) => {
        return recurseMetricData(metric);
      })
      .filter((row): row is TableMetricData => row !== null);

    return updateIsExpandedNotAggregatableMetrics(metricData);
  }, [metrics]);

  const isMetricDisclosure = useMemo(
    () => disclosureRequirementType === DREnums.metric,
    [disclosureRequirementType]
  );

  const isSubsidiaryAndNonMetricDR = useMemo(
    () => !isMetricDisclosure && !isGroup,
    [isMetricDisclosure, isGroup]
  );

  const [showBulkEditor, setShowBulkEditor] = useState<boolean>(false);

  const columns: ColumnDefResolved<TableMetricData>[] = useMemo(
    () => [
      {
        // ignoring since I think this a type error from the lib
        // @ts-ignore
        header: (_: HeaderContext<TableMetricData, unknown>): React.JSX.Element => (
          <DataCollectionCheckboxHeader
            disclosureRequirementRef={disclosureRequirementRef}
            tableRows={tableRows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ),
        accessorKey: 'selectedRows',
        meta: {
          width: '2%',
        },
        cell: ({ row }) => (
          <DataCollectionCheckboxCell
            row={row}
            disclosureRequirementRef={disclosureRequirementRef}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ),
      },
      {
        header: 'Metric',
        accessorKey: 'shortTitle',
        enableSorting: false,

        cell: ({ row }) => (
          <MetricShortTitleView
            row={row}
            companyStandardId={companyStandardId}
            isGroupCompany={isGroup}
          />
        ),
      },
      {
        header: 'Disaggregation',
        accessorKey: 'breakdown',
        meta: {
          width: '13%',
        },
        cell: ({ row }) => {
          if (row.original.type === DataCollectionRowEnum.metric)
            return (
              <BreakDownTag
                metric={row.original}
                isGroupCompany={isGroup}
                companyStandardId={companyStandardId}
                parentStandardId={parentStandardId}
                hasParentCompany={hasParentCompany}
                tagDetailForSubsidiaries={tagDetailForSubsidiaries}
              />
            );
        },
      },
      {
        header: 'Frequency',
        accessorKey: 'frequency',
        meta: {
          width: '11%',
        },
        cell: ({ row }) => (
          <DataCollectionFrequencyCell
            row={row}
            companyStandardId={companyStandardId}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isMetricDisclosure={isMetricDisclosure}
          />
        ),
      },
      {
        header: 'Level',
        accessorKey: 'dataGathering',
        meta: {
          width: '11%',
        },
        cell: ({ row }) => (
          <DataCollectionDataGatheringLevelCell
            row={row}
            companyStandardId={companyStandardId}
            isGroup={isGroup}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            hasReportingUnits={hasReportingUnits}
            isMetricDisclosure={isMetricDisclosure}
          />
        ),
      },
      {
        header: 'Ref.',
        accessorKey: 'reference',
        meta: {
          width: '6%',
        },
        cell: ({ row }) => <MetricRefOrderNumber row={row} />,
      },
      {
        header: '',
        accessorKey: 'settings',
        meta: {
          width: '3%',
        },
        cell: ({ row }) => (
          <DataCollectionLearnMoreCell
            row={row}
            onDrawerOpen={onDrawerOpen}
            setSelectedMetric={setSelectedMetric}
          />
        ),
      },
    ],
    [
      isGroup,
      isMetricDisclosure,
      selectedRows,
      tableRows,
      parentStandardId,
      companyStandardId,
      disclosureRequirementRef,
    ]
  );

  const filteredColumns = useMemo(() => {
    const newColumns = isTagsOnly
      ? columns.filter(
          (col) => col.accessorKey !== 'frequency' && col.accessorKey !== 'dataGathering'
        )
      : columns;
    if (isSubsidiaryAndNonMetricDR)
      return newColumns.filter((c) => c.accessorKey !== 'selectedRows');
    return newColumns;
  }, [columns]);

  const defaultExpadedRows = useMemo(() => {
    return updateDefaultExpandedRows(tableRows);
  }, [tableRows]);

  if (metricDataLoading) return <Loader />;

  return (
    <Box w="100%" bg="bg.default" borderRadius="8px">
      <NestedTable
        columns={filteredColumns}
        data={tableRows ?? []}
        defaultExpanded={defaultExpadedRows}
        headerBorderColor="transparent"
      />

      {!!selectedRows.length && (
        <StickyToolbar
          onClick={() => setShowBulkEditor(true)}
          count={selectedRows.length}
          onClose={() => setSelectedRows([])}
          actionText="Edit"
          actionIcon={<EditIcon color="inherit" />}
          text={`data point${selectedRows.length > 1 ? 's' : ''} selected. Click “Edit” to change frequency or data gathering level.`}
        />
      )}

      <BulkEditModal
        isOpen={showBulkEditor}
        onClose={() => {
          setShowBulkEditor(false);
          setSelectedRows([]);
        }}
        selectedRows={selectedRows}
        isGroup={isGroup}
      />
    </Box>
  );
};
