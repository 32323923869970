import { Infobox, InfoboxProps } from 'Atoms/Infobox';
import { useTranslation } from 'utils/translation';

export const DecimalWarningBox = ({ ...props }: Omit<InfoboxProps, 'status'>) => {
  const { t } = useTranslation('financials');

  return (
    <Infobox
      width="100%"
      status="neutral"
      closable={false}
      title={t('financials:decimalWarning.title')}
      description={t('financials:decimalWarning.description')}
      {...props}
    />
  );
};
