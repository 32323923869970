import { ReactNode } from 'react';
import { Box, VStack, HStack, Divider, Icon } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { Menu } from 'Molecules/Menu';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { formatNum } from 'utils/numbers';
import { VisibleIf } from 'Atoms';
import { useTranslation } from 'utils/translation';

type ReportBoxContent = {
  element: ReactNode;
  label: string;
  withActionButton?: boolean;
  extraData?: {
    cash: number;
    sovereigns: number;
    currency?: string;
    companiesOnlyInvestment: number;
  };
};

const getTotalLabel = (hasCash: boolean, hasSovereigns: boolean): string => {
  const { t } = useTranslation('portfolio');
  if (hasCash && hasSovereigns) return t('portfolio:nonEligibleFinancial.totalWithCashSov');
  if (hasCash) return t('portfolio:nonEligibleFinancial.totalWithCash');
  if (hasSovereigns) return t('portfolio:nonEligibleFinancial.totalWithSov');
  return t('portfolio:nonEligibleFinancial.total');
};

const getExclusionLabel = (hasCash: boolean, hasSovereigns: boolean): string => {
  const { t } = useTranslation('portfolio');
  if (hasCash && hasSovereigns) return t('portfolio:nonEligibleFinancial.withoutCashSov');
  if (hasCash) return t('portfolio:nonEligibleFinancial.withoutCash');
  if (hasSovereigns) return t('portfolio:nonEligibleFinancial.withoutSov');
  return '';
};

const InvestmentRow = ({
  label,
  value,
  currency,
}: {
  label: string;
  value: number;
  currency: string;
}) => (
  <HStack justifyContent="space-between" width="100%">
    <Typography variant="detail" color="text.muted" width="164px">
      {label}
    </Typography>
    <Typography variant="bodyStrong" color="text.detail">
      {formatNum(value)} {currency}
    </Typography>
  </HStack>
);

const ReportHeaderMenuContent = ({
  cash,
  sovereigns,
  currency,
  companiesOnlyInvestment,
}: {
  cash: number;
  sovereigns: number;
  currency: string;
  companiesOnlyInvestment: number;
}) => {
  const hasCash = cash > 0;
  const hasSovereigns = sovereigns > 0;
  const { t } = useTranslation('portfolio');

  return (
    <VStack
      spacing="12px"
      alignItems="start"
      justifyContent="start"
      width="251px"
      padding="8px 0px"
    >
      <HStack>
        <Typography variant="h4" color="text.muted">
          {t('portfolio:nonEligibleFinancial.totalInvestments')}
          {currency}
        </Typography>
      </HStack>
      <VisibleIf condition={hasCash || hasSovereigns}>
        <Divider height="1px" border="none" background="border.decorative" />
        <InvestmentRow
          label={getTotalLabel(hasCash, hasSovereigns)}
          value={companiesOnlyInvestment + cash + sovereigns}
          currency={currency}
        />
        <Divider height="1px" border="none" background="border.decorative" />
      </VisibleIf>
      <VisibleIf condition={hasCash && hasSovereigns}>
        <InvestmentRow
          label={t('portfolio:nonEligibleFinancial.companiesWithoutSov')}
          value={companiesOnlyInvestment + cash}
          currency={currency}
        />
        <InvestmentRow
          label={t('portfolio:nonEligibleFinancial.companiesWithoutCash')}
          value={companiesOnlyInvestment + sovereigns}
          currency={currency}
        />
      </VisibleIf>
      <InvestmentRow
        label={`${t('portfolio:nonEligibleFinancial.companies')}${getExclusionLabel(hasCash, hasSovereigns)}`}
        value={companiesOnlyInvestment}
        currency={currency}
      />
    </VStack>
  );
};

export const ReportHeaderBox = ({
  header,
  content,
  layout = 'inline',
}: {
  header: string;
  content: ReportBoxContent[];
  layout?: 'inline' | 'stacked';
}) => {
  return (
    <Box bg="bg.muted" borderRadius="8px" p="16px" width="100%">
      <VStack spacing={layout === 'inline' ? '8px' : '2px'} alignItems="start">
        <Typography variant="h4">{header}</Typography>
        {layout === 'inline' ? (
          <HStack pt="6px" justifyContent="space-between" alignItems="flex-end" width="100%">
            {content.map((c, index) =>
              c.withActionButton ? (
                <VStack spacing="0px" flex={1} alignItems="start" key={index}>
                  <HStack
                    justifyContent="space-between"
                    width="100%"
                    padding="8px"
                    borderRadius="6px"
                    cursor="pointer"
                    background="bg.interactive"
                    _hover={{ bg: 'bg.hover', cursor: 'pointer' }}
                    _active={{ bg: 'bg.pressed', cursor: 'pointer' }}
                  >
                    <Menu
                      menuWidth="100%"
                      menuButton={
                        <HStack width="100%" justifyContent="space-between">
                          <VStack spacing="0px" flex={1} alignItems="start">
                            {c.element}
                            <Typography variant="body">{c.label}</Typography>
                          </VStack>
                          <Icon as={ChevronDownIcon} />
                        </HStack>
                      }
                      sections={[
                        {
                          actions: [
                            {
                              id: '1',
                              reactNode: (
                                <ReportHeaderMenuContent
                                  cash={c.extraData?.cash ?? 0}
                                  sovereigns={c.extraData?.sovereigns ?? 0}
                                  currency={c.extraData?.currency ?? ''}
                                  companiesOnlyInvestment={
                                    c.extraData?.companiesOnlyInvestment ?? 0
                                  }
                                />
                              ),
                            },
                          ],
                        },
                      ]}
                      size="custom"
                    />
                  </HStack>
                </VStack>
              ) : (
                <VStack spacing="0px" flex={1} alignItems="start" key={index} padding="8px">
                  {c.element}
                  <Typography variant="body">{c.label}</Typography>
                </VStack>
              )
            )}
          </HStack>
        ) : (
          <>
            <HStack pt="6px" justifyContent="space-between" alignItems="flex-end" width="100%">
              {content
                .filter((c) => !c.withActionButton)
                .map((c, index) => (
                  <VStack spacing="0px" flex={1} alignItems="start" key={index} padding="8px">
                    {c.element}
                    <Typography variant="body">{c.label}</Typography>
                  </VStack>
                ))}
            </HStack>
            <Box width="100%">
              {content
                .filter((c) => c.withActionButton)
                .map((c, index) => (
                  <HStack
                    key={index}
                    justifyContent="space-between"
                    width="100%"
                    padding="8px"
                    borderRadius="6px"
                    cursor="pointer"
                    background="bg.interactive"
                    _hover={{ bg: 'bg.hover', cursor: 'pointer' }}
                    _active={{ bg: 'bg.pressed', cursor: 'pointer' }}
                  >
                    <Menu
                      menuWidth="100%"
                      menuButton={
                        <HStack width="100%" justifyContent="space-between">
                          <VStack spacing="0px" flex={1} alignItems="start">
                            {c.element}
                            <Typography variant="body">{c.label}</Typography>
                          </VStack>
                          <Icon as={ChevronDownIcon} />
                        </HStack>
                      }
                      sections={[
                        {
                          actions: [
                            {
                              id: '1',
                              reactNode: (
                                <ReportHeaderMenuContent
                                  cash={c.extraData?.cash ?? 0}
                                  sovereigns={c.extraData?.sovereigns ?? 0}
                                  currency={c.extraData?.currency ?? ''}
                                  companiesOnlyInvestment={
                                    c.extraData?.companiesOnlyInvestment ?? 0
                                  }
                                />
                              ),
                            },
                          ],
                        },
                      ]}
                      size="custom"
                    />
                  </HStack>
                ))}
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};
