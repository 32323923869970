import usePortfolios, {
  useDeletePortfolioCompany,
  useUpsertPortfolioNonEligibleFinancials,
} from 'containers/Portfolios/Portfolios.hooks';
import React, { useEffect, useState } from 'react';
import { ActionMenu, HelpTooltip, SearchInput, Table } from 'Molecules';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  Button,
  Checkbox,
  EmptyState,
  Tag,
  Tooltip,
  NumberInput,
  NumberInputProps,
  IconButton,
} from 'Atoms';
import { AvatarWithName } from 'Organisms';
import { useCurrentCompany, useDebounce, useToast } from 'utils/hooks';
import {
  GetPortfolioNonEligibleFinancialsQuery_,
  InvestmentCategory_Enum_,
  Portfolio,
  PortfolioCompany,
  QuarterEnum_Enum_,
  ValueOfInvestments,
  useUserCompaniesQuery,
} from 'models';
import { CommentIcon, DeleteIcon, EditIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { EligibilityStatus, PortfolioCompanyEditorModal } from './PortfolioCompanyEditor';
import { NudgePortfolioCompanyModal } from 'containers/Portfolios/pieces/NudgePortfolioCompanyModal';
import { ReportPeriods } from './PortfolioView';
import { NotEligibleTag, PortfolioCompanyProgress as TaxonomyProgress } from './PortfolioTaxonomy';
import { useUserData } from '@nhost/react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatQuarterYear } from 'utils/date';
import { InvestmentInput } from './InvestmentInput';
import { MarketValueInput } from './MarketValueInput';
import { HelpIcon } from 'Tokens/Icons/Status';
import { CashIcon, CompanyIcon, SovereignsIcon } from 'Tokens/Icons/Data';
import { ColumnDef } from '@tanstack/react-table';
import { useKnowledgeBase } from 'Features/KnowledgeBase';
import { ARTICLE_INCLUDING_CASH_SOVEREIGNS } from 'Features/KnowledgeBase/KnowledgeBaseArticles.ts';
import { SquareIconWrapper } from 'Tokens/Icons/SquareIconWrapper';
import { captureException } from '@sentry/react';

type PortfolioCompanyTableType = Partial<
  Portfolio['portfolioCompanies'][number] & { rowType: InvestmentCategory_Enum_; name: string }
>;

const NonEligibleFinancialsInput = (
  props: NumberInputProps & {
    reportPeriod: ReportPeriods;
    reportingPeriod: string;
    values: ValueOfInvestments;
    type: InvestmentCategory_Enum_;
  }
) => {
  const [quarter, year] = props.reportingPeriod.split('-');
  const [localValue, setLocalValue] = useState<number>(props.value ? Number(props.value) : 0);
  const { portfolioId } = useParams();
  const toast = useToast();
  const { t } = useTranslation('portfolio');

  const upsertPortfolioNonEligibleFinancials = useUpsertPortfolioNonEligibleFinancials();

  const updateValues = () => {
    const modifiedValues = {
      ...props.values,
      [props.reportPeriod]: localValue,
    };
    try {
      upsertPortfolioNonEligibleFinancials([
        {
          portfolioId: portfolioId,
          quarter: quarter as QuarterEnum_Enum_,
          year: Number(year),
          type: props.type,
          value: modifiedValues,
          isSelected: true,
        },
      ]);
      toast({
        text: `${t('portfolio:toasts.success')}`,
      });
    } catch (error) {
      captureException(error, {
        extra: { errorMessage: 'Failed to add nonEligibleFinancials' },
      });
      toast({
        text: `${t('portfolio:toasts.fail')}`,
        variant: 'danger',
      });
    }
  };

  const debouncedValue = useDebounce<number>(localValue, 750);

  const onValueChange = (value: number) => {
    setLocalValue(isNaN(value) ? 0 : Number(value));
  };

  useEffect(() => {
    if (props.value !== localValue) {
      updateValues();
    }
  }, [debouncedValue, props.value]);

  return (
    <Tooltip label={props.isDisabled ? t('portfolio:investmentValue.editTooltipQuarter') : ''}>
      <NumberInput
        {...props}
        keepWithinRange
        clampValueOnBlur
        value={localValue}
        onChange={onValueChange}
        format={(value) => {
          return value.toLocaleString('en', {
            useGrouping: true,
          });
        }}
      />
    </Tooltip>
  );
};

const PaiTag = ({ progress, isShared }: { progress: number; isShared: boolean }) => {
  const { t } = useTranslation('portfolio');
  if (!isShared) {
    return (
      <Tooltip label="The assessment has not yet been shared by the company and the progress is therefore not available">
        <Tag variant="ghost" rightIcon={<HelpIcon />}>
          {t('common:assessment.isLocked.notShared')}
        </Tag>
      </Tooltip>
    );
  } else if (progress === 0) {
    return <Tag> {t('portfolio:progress.notStarted')}</Tag>;
  } else if (progress < 1) {
    return <Tag variant="info">{t('portfolio:progress.inProgress')}</Tag>;
  } else if (isNaN(progress)) {
    return <Typography>--</Typography>;
  }
  return <Tag variant="success">{t('portfolio:progress.completed')}</Tag>;
};

export const protfolioCompaniesSorter = (a?: string, b?: string) => {
  return a?.localeCompare(b ?? '') ?? 1;
};

export const PortfolioCompanies = ({
  reportingPeriod,
  reportPeriod,
  paiProgressPerCompany,
  isAuditor = false,
  showCashRow,
  showSovereignRow,
  portfolioNonEligibleFinancials,
}: {
  reportingPeriod: string;
  reportPeriod: ReportPeriods;
  paiProgressPerCompany: Record<string, number>;
  isAuditor?: boolean;
  showCashRow?: boolean;
  showSovereignRow?: boolean;
  portfolioNonEligibleFinancials?: GetPortfolioNonEligibleFinancialsQuery_['PortfolioNonEligibleFinancials'];
}) => {
  const { t } = useTranslation('portfolio');
  const { getPortfolio } = usePortfolios();
  const user = useUserData();
  const { onOpen: openArticle } = useKnowledgeBase();

  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<PortfolioCompany[]>([]);
  const [currentEdit, setCurrentEdit] = useState<PortfolioCompany | undefined>(undefined);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showNudger, setShowNudger] = useState<boolean>(false);

  const deletePortfolioCompany = useDeletePortfolioCompany();

  const { company } = useCurrentCompany();

  const { portfolioId } = useParams();
  const portfolio = getPortfolio(portfolioId ?? '', reportingPeriod);

  const portfolioCompanies =
    portfolio?.portfolioCompanies
      ?.filter((pc) => formatQuarterYear(pc.quarter, pc.year) === reportingPeriod)
      .sort((a, b) => protfolioCompaniesSorter(a?.company?.name, b?.company?.name)) ?? [];

  const filteredPortfolioCompanies = !searchValue
    ? portfolioCompanies
    : portfolioCompanies?.filter((portfolioCompany) => {
        return portfolioCompany.name?.toLowerCase().includes(searchValue.toLowerCase());
      });

  const cashValueData = portfolioNonEligibleFinancials?.find(
    (pnf) => pnf.type === InvestmentCategory_Enum_.Cash_
  );

  const sovereignValueData = portfolioNonEligibleFinancials?.find(
    (pnf) => pnf.type === InvestmentCategory_Enum_.Sovereign_
  );

  const tableData: PortfolioCompanyTableType[] = [...filteredPortfolioCompanies];

  if (showCashRow) {
    tableData.push({
      id: 'cash',
      name: 'Cash',
      rowType: InvestmentCategory_Enum_.Cash_,
      valueOfInvestments: cashValueData?.value ?? { q1: 0, q2: 0, q3: 0, q4: 0 },
    });
  }
  if (showSovereignRow) {
    tableData.push({
      id: 'sovereigns',
      name: 'Sovereigns',
      rowType: InvestmentCategory_Enum_.Sovereign_,
      valueOfInvestments: sovereignValueData?.value ?? { q1: 0, q2: 0, q3: 0, q4: 0 },
    });
  }

  const isNonEligibleFinancialRow = (type?: InvestmentCategory_Enum_) => {
    if (type === InvestmentCategory_Enum_.Cash_ || type === InvestmentCategory_Enum_.Sovereign_) {
      return true;
    }
    return false;
  };

  const tableColumns: ColumnDef<PortfolioCompanyTableType>[] = [
    {
      header: () => (
        <Center width="50px">
          <Checkbox
            isDisabled={!filteredPortfolioCompanies.length}
            isChecked={selectedRows.length === filteredPortfolioCompanies?.length}
            onChange={(e) =>
              setSelectedRows(e.currentTarget.checked ? (filteredPortfolioCompanies ?? []) : [])
            }
          />
        </Center>
      ),
      id: 'checkbox',
      size: 0,
      maxSize: 0,
      cell: ({ row }) => {
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return;
        }
        return (
          <Center width="50px" height="100%">
            <Checkbox
              isChecked={!!selectedRows.find((x) => (row.original as PortfolioCompany).id === x.id)}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  setSelectedRows((cur) => [...cur, row.original as PortfolioCompany]);
                } else
                  setSelectedRows((cur) => [
                    ...cur.filter((item) => item.id !== (row.original as PortfolioCompany).id),
                  ]);
              }}
            />
          </Center>
        );
      },
    },
    {
      header: () => <Typography variant="bodyStrong">{t('common:company')}</Typography>,
      id: 'name',
      meta: {
        colSpan: 1,
      },
      cell: ({ row }) => {
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return (
            <HStack width="100%">
              {row.original.rowType === InvestmentCategory_Enum_.Cash_ ? (
                <SquareIconWrapper icon={CashIcon} size="28px" color="white" />
              ) : (
                <SquareIconWrapper icon={SovereignsIcon} size="28px" color="white" />
              )}
              <VStack alignItems="start" spacing="0px">
                <Typography variant="bodyStrong">{row.original.name}</Typography>
              </VStack>
            </HStack>
          );
        }
        const hasAccessToCompany = userCompaniesData?.data?.companies?.find(
          (c) => c.company?.id === row.original?.company?.id
        );

        return (
          <HStack
            width="100%"
            onClick={() => hasAccessToCompany && navigate(`/${row.original?.company?.id}`)}
          >
            <SquareIconWrapper icon={CompanyIcon} size="28px" color="white" />
            <Tooltip label={hasAccessToCompany ? t('common:goToCompany') : ''}>
              <Typography variant="bodyStrong">
                {(row.original as PortfolioCompany).company?.name ??
                  (row.original as PortfolioCompany).estimateCompany?.name ??
                  t('portfolio:fields.noName')}
              </Typography>
            </Tooltip>
          </HStack>
        );
      },
    },
    {
      header: () => (
        <HStack spacing="8px">
          <VStack spacing="0px" alignItems="start">
            <Typography variant="bodyStrong">{t('portfolio:investmentValue.value')}</Typography>
            <Typography variant="micro">{company?.currency}</Typography>
          </VStack>
          <HelpTooltip label={t('companiesView.tooltips.valueOfInvestment')} />
        </HStack>
      ),
      accessorKey: 'amount',
      cell: ({ row }) => {
        const valueOfInvestments = row.original.valueOfInvestments;
        const quarterValue: number = valueOfInvestments?.[reportPeriod];
        const yearValue: number =
          (valueOfInvestments?.q1 +
            valueOfInvestments?.q2 +
            valueOfInvestments?.q3 +
            valueOfInvestments?.q4) /
          4;
        const id = row.original.id;
        const isQuarter = reportPeriod !== 'year';

        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return (
            <NonEligibleFinancialsInput
              reportPeriod={reportPeriod}
              reportingPeriod={reportingPeriod}
              value={isQuarter ? quarterValue : yearValue}
              type={row.original.rowType as InvestmentCategory_Enum_}
              values={row.original.valueOfInvestments}
              isDisabled={!isQuarter || isAuditor}
            />
          );
        }
        return (
          <InvestmentInput
            isDisabled={!isQuarter || isAuditor}
            width="100%"
            id={id}
            reportPeriod={reportPeriod}
            valueOfInvestments={valueOfInvestments}
            maxValue={row.original.marketValue}
            value={isQuarter ? quarterValue : yearValue}
            isAuditor={isAuditor}
            isQuarter={isQuarter}
          />
        );
      },
    },
    {
      header: () => (
        <HStack spacing="9px">
          <VStack spacing="0px" alignItems="start">
            <Typography variant="bodyStrong">
              {t('portfolio:investmentValue.enterprise')}
            </Typography>
            <Typography variant="micro">{company?.currency}</Typography>
          </VStack>
          {reportPeriod !== ReportPeriods.year ? (
            <HelpTooltip label={t('companiesView.tooltips.enterpriseValue')} />
          ) : (
            <HelpTooltip label={t('companiesView.tooltips.enterpriseValueYear')} />
          )}
        </HStack>
      ),
      accessorKey: 'market-value',
      cell: ({ row }) => {
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return;
        }
        const id = row.original.id;
        const isYear = reportPeriod === 'year';
        const value = row.original.marketValue;
        const valueOfInvestments = row.original.valueOfInvestments;
        //the minMarketValue is the largest valueOfInvestments
        const minMarketValue: number = Math.max(
          valueOfInvestments?.q1,
          valueOfInvestments?.q2,
          valueOfInvestments?.q3,
          valueOfInvestments?.q4
        );
        return (
          <MarketValueInput
            isDisabled={!isYear || isAuditor}
            id={id}
            value={value}
            isAuditor={isAuditor}
            isYear={isYear}
            minValue={minMarketValue}
          />
        );
      },
    },
    {
      header: () => (
        <Typography variant="bodyStrong">{t('portfolio:investmentValue.taxonomy')}</Typography>
      ),
      accessorKey: 'eu-taxonomy-status',
      cell: ({ row }) => {
        const isShared = !!row.original?.sharedAssessment;
        const isTaxonomyEligible =
          row?.original?.eligibilityStatus !== EligibilityStatus.notEligible;
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return;
        }
        if (isTaxonomyEligible) {
          return (
            <TaxonomyProgress
              progress={
                row?.original?.progress ?? {
                  financials: 0,
                  screening: 0,
                  isLocked: false,
                }
              }
              isShared={isShared}
            />
          );
        } else {
          return <NotEligibleTag />;
        }
      },
    },
    {
      header: () => (
        <Typography variant="bodyStrong">{t('portfolio:investmentValue.pai')}</Typography>
      ),
      accessorKey: 'pai-status',
      cell: ({ row }) => {
        const isShared = !!row.original?.sharedPaiCompanyReportId;

        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return;
        }
        return (
          <PaiTag progress={paiProgressPerCompany[row.original?.company?.id]} isShared={isShared} />
        );
      },
    },
    {
      header: () => (
        <Typography variant="bodyStrong">{t('portfolio:investmentValue.contactPerson')}</Typography>
      ),
      accessorKey: 'contact-person',
      meta: {
        colSpan: 1,
      },
      cell: ({ row }) => {
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return;
        }
        const contactPersonName = row.original.contactPersonName ?? '';
        return (
          <>
            {row.original.contactPersonName ? (
              <AvatarWithName
                user={{
                  displayName: contactPersonName,
                  avatarUrl: '',
                }}
              />
            ) : (
              <Typography variant="body">--</Typography>
            )}
          </>
        );
      },
    },
    {
      header: '',
      id: 'actions',
      size: 0,
      maxSize: 0,
      cell: ({ row }) => {
        if (isNonEligibleFinancialRow(row.original.rowType)) {
          return (
            <IconButton
              size="s"
              variant="ghost"
              icon={<HelpIcon />}
              aria-label="Info button"
              onClick={() => openArticle(ARTICLE_INCLUDING_CASH_SOVEREIGNS.slug)}
            />
          );
        }
        return (
          <ActionMenu
            actions={[
              {
                name: t('common:actions.edit'),
                onClick: () => setCurrentEdit(row.original as PortfolioCompany),
                icon: <EditIcon color="inherit" />,
                isDisabled: isAuditor,
              },
              {
                name: t('common:actions.delete'),
                onClick: () => deletePortfolioCompany(row.original as PortfolioCompany),
                icon: <DeleteIcon color="inherit" />,
                isDisabled: isAuditor,
              },
              {
                name: t('portfolio:reminderReport'),
                onClick: () => {
                  setSelectedRows([row.original as PortfolioCompany]);
                  setShowNudger(true);
                },
                icon: <CommentIcon color="inherit" />,
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <VStack spacing="md" width="100%" alignItems="center" paddingTop="16px">
      <VStack spacing="16px" width="100%">
        <HStack justifyContent="space-between" width="100%">
          <SearchInput
            search={searchValue}
            setSearch={setSearchValue}
            placeholder={t('portfolio:investmentValue.filterCompanies')}
            minW="200px"
          />
          <HStack spacing="8px">
            <Button
              variant="ghost"
              leftIcon={<CommentIcon color="inherit" />}
              onClick={() => {
                setSelectedRows(filteredPortfolioCompanies ?? []);
                setShowNudger(true);
              }}
            >
              {t('portfolio:reminderReport')}
            </Button>
          </HStack>
        </HStack>
      </VStack>

      {!filteredPortfolioCompanies.length && searchValue ? (
        <Box w="100%" h="100%">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setSearchValue('');
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            component={true}
          />
        </Box>
      ) : (
        <Table
          rowProps={{ verticalAlign: 'top' }}
          cellProps={{ verticalAlign: 'top' }}
          columns={tableColumns}
          data={tableData}
        />
      )}
      <PortfolioCompanyEditorModal
        portfolioCompany={currentEdit}
        isOpen={isCreating || !!currentEdit}
        onClose={() => {
          setCurrentEdit(undefined);
          setIsCreating(false);
        }}
        currentPortfolioCompanyIds={portfolioCompanies.map((pc) => pc.id)}
        reportYear={reportingPeriod}
      />
      <NudgePortfolioCompanyModal
        portfolioCompanies={selectedRows}
        isOpen={showNudger}
        onClose={() => {
          setShowNudger(false);
          setSelectedRows([]);
        }}
      />
    </VStack>
  );
};
