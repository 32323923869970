import { HStack, VStack } from '@chakra-ui/react';
import { FolderIcon } from 'Tokens/Icons/Data';
import { Accordion } from 'Atoms/Accordion/Accordion.tsx';
import { BusinessUnitFinancialTable } from './BusinessUnitFinancialTable.tsx';
import { useGroupFinancials } from './FinancialTables.hooks.ts';
import { Tag } from 'Atoms/Tag/Tag.tsx';
import { useTranslation } from 'utils/translation/translation.ts';
import { Typography } from 'Tokens/Typography/Typography.tsx';

type GroupFinancialsProps = {
  reportingGroupId: string;
  bg: 'solid' | 'outlined';
};

export const GroupFinancials = ({ reportingGroupId, bg }: GroupFinancialsProps) => {
  const { reportingGroup, childGroups, groupBusinessUnits, isDone } =
    useGroupFinancials(reportingGroupId);
  const { t } = useTranslation();
  if (!reportingGroup) return null;

  return (
    <Accordion
      variant={bg}
      marginBottom="12px"
      title={reportingGroup.name}
      lazyLoadContent
      items={[
        {
          label: reportingGroup.name,
          icon: <FolderIcon marginTop="8px" />,
          accordionNb: childGroups.length + groupBusinessUnits.length,
          tag:
            isDone !== undefined
              ? [
                  <Tag variant={isDone ? 'success' : 'default'}>
                    {isDone ? t('common:buttons.done') : t('common:buttons.todo')}
                  </Tag>,
                ]
              : [<Tag>{t('common:words.empty')}</Tag>],
          content: (
            <VStack alignItems="flex-start" bg={bg === 'solid' ? 'bg.muted' : 'white'}>
              {childGroups &&
                childGroups.map((rg) => (
                  <GroupFinancials key={rg.id} reportingGroupId={rg.id} bg="outlined" />
                ))}
              {groupBusinessUnits.length ? (
                groupBusinessUnits.map((bu) => (
                  <BusinessUnitFinancialTable key={bu.id} businessUnit={bu} />
                ))
              ) : (
                <>
                  {!childGroups.length && (
                    <HStack width="100%" justifyContent="center">
                      <Typography variant="bodyStrong">
                        {t('common:assessment.groupFinancials.reporting')}
                      </Typography>
                    </HStack>
                  )}
                </>
              )}
            </VStack>
          ),
        },
      ]}
    />
  );
};
