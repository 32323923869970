import { Table as ChakraTable, Thead, Tbody, Tr, Td, VStack, Skeleton } from '@chakra-ui/react';

const SkeletonRow = ({ columns }: { columns: { height: string; width: string }[] }) => (
  <Tr>
    {columns.map((col, index) => (
      <Td key={index}>
        <Skeleton height={col.height} width={col.width} />
      </Td>
    ))}
  </Tr>
);

const SkeletonTextBlock = () => (
  <Tr>
    <Td>
      <VStack spacing="2px" alignItems="flex-start">
        <Skeleton height="20px" width="412px" />
        <Skeleton height="16px" width="236px" />
      </VStack>
    </Td>
  </Tr>
);

export const SkeletonPaiIndicatorsResultsTable = () => {
  const skeletonColumns = [
    { height: '16px', width: '716px' },
    { height: '20px', width: '70px' },
    { height: '20px', width: '40px' },
    { height: '20px', width: '50px' },
  ];

  return (
    <VStack width="100%" alignItems="stretch">
      <ChakraTable size="sm">
        <Thead>
          <SkeletonRow
            columns={[
              { height: '18px', width: '740px' },
              { height: '18px', width: '156px' },
              { height: '18px', width: '156px' },
              { height: '18px', width: '156px' },
            ]}
          />
        </Thead>
        <Tbody>
          <SkeletonTextBlock />
          {Array.from({ length: 5 }).map((_, index) => (
            <SkeletonRow key={index} columns={skeletonColumns} />
          ))}
          <SkeletonTextBlock />
          {Array.from({ length: 2 }).map((_, index) => (
            <SkeletonRow key={index} columns={skeletonColumns} />
          ))}
        </Tbody>
      </ChakraTable>
    </VStack>
  );
};
