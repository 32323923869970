import { useToast } from '@chakra-ui/react';
import { Modal, Select } from 'Molecules';
import { Suspense, useMemo, useState } from 'react';
import { usePortfolioBulkActions } from '../BulkUpload.hooks';
import { Button, DateInput } from 'Atoms';
import { useAllActivities } from 'Features/Screening/Screening.hooks';
import { useUserCompaniesQuery } from 'models';
import { useUserData } from '@nhost/react';
import { MultiValue } from 'react-select';
import { Uploader } from 'Features/CSVUploader/Uploader.tsx';

interface PortfolioBulkUploaderProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: { title?: string; description?: string; button?: string }[];
  onUploadDone?: (object: any[]) => void | Promise<string[]> | boolean;
}

const ALL_OPTION = { label: 'All', value: 'all' };

export const PortfolioBulkUploader = ({ isOpen, onClose }: PortfolioBulkUploaderProps) => {
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [reportingPeriod, setReportingPeriod] = useState<Date>(new Date());
  const [activityRefs, setActivityRefs] = useState<string[]>([ALL_OPTION.value]);
  const [selectedCompanies, setSelectedCompanies] = useState<
    MultiValue<{ label: string; value: string }>
  >([ALL_OPTION]);
  const [rejectionMessages, setRejectionMessages] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toast = useToast();

  const resetStates = () => {
    setFile(null);
    setIsFileUploaded(false);
    setRejectionMessages([]);
  };

  const activities = useAllActivities();
  const user = useUserData();
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: { id: user?.id },
  });
  const userCompanies =
    userCompaniesData?.data?.companies.filter(
      (c) => !c.company.isGroupOwner && !c.company.isPortfolioOwner
    ) ?? [];
  const { downloadTemplate, uploadTemplate } = usePortfolioBulkActions();

  const handleDownloadTemplate = () => {
    const selectedOptions = selectedCompanies.map((option) => option.value);

    const companies = selectedOptions.includes('all')
      ? userCompanies.map((c) => c.company.id)
      : selectedOptions;

    downloadTemplate(reportingPeriod.getFullYear(), activityRefs, companies);
  };

  const handleUploadTemplate = useMemo(
    () => async () => {
      if (!file) return;
      setIsLoading(true);
      const { res: response } = await uploadTemplate(file);
      if (response && response.status === 200) {
        setIsLoading(false);
        onClose();
        toast({
          title: 'Upload successful',
          description: 'Your data has been uploaded successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        setIsLoading(false);
        toast({
          title: 'Upload failed',
          description: 'Your data could not be uploaded. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [file]
  );

  const handleSelectCompanies = (selectedOptions: MultiValue<{ label: string; value: string }>) => {
    const optionsValues = selectedOptions.map((option) => option.value);

    if (optionsValues.includes('all')) {
      setSelectedCompanies([{ label: 'All', value: 'all' }]);
    } else {
      setSelectedCompanies(selectedOptions);
    }
  };

  const DEFAULT_DESCRIPTIONS = [
    {
      title: 'Choose reporting year',
      description:
        'Select the year where you want to edit the portfolio composition, and bulk upload assessment data.',
      Component: (
        <DateInput
          width="220px"
          value={reportingPeriod}
          setValue={(date) => date && setReportingPeriod(date)}
          showYearPicker
          dateFormat="yyyy"
          attachToId="portfolio-bulk-upload-modal"
        />
      ),
    },
    {
      title: 'Choose companies',
      Component: (
        <Select
          width="220px"
          isMulti
          options={[
            ALL_OPTION,
            ...userCompanies?.map((a) => ({ label: a.company.name, value: a.company.id })),
          ]}
          defaultValue={ALL_OPTION}
          value={selectedCompanies}
          onChange={handleSelectCompanies}
        />
      ),
    },
    {
      title: 'Select taxonomy activities',
      description:
        'If you know which activities your portfolio companies will use, select them here to make it a part of the template. If you are unsure, you can leave this blank and add the activities later.',
      Component: (
        <Select
          width="220px"
          isMulti
          options={[ALL_OPTION, ...activities.map((a) => ({ label: a.name, value: a.reference }))]}
          defaultValue={ALL_OPTION}
          onChange={(v) => setActivityRefs(v.map((a) => a.value))}
        />
      ),
    },
    {
      title: 'Download template',
      description: 'Download the template and fill in the data for your portfolio companies.',
      Component: <Button onClick={handleDownloadTemplate}>Download template</Button>,
    },
    {
      title: 'Upload filled in template',
    },
  ];

  const handleTemplateUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
    setFileName(uploadedFile.name);
    setIsFileUploaded(true);
  };

  return (
    <Modal
      size="md"
      title="Upload portfolio data"
      isOpen={isOpen}
      onClose={() => {
        resetStates();
        onClose();
      }}
      confirmText="Upload file"
      onConfirm={() => {
        handleUploadTemplate();
      }}
      confirmButtonProps={{ isDisabled: !isFileUploaded, isLoading }}
      contentProps={{ id: 'portfolio-bulk-upload-modal' }}
    >
      <Suspense>
        <Uploader
          acceptedTypes={['.xlsx']}
          onUpload={handleTemplateUpload}
          isFileUploaded={isFileUploaded}
          setIsFileUploaded={setIsFileUploaded}
          rejectionMessages={rejectionMessages}
          fileName={fileName}
          description={DEFAULT_DESCRIPTIONS}
        />
      </Suspense>
    </Modal>
  );
};
