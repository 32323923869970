import { FormField, Input } from 'Atoms';
import { BusinessUnit, useBusinessUnitsQuery } from 'models';
import { useMemo } from 'react';
import { Control, Controller, FieldValues, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCurrentCompanyId } from 'utils/hooks';

export const ReportingUnitController = <T extends FieldValues>({
  control,
  existingBU,
}: {
  control: Control<T, any>;
  existingBU?: BusinessUnit;
}) => {
  const { companyId } = useCurrentCompanyId();

  const { data: businessUnitsData } = useBusinessUnitsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const businessUnits = useMemo(() => {
    return businessUnitsData?.businessunits;
  }, [businessUnitsData]);
  const { errors } = useFormState({ control });

  const { cAssessmentId = '' } = useParams();
  const { t } = useTranslation(['assessment', 'bUnits']);

  return (
    <Controller<T, any>
      name="name"
      rules={{
        required: t('bUnits:reportingUnit.name'),
        validate: {
          notEmpty: (value) => value?.trim().length !== 0 || t('bUnits:reportingUnit.name'),
          notDuplicate: (value) =>
            !businessUnits?.find(
              (bu) =>
                bu.name === value?.trim() &&
                bu.name !== existingBU?.name &&
                bu?.cAssessmentIds?.find(
                  (cAssessment) => cAssessment.cAssessmentId === cAssessmentId
                )
            ) || t('bUnits:reportingUnit.duplicateName', { BUName: value }),
        },
      }}
      control={control}
      render={({ field }) => (
        <FormField
          id="name"
          label={t('assessment:duplicateAssessmentModal.modal.name')}
          isRequired
          isInvalid={!!errors.name}
          error={errors.name?.message?.toString()}
          helpTextAsTooltip={false}
        >
          <Input
            width="100%"
            value={field.value}
            onChange={field.onChange}
            ref={field.ref}
            onBlur={field.onBlur}
          />
        </FormField>
      )}
    />
  );
};
