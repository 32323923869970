import { HStack, useDisclosure } from '@chakra-ui/react';
import { AssessableMetrics } from '../Metrics';
import { Typography } from 'Tokens';
import {
  GetAssessmentCurrencyConversionQuery_,
  GetRequiredMaterialMetricsQuery_,
  QuestionType_Enum_,
  useGetPoliciesDrQuery,
  User,
} from 'models';
import { AggregatedQualitativeAnswers, MetricsTableData } from '..';
import { Button, Tooltip } from 'Atoms';
import { AIIcon } from 'Tokens/Icons/Function';
import { MetricsAIModal } from './MetricsAIModal';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { TimePeriods } from '../../Requirement';
import { useTranslation } from 'utils/translation';
import { useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { useCurrentCompany } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { MetricsAIDisclaimer } from './MetricsAIDisclaimer';

export const MetricsAISection = ({
  materialStandardId,
  reportingUnitId,
  disclosureRequirementRef,
  allFilteredMetrics,
  selectedQuarter,
  selectedMetric,
  setSelectedMetric,
  onOpen,
  isGroup,
  rowData,
  setRowData,
  esrsAssessmentProjectLeader,
  qualitativeMetrics,
  summaryQualitativeMetrics,
  isPolicy = false,
  aggregatedMetricsAnswers,
  parentStandardId,
  requiredMaterialMetrics,
  currencyConversionData,
}: {
  materialStandardId: string;
  disclosureRequirementRef: string;
  reportingUnitId?: string;
  allFilteredMetrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  selectedMetric?: SelectedMetric;
  setSelectedMetric: (param: SelectedMetric) => void;
  onOpen: () => void;
  isGroup: boolean;
  rowData?: MetricsTableData;
  setRowData: (data: MetricsTableData | undefined) => void;
  esrsAssessmentProjectLeader?: Partial<User>;
  qualitativeMetrics: MetricsTableData[];
  summaryQualitativeMetrics: AssessableMetrics;
  isPolicy?: boolean;
  aggregatedMetricsAnswers: AggregatedQualitativeAnswers;
  parentStandardId: string | undefined;
  requiredMaterialMetrics: GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric'];
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion'];
}) => {
  const { isOpen: isAIModalOpen, onClose: onAIModalClose, onOpen: onAIModalOpen } = useDisclosure();
  const {
    isOpen: isAIDisclaimerOpen,
    onClose: onAIDisclaimerClose,
    onOpen: onAIDisclaimerOpen,
  } = useDisclosure();
  const [isAIDisclaimerDismissed, setisAIDisclaimerDismissed] = useUserSetting(
    'ai-disclaimer-dismissed',
    false
  );
  const { t } = useTranslation(['common, ai']);
  const { company } = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags({ company });
  const { esrsAssessmentId = '', standardRef = '' } = useParams();

  // Policy attachments, used here to track and disable AI button for policies and passed as prop to the modal
  const { data: policiesData } = useGetPoliciesDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !isPolicy || !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const policyAttachments = useMemo(() => {
    return policiesData?.DisclosureRequirement_by_pk?.policies[0]?.attachmentBox?.attachments ?? [];
  }, [policiesData]);

  const quantitativeMetrics = useMemo(
    () =>
      allFilteredMetrics.filter(
        (metric) => metric.metric.metricType === QuestionType_Enum_.Decimal_
      ),
    [allFilteredMetrics]
  );

  return (
    <>
      <HStack
        w="100%"
        p="4px 4px 4px 8px"
        border="1px solid"
        borderColor="border.selected.accent"
        borderRadius="6px"
      >
        <HStack spacing="8px" w="100%" justifyContent="space-between">
          <HStack spacing="8px">
            <AIIcon color="text.info" />
            <Typography variant="bodyStrong">
              {isPolicy
                ? 'These answers could be generated by AI using your policy documents'
                : 'Generate answers with AI'}
            </Typography>
          </HStack>

          <Tooltip
            label={
              !hasAiAccess
                ? t('common:aiConsent')
                : isPolicy && !policyAttachments.length
                  ? 'Please add a policy to unlock AI'
                  : ''
            }
            isDisabled={hasAiAccess && (isPolicy ? !!policyAttachments.length : true)}
            placement="bottom-end"
          >
            <Button
              size="sm"
              variant="primary"
              onClick={isAIDisclaimerDismissed ? onAIModalOpen : onAIDisclaimerOpen}
              isDisabled={!hasAiAccess || (isPolicy && !policyAttachments.length)}
            >
              Start
            </Button>
          </Tooltip>
        </HStack>
      </HStack>

      <MetricsAIDisclaimer
        isOpen={isAIDisclaimerOpen}
        onClose={onAIDisclaimerClose}
        onAIModalOpen={onAIModalOpen}
        setAIDisclaimerDismissed={setisAIDisclaimerDismissed}
      />

      <MetricsAIModal
        isOpen={isAIModalOpen}
        onAIDisclaimerOpen={onAIDisclaimerOpen}
        onClose={onAIModalClose}
        disclosureRequirementRef={disclosureRequirementRef}
        materialStandardId={materialStandardId}
        reportingUnitId={reportingUnitId}
        allFilteredMetrics={quantitativeMetrics}
        selectedQuarter={selectedQuarter}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        onOpen={onOpen}
        isGroup={isGroup}
        rowData={rowData}
        setRowData={setRowData}
        esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
        qualitativeMetrics={qualitativeMetrics}
        summaryQualitativeMetrics={summaryQualitativeMetrics}
        aggregatedMetricsAnswers={aggregatedMetricsAnswers}
        isPolicy={isPolicy}
        policyAttachments={policyAttachments}
        parentStandardId={parentStandardId}
        requiredMaterialMetrics={requiredMaterialMetrics}
        currencyConversionData={currencyConversionData}
      />
    </>
  );
};
