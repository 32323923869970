import { Box, ChakraProps, HStack } from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { AvatarGroup } from 'Molecules/AvatarGroup';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { WarningIcon } from 'Tokens/Icons/Status.tsx';
import React from 'react';
import { useTranslation } from 'utils/translation';

type SharedWithCompanies = {
  name: string;
  hasPaiAccess?: boolean;
  logoUrl?: string | null | undefined;
}[];

export const ShareButton = ({
  isShared,
  onClick,
  companiesSharedWith,
  size = 'sm',
  width = 'unset',
}: {
  isShared: boolean;
  onClick?: () => void;
  companiesSharedWith: SharedWithCompanies;
  size?: string;
  width?: ChakraProps['width'];
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Tag
      rightIcon={<ChevronDownIcon color={isShared ? undefined : 'text.warning'} />}
      size={size}
      backgroundColor={isShared ? 'bg.interactive' : 'bg.warning'}
      onClick={(e) => {
        if (onClick) onClick();
        e.stopPropagation();
      }}
      variant={isShared ? undefined : 'warning'}
      clickable={onClick ? true : false}
      paddingInline="8px"
      width={width}
      border="1px solid"
      borderColor="border.warning"
      justifyContent="space-between"
    >
      <HStack spacing="6px" alignItems="center">
        {isShared ? (
          <HStack>
            <Typography variant="bodyStrong">{t('common:assessmentTable.shared')}</Typography>
            <Box h="20px">
              <AvatarGroup
                isRounded={false}
                size="xs"
                key="investors-avatars"
                namesOrLogos={companiesSharedWith.map((company) => ({
                  name: company.name,
                  logoUrl: company.logoUrl ?? undefined,
                }))}
                maximumNumberOfAvatars={2}
                truncatedAvatarSize="xs"
              />
            </Box>
          </HStack>
        ) : (
          <HStack>
            <WarningIcon color="text.warning" />
            <Typography variant="bodyStrong" color="text.warning">
              {t('common:assessmentTable.notShared')}
            </Typography>
          </HStack>
        )}
      </HStack>
    </Tag>
  );
};
