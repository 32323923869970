import { HStack, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import { Typography } from 'Tokens';
import { MaterialityAssessmentHeader } from '../../MaterialityAssessment/MaterialitySettingsHeader';
import { Infobox, Tag } from 'Atoms';
import { useMemo } from 'react';
import { CurrencyConversionTable } from './CurrencyConversionTable';
import { useTranslation } from 'utils/translation';
import { GetSubCurrenciesQuery_, useGetAssessmentCurrencyConversionQuery } from 'models';
import { useParams } from 'react-router-dom';
import { CurrencyConversionTableRow } from './CurrencyConversion.d';

export const CurrencyConversion = ({
  isOnboarding,
  groupCurrency,
  subCurrencies,
  companyName,
}: {
  isOnboarding: boolean;
  groupCurrency?: string;
  subCurrencies?: GetSubCurrenciesQuery_['EsrsAssessment'];
  companyName?: string;
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const { t } = useTranslation('common');
  const { data: assessmentCurrencyData, loading: assessmentCurrencyDataLoading } =
    useGetAssessmentCurrencyConversionQuery({
      variables: {
        assessmentId: esrsAssessmentId,
      },
      skip: !esrsAssessmentId,
    });
  const currencies = useMemo(() => {
    const subCurrenciesMap = subCurrencies?.reduce((acc: CurrencyConversionTableRow[], curr) => {
      const currency = curr.company.currency;

      let currencyGroup = acc.find((item) => item.from === currency);

      if (!currencyGroup) {
        currencyGroup = {
          companies: [],
          from: currency,
          to: groupCurrency ?? '',
          isGroupCurrency: false,
        };
        acc.push(currencyGroup);
      }

      currencyGroup.companies.push(curr.company.name);

      return acc;
    }, [] as CurrencyConversionTableRow[]);

    subCurrenciesMap?.unshift({
      companies: [companyName ?? ''],
      from: groupCurrency ?? '',
      to: groupCurrency ?? '',
      isGroupCurrency: true,
    });

    return subCurrenciesMap;
  }, [assessmentCurrencyData, subCurrencies, groupCurrency]);

  if (assessmentCurrencyDataLoading) return <Loader />;

  return (
    <ContentLayout
      variant="inline.nopad"
      header={isOnboarding && <ContentHeader title="Currency conversion setup" />}
      height={'fit-content'}
    >
      <VStack mt={'28px'} w="100%" spacing="16px" alignItems="start">
        {<MaterialityAssessmentHeader title="Manage currency rate setup" />}
        <Infobox
          status="highlight"
          title="How to calculate currency conversion rate"
          closable={false}
          description={
            <VStack gap="14px" alignItems="start">
              <Typography variant="body">
                The currency conversion rate is a multiplier used to convert financial data from a
                subsidiary's local currency into the group's reporting currency. This ensures
                consistency in the consolidated group reporting.
              </Typography>
              <Typography variant="body">Example:</Typography>
              <UnorderedList>
                <ListItem>
                  The group’s reporting currency is NOK (Norwegian Krone), while one of its
                  subsidiaries operates in € (Euro).
                </ListItem>
                <ListItem>
                  The group selects a conversion rate of 1€ = 11.70 NOK to ensure consistency with
                  the rate used in financial reporting.
                </ListItem>
                <ListItem>
                  The group will then input a conversion rate from € to NOK of 11.70
                </ListItem>
              </UnorderedList>
              <Typography variant="body">
                The subsidiary's financial data in € will then be multiplied by 11.70 when added to
                the group’s total.
              </Typography>
            </VStack>
          }
          withIcon
        />
        <Tag variant="info" w="100%" justifyContent="start">
          <HStack gap="4px">
            <Typography variant="detail" color="text.default">
              Group currency:
            </Typography>
            <Typography variant="bodyStrong" color="text.info">
              {groupCurrency ? t(`fields.currencyNames.${groupCurrency}`) : ''}
            </Typography>
          </HStack>
        </Tag>
        <CurrencyConversionTable currencies={currencies ?? []} />
      </VStack>
    </ContentLayout>
  );
};
