import React, { useMemo } from 'react';
import { TruncatableText } from 'Atoms';
import { mapUnitToCompanyCurrency } from '../../../../../../utils';
import { Row } from '@tanstack/react-table';
import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData } from '../../../MetricAnswers.hooks';

export const Unit = ({
  row,
  materialStandardId,
  optedOutMetrics,
  currency,
}: {
  row: Row<TableData<MetricsTableData>>;
  materialStandardId: string;
  optedOutMetrics?: string[];
  currency: string;
}) => {
  const hideTotal =
    (row.original.metric.materialMetrics?.find((m) => m.materialStandardId === materialStandardId)
      ?.materialMetricTags.length ?? 0) > 0 &&
    !row.original.metric.showPercentageWithTag &&
    !row.original.tagName;
  const hasOptedOut = useMemo(
    () => optedOutMetrics?.includes(row.original.metric.reference),
    [optedOutMetrics, row]
  );
  return (
    !row.original.referenceToSource &&
    !row.original.locked &&
    !hideTotal && (
      <TruncatableText
        text={
          row.original.metric.unitOfMeasurement
            ? mapUnitToCompanyCurrency(row.original.metric.unitOfMeasurement, currency)
            : ''
        }
        color={hasOptedOut ? 'text.hint' : 'text.default'}
      />
    )
  );
};
