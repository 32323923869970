import { Center, Circle, HStack, VStack } from '@chakra-ui/react';
import { Button, Tag, TruncatableText } from 'Atoms';
import { ListIcon } from 'Tokens/Icons/Data';
import { CheckIcon } from 'Tokens/Icons/Status';
import { GetReportsQuery_ } from 'models';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssessmentTypes, ReportItem, useHomeAssessments } from './Home.hooks';
import { Typography } from 'Tokens';
import { timeSinceDate } from 'utils/date';
import { useCompanyPaiReport, usePaiCategories } from 'containers/CompanyPai/CompanyPai.hooks';
import { ReportPeriods, TIME_PERIOD_FILTERS } from 'Features/PortfolioView';

const getTimePeriodValue = (period: ReportPeriods): string => {
  const filter = TIME_PERIOD_FILTERS.find((time) => time.key === period);
  return filter?.value ?? period;
};

const ReportCard = ({
  report,
  index,
  companyId,
}: {
  report: ReportItem;
  index: number;
  companyId: string;
}) => {
  const { t } = useTranslation('home');
  const translations = useTranslation('common');
  const { report: paiReport } = useCompanyPaiReport(
    Number(report.year) ?? new Date().getUTCFullYear(),
    ReportPeriods[report.period as keyof typeof ReportPeriods] ?? ReportPeriods.year,
    companyId
  );
  const categories = usePaiCategories(paiReport);

  const date = useMemo(() => {
    if (report.lastEdited === 0) {
      return '';
    }
    return timeSinceDate(new Date(report.lastEdited ?? 0), translations);
  }, [report]);

  const isReportDone = () => {
    if (report.type === AssessmentTypes.pai) {
      return categories.every((category) => category.isDone);
    } else {
      return report.status === 'done';
    }
  };

  return (
    <HStack
      key={report.id ?? index}
      padding="8px"
      bg="bg.interactive"
      borderRadius="12px"
      justifyContent="space-between"
      cursor="pointer"
      _hover={{ bg: 'bg.hover', cursor: 'pointer' }}
      _active={{ bg: 'bg.pressed' }}
      onClick={report.onClick}
      w="100%"
    >
      <HStack width="100%">
        {!isReportDone() ? (
          <Center height="36px" minWidth="36px" borderRadius="8px" bg="bg.unknown">
            <ListIcon boxSize="14px" />
          </Center>
        ) : (
          <Center height="36px" minWidth="36px" borderRadius="8px" bg="bg.success">
            <CheckIcon color="text.success" boxSize="13px" />
          </Center>
        )}
        <VStack alignItems="start" gap="0px">
          <HStack gap="4px">
            <Typography variant="bodyStrong">{report.type} </Typography>
            <Circle size="3px" bg="text.default" />
            <Typography variant="bodyStrong">{report.year} </Typography>

            {report?.period && report.type === AssessmentTypes.pai && (
              <HStack gap="4px">
                <Circle size="3px" bg="text.default" />
                <Typography variant="bodyStrong">
                  {getTimePeriodValue(report.period as ReportPeriods)}
                </Typography>
              </HStack>
            )}
          </HStack>
          {report.type === AssessmentTypes.taxonomy && (
            <TruncatableText
              variant="detail"
              color="text.default"
              text={`${report.title}${
                !!report?.period && report?.period !== 'year'
                  ? ` ${report?.period?.toUpperCase()}`
                  : ''
              }`}
            />
          )}
        </VStack>
      </HStack>
      <HStack w="40%" justifyContent="flex-end" gap="16px">
        <Typography variant="detail" color="text.hint">
          {date}
        </Typography>
        <Tag minWidth="fit-content">
          {!isReportDone() ? t('todo') : t('done')}
          {report?.count && report?.count > 0 ? (
            <Typography variant="detail" color="text.hint" ml="4px">
              {report.count} {t('new')}
            </Typography>
          ) : (
            <></>
          )}
        </Tag>
      </HStack>
    </HStack>
  );
};

export const HomeAssessments = ({
  assessments,
  isGroup,
  companyId,
}: {
  assessments?: GetReportsQuery_;
  isGroup: boolean;
  companyId: string;
}) => {
  const allAssessments = useHomeAssessments(assessments, companyId, isGroup);
  const [numberOfAssessments, setNumberOfAssessments] = useState(4);

  return (
    <VStack w="100%" gap="8px" alignItems="start">
      {allAssessments?.length &&
        allAssessments
          .slice(0, numberOfAssessments)
          .map((report, index) => (
            <ReportCard report={report} index={index} companyId={companyId} />
          ))}
      {allAssessments.length > numberOfAssessments && (
        <Button variant="ghost" onClick={() => setNumberOfAssessments(numberOfAssessments + 4)}>
          Show more...
        </Button>
      )}
    </VStack>
  );
};
