import { AbsoluteCenter, Box, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useCreateNumberMutation, useHighestNumberSubscription } from 'models';
import { Typography } from 'Tokens';

export const WebsocketTest = () => {
  const {
    data: highestNumber,
    error: subscriptionError,
    loading: subscriptionLoading,
  } = useHighestNumberSubscription();
  const [addOne, { data: addedNumber, error: addOneError, loading: addOneLoading }] =
    useCreateNumberMutation();

  return (
    <Box h="100vh" w="100vw" overflow="scroll">
      <AbsoluteCenter axis="both" maxH="100vh" overflow="scroll">
        <Typography variant="h1" mb="20px">
          Websocket Test
        </Typography>
        <HStack mb="20px">
          <Button variant="primary" onClick={() => addOne()} isLoading={addOneLoading}>
            Add one
          </Button>
          <Typography variant="h2">
            Just added: {addedNumber?.insert_WebsocketTest_one?.id ?? ''}
          </Typography>
        </HStack>
        <Typography variant="h2" mb="20px">
          Websocket number:{' '}
          {subscriptionLoading
            ? 'Loading...'
            : (highestNumber?.WebsocketTest_aggregate.aggregate?.max?.id ?? '')}
        </Typography>
        <Typography variant="h2">Write error:</Typography>
        <pre>{JSON.stringify(addOneError, null, 2) ?? ''}</pre>
        <Typography variant="h2">Websocket error:</Typography>
        <pre>{JSON.stringify(subscriptionError, null, 2) ?? ''}</pre>
      </AbsoluteCenter>
    </Box>
  );
};
