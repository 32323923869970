import { Box, Skeleton, useDisclosure, VStack } from '@chakra-ui/react';
import { Button, Tag } from 'Atoms';
import { AddIcon, EditIcon, MoreIcon } from 'Tokens/Icons/Function';
import { MDRMModal } from './MDRMModal';
import { isFrequencyYearly, MetricsTableData } from '../MetricAnswers.hooks';
import { AggregatedMetricsTableData, AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { useGetDatapointValues } from '../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';
import { useEffect, useMemo, useState } from 'react';
import { MdrmAnswerType, MDRMSidebar, TimePeriods, TimePeriodsEnums } from 'containers/Esrs/pieces';
import { useMdrmAnswers } from './MDRM.hooks';
import { MDRMTable } from './MDRMTable';
import { useSearchParams } from 'react-router-dom';

export const MDRM = ({
  metric,
  companyReportingUnitId,
  selectedQuarter,
  setRowData,
}: {
  metric: MetricsTableData | AggregatedMetricsTableData;
  companyReportingUnitId: string;
  selectedQuarter: TimePeriods;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    dataPointPerYear,
    dataPointsPerQuarter,
    loading: dpLoading,
  } = useGetDatapointValues(metric, companyReportingUnitId);
  const isYearly = useMemo(() => isFrequencyYearly(metric.metric), [metric]);

  const [openMdrm, setOpenMdrm] = useState<MdrmAnswerType | undefined>();
  const [openComments, setOpenComments] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const dataPointPerQuarter = useMemo(
    () => dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter)?.value,
    [selectedQuarter, dataPointsPerQuarter]
  );

  const dataPoint = useMemo(() => {
    if (isYearly) return dataPointPerYear;
    return dataPointPerQuarter;
  }, [isYearly, dataPointPerYear, dataPointPerQuarter]);

  const { answers, loading, onMdrmAnswerChange, upsertEmptyDatapoint } = useMdrmAnswers({
    datapointId: dataPoint?.id,
    metricRef: metric.metric.reference,
    row: metric,
    companyReportingUnitId: companyReportingUnitId,
    timeframe: isYearly ? TimePeriodsEnums.year : selectedQuarter,
  });

  // Clear the open mdrm state if the param is not set
  useEffect(() => {
    if (!searchParams.get('openMdrm')) {
      setOpenMdrm(undefined);
    }
  }, [searchParams]);

  const hasDecidedDisclosure = useMemo(
    () => typeof dataPoint?.isMdrmDisclosed === 'boolean',
    [dataPoint]
  );

  const handleOpenModal = async () => {
    // Create datapoint if it doesn't exist
    if (!dataPoint && !dpLoading) {
      await upsertEmptyDatapoint();
    }
    onOpen();
  };

  return (
    <Skeleton isLoaded={!dpLoading}>
      <Box w="100%">
        <VStack spacing="36px" w="100%">
          {hasDecidedDisclosure ? (
            <Button
              variant="ghost"
              alignSelf="end"
              size="sm"
              leftIcon={dataPoint?.isMdrmDisclosed ? <EditIcon /> : <MoreIcon />}
              onClick={onOpen}
            >
              {dataPoint?.isMdrmDisclosed ? 'Edit MDR-M' : 'MDR-M disclosed elsewhere'}
            </Button>
          ) : (
            <Tag
              title="Disclose MDR-M"
              variant="warning"
              clickable
              alignSelf="end"
              leftIcon={<AddIcon color="inherit" />}
              onClick={handleOpenModal}
              border={'1px solid border.warning'}
            />
          )}
          {dataPoint?.isMdrmDisclosed === true && (
            <Skeleton w="100%" isLoaded={!loading}>
              <MDRMTable
                answers={answers}
                metric={metric}
                onMdrmAnswerChange={onMdrmAnswerChange}
                setOpenMdrm={setOpenMdrm}
                setOpenComments={setOpenComments}
                setRowData={setRowData}
              />
            </Skeleton>
          )}
        </VStack>
        {openMdrm && (
          <MDRMSidebar
            metric={metric}
            datapointId={dataPoint?.id}
            mdrm={openMdrm}
            setOpenMdrm={setOpenMdrm}
            openComments={openComments}
            setOpenComments={setOpenComments}
          />
        )}

        <MDRMModal isOpen={isOpen} onClose={onClose} dataPoint={dataPoint} />
      </Box>
    </Skeleton>
  );
};
