import {
  AuditLogEntryFieldsFragment_,
  CompanyAssessment,
  CompanyAssessmentContextQuery_,
  QuestionType_Enum_,
  ShortUser,
  useAuditLogEntriesQuery,
  useCompanyAssessmentContextQuery,
} from 'models';
import { useCurrentCompanyId } from 'utils/hooks';
import { auditLogsTablesMap } from './auditLogsTablesMap';
import { taxonomyAuditLogsCases } from './taxonomyAuditLogsCases';
import { generalAuditLogsCases } from './generalAuditLogsCases';

export const COMPANY_LEVEL = 'Company level';

export type DataChanges =
  | {
      oldData?: string;
      newData?: string;
      oldFileExtension?: string;
      newFileExtension?: string;
      questionType?: QuestionType_Enum_;
    }[]
  | null;

export type AuditLog = {
  id: string;
  user?: ShortUser;
  operation: string;
  table: string;
  contextualInfo: string;
  metaInfo: { [key: string]: string };
  dataChanges: DataChanges;
  withReplacement?: boolean;
  isSkipped?: boolean;
};

export type ActivityReport = {
  activityRef: string;
  id: any;
  activity: {
    name: string;
  };
};

export type TableSpecificDataType = {
  contextualInfo: string;
  metaInfo: { [key: string]: string };
  dataChanges: DataChanges;
  operation: string;
  withReplacement?: boolean;
  isSkipped?: boolean;
};

const extractContext = (data?: CompanyAssessmentContextQuery_['company'] | null): string[] => {
  const context: string[] = [];

  const traverse = (obj: any) => {
    for (const key in obj) {
      if (key === 'id') {
        context.push(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        traverse(obj[key]);
      }
    }
  };

  traverse(data);

  return context;
};

const getTableSpecificData = (
  auditLog: AuditLogEntryFieldsFragment_,
  contextData: CompanyAssessmentContextQuery_
): TableSpecificDataType => {
  let tableSpecificData: TableSpecificDataType = {
    contextualInfo: '',
    metaInfo: {},
    dataChanges: [],
    operation: '',
    withReplacement: false,
    isSkipped: false,
  };

  const { table, schema } = auditLog;

  if (schema === 'public' && auditLogsTablesMap.taxonomy.includes(table)) {
    tableSpecificData = taxonomyAuditLogsCases({
      contextData,
      auditLog,
    });
  } else if (schema === 'public' && auditLogsTablesMap.general.includes(table)) {
    tableSpecificData = generalAuditLogsCases({
      contextData,
      auditLog,
    });
  }
  return tableSpecificData;
};

const mapAuditLogs = (
  data: AuditLogEntryFieldsFragment_[],
  context: CompanyAssessmentContextQuery_
): AuditLog[] => {
  return data?.map((auditLog) => {
    const { contextualInfo, metaInfo, dataChanges, operation, withReplacement, isSkipped } =
      getTableSpecificData(auditLog, context);
    return {
      id: auditLog?.invocationId,
      user: auditLog?.user ?? undefined,
      operation: operation,
      table: auditLog?.table,
      contextualInfo,
      metaInfo,
      dataChanges,
      withReplacement: withReplacement,
      isSkipped: isSkipped,
    };
  });
};

export const useTaxonomyAuditLogs = ({ cAssessment }: { cAssessment: CompanyAssessment }) => {
  const { companyId } = useCurrentCompanyId();

  const {
    data: contextData,
    loading: contextLoading,
    refetch: refetchContext,
  } = useCompanyAssessmentContextQuery({
    variables: {
      cAssessmentId: cAssessment?.id,
    },
    skip: !cAssessment,
  });

  const context = extractContext(contextData?.company);

  const {
    data,
    loading,
    refetch: refetchAuditLogs,
  } = useAuditLogEntriesQuery({
    variables: {
      companyId,
      limit: 100,
      offset: 0,
    },
    skip: !companyId,
  });

  const refetch = async () => {
    await refetchContext();
    await refetchAuditLogs();
  };

  const dataForCAssessment = data?.auditLogEntries.filter((auditLog) => {
    if (auditLog.table === 'ReportDownloads') return context.includes(auditLog.companyId);
    return (
      context.includes(auditLog?.newData?.id) ||
      (auditLog.table === 'PortfolioCompany' &&
        (context.includes(auditLog?.newData?.sharedCompanyAssessmentId) ||
          context.includes(auditLog?.oldData?.sharedCompanyAssessmentId)))
    );
  });

  const auditLogs =
    dataForCAssessment && contextData ? mapAuditLogs(dataForCAssessment, contextData) : [];

  return {
    auditLogs,
    loading: loading || contextLoading,
    refetch,
  };
};
