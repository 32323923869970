import React, { useMemo } from 'react';
import { Typography } from 'Tokens';
import { TruncatableText } from 'Atoms';
import { getMetricRefNumber } from '../../../Metrics.hooks';
import { Row } from '@tanstack/react-table';
import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData } from '../../../MetricAnswers.hooks';

export const Reference = ({
  optedOutMetrics,
  row,
  isNotSpecialRow,
}: {
  optedOutMetrics?: string[];
  row: Row<TableData<MetricsTableData>>;
  isNotSpecialRow: (row) => boolean;
}) => {
  const hasOptedOut = useMemo(
    () => optedOutMetrics?.includes(row.original.metric.reference),
    [optedOutMetrics, row]
  );
  return row.original.isAdditionalInfo ? (
    <Typography variant="body">_</Typography>
  ) : (
    isNotSpecialRow(row) && (
      <TruncatableText
        text={getMetricRefNumber(row.original.metric)}
        color={hasOptedOut ? 'text.hint' : 'text.default'}
      />
    )
  );
};
