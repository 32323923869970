import React, { ReactNode } from 'react';
import { useToast as useChakraToast, HStack, Divider, ToastId } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';

export const ToastItem = ({
  text,
  variant = 'default',
  onClose,
  closable = true,
  actions,
  icon,
  fitContent,
}: {
  text: string;
  closable: boolean;
  onClose: () => void;
  variant: 'default' | 'danger';
  actions?: ReactNode;
  icon?: JSX.Element;
  fitContent: boolean;
}) => {
  return (
    <HStack
      justifyContent="space-between"
      paddingX="16px"
      paddingY="12px"
      bg={variant === 'danger' ? 'bg.critical.accent' : 'bg.accent'}
      minH="44px"
      borderRadius="6px"
      width={fitContent ? 'fit-content' : ''}
      minW={fitContent ? 'fit-content' : '420px'}
    >
      <HStack>
        {icon}
        <Typography color="text.onAccent" variant="body">
          {text}
        </Typography>
      </HStack>
      <HStack spacing="4px">
        {actions}
        {actions && closable && (
          <Divider
            orientation="vertical"
            width="2px"
            borderRadius="3px"
            background="border.decorative"
            height="20px"
          />
        )}
        {closable && (
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="close"
            icon={<RemoveIcon boxSize="12px" color="inherit" />}
            onClick={onClose}
            color="text.onAccent"
          />
        )}
      </HStack>
    </HStack>
  );
};

export const useToast = () => {
  const toast = useChakraToast();
  return ({
    custom,
    duration = 3000,
    text,
    variant = 'default',
    closable = true,
    actions,
    prevId,
    destroyAll = false,
    icon,
    fitContent = false,
    id,
  }: {
    custom?: React.ReactNode;
    duration?: number | null;
    text: string;
    variant?: 'default' | 'danger';
    closable?: boolean;
    actions?: ReactNode;
    prevId?: ToastId;
    destroyAll?: boolean;
    icon?: JSX.Element;
    fitContent?: boolean;
    id?: ToastId;
  }) => {
    if (prevId) toast.close(prevId);
    if (destroyAll) {
      setTimeout(() => toast.closeAll(), 1000);
    }
    return toast({
      id,
      duration: duration,
      render: ({ onClose }) =>
        custom ? (
          custom
        ) : (
          <ToastItem
            text={text}
            onClose={onClose}
            closable={closable}
            actions={actions}
            variant={variant}
            icon={icon}
            fitContent={fitContent}
          />
        ),
    });
  };
};
