import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import { useEffect, useState } from 'react';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';

export const CashSovereignSelectorMenu = ({
  includeSovereigns,
  includeCash,
  setIncludeSovereigns,
  setIncludeCash,
  cash,
  sovereigns,
  reportPeriod,
}: {
  includeSovereigns: boolean;
  includeCash: boolean;
  setIncludeSovereigns: (value: boolean) => void;
  setIncludeCash: (value: boolean) => void;
  cash: number;
  sovereigns: number;
  reportPeriod: string;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [prevReportPeriod, setPrevReportPeriod] = useState(reportPeriod);
  const [cashEverAvailable, setCashEverAvailable] = useState(cash > 0);
  const [sovereignsEverAvailable, setSovereignsEverAvailable] = useState(sovereigns > 0);

  useEffect(() => {
    if (reportPeriod !== prevReportPeriod) {
      setCashEverAvailable(cash > 0);
      setSovereignsEverAvailable(sovereigns > 0);
      setPrevReportPeriod(reportPeriod);
    }
  }, [reportPeriod, cash, sovereigns, prevReportPeriod]);

  const getSelectedText = () => {
    if (cashEverAvailable && sovereignsEverAvailable) {
      if (includeCash && includeSovereigns) return 'Including cash and sovereigns';
      if (includeCash) return 'Including cash, without sovereigns';
      if (includeSovereigns) return 'Including sovereigns, without cash';
      return 'Without cash and sovereigns';
    }
    if (cashEverAvailable) return includeCash ? 'Including cash' : 'Without cash';
    if (sovereignsEverAvailable)
      return includeSovereigns ? 'Including sovereigns' : 'Without sovereigns';
    return 'No financials added';
  };

  return (
    <Box>
      <Popover
        gutter={2}
        isOpen={menuOpen}
        trigger="click"
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
      >
        <PopoverTrigger>
          <Button
            width="268px"
            h="36px"
            bg="bg.default"
            borderWidth="1px"
            borderColor="border.default"
            _hover={{ borderColor: 'border.hover' }}
            _focus={{
              borderColor: 'border.selected.accent',
              boxShadow: 'none',
            }}
            _active={{
              bg: 'bg.default',
            }}
            borderRadius="8px"
            p="8px"
            rightIcon={<ChevronDownIcon color="inherit" />}
            justifyContent="space-between"
            color="text.default"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Typography variant="body">{getSelectedText()}</Typography>
          </Button>
        </PopoverTrigger>

        <PopoverContent as={VStack} spacing="8px" p="8px" alignItems="stretch" w="256px">
          <Typography variant="detail">
            Results calculated {getSelectedText().toLowerCase()}
          </Typography>

          {cashEverAvailable && (
            <HStack
              p="8px"
              m="0px"
              w="100%"
              _hover={{
                bg: 'bg.hover',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => setIncludeCash(!includeCash)}
            >
              <Checkbox isChecked={includeCash} />
              <Typography variant="bodyStrong">Include cash</Typography>
            </HStack>
          )}

          {sovereignsEverAvailable && (
            <HStack
              p="8px"
              m="0px"
              w="100%"
              _hover={{
                bg: 'bg.hover',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => setIncludeSovereigns(!includeSovereigns)}
            >
              <Checkbox isChecked={includeSovereigns} />
              <Typography variant="bodyStrong">Include sovereigns</Typography>
            </HStack>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );
};
