import { IconButton, TooltipProps } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import { MouseEventHandler } from 'react';
import { HelpIcon } from 'Tokens/Icons/Status';

export function HelpTooltip({
  label,
  placement = 'top',
  iconColor,
  onButtonClick,
  ...props
}: Omit<TooltipProps, 'children'> & {
  iconColor?: string;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Tooltip
      label={label}
      hasArrow
      arrowSize={10}
      arrowShadowColor="grayscale.800"
      placement={placement}
      color="bg.default"
      // shouldWrapChildren
      {...props}
    >
      <IconButton
        aria-label="help"
        icon={<HelpIcon color={iconColor ?? 'inherit'} />}
        size="xs"
        color={iconColor ?? 'currentColor'}
        _hover={{ color: iconColor ?? 'currentColor' }}
        onClick={(e) => {
          onButtonClick?.(e);
        }}
        variant="ghost"
      />
    </Tooltip>
  );
}
