import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Button, EmptyState, IconButton, Infobox } from 'Atoms';
import {
  Box,
  Collapse,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { EsrsAssessmentModal } from './EsrsAssessmentModal';
import { EsrsGroupAssessmentTable } from './EsrsGroupAssessmentTable';
import { useGetEsrsAssessmentList } from './EsrsAssessment.hooks';
import { useKnowledgeBase } from 'Features';
import { PlayIcon } from 'Tokens/Icons/Function';
import { useUserSetting } from 'containers/Navigation';
import { InfoIcon } from 'Tokens/Icons/Status';
import { useEffect } from 'react';

export type EsrsAssessmentEditFields = {
  id: string;
  reportingYear: Date;
};

const EsrsAssessmentsEmptyState = ({
  onCallToActionClick,
}: {
  onCallToActionClick: () => void;
}) => {
  const { t } = useTranslation(['common', 'esrs']);
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('esrs:assessment.noReport')}
        description={t('esrs:assessment.noEsrsAssessmenttDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => {
            onCallToActionClick();
          },
        }}
        icon={<PaiIndicatorsIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

export const EsrsAssessmentList = () => {
  const { t } = useTranslation(['common', 'esrs', 'assessment']);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onOpen: onKnowledgeBaseOpen } = useKnowledgeBase();
  const [isEsrsLearnMoreClosed, onToggleEsrsModal] = useUserSetting(
    'esrs_learn_more_modal_is_closed',
    false
  );
  const [isEsrsTooltipClosed, onToggleEsrsTooltip] = useUserSetting(
    'esrs_learn_more_tooltip_is_closed',
    false
  );

  const { esrsAssessments, isGroup, loading } = useGetEsrsAssessmentList();

  useEffect(() => {
    if (isEsrsLearnMoreClosed && !isEsrsTooltipClosed) {
      setInterval(() => onToggleEsrsTooltip(true), 4000);
    }
  }, [isEsrsLearnMoreClosed, isEsrsTooltipClosed]);

  return (
    <ContentLayout
      header={
        <HStack justifyContent="space-between" width="100%" alignItems="center" mb="8px">
          <ContentHeader title={t('esrs:assessment.header')} props={{ paddingBottom: 0 }} />
          <Popover
            placement="left-start"
            isOpen={isEsrsLearnMoreClosed && !isEsrsTooltipClosed}
            onClose={() => onToggleEsrsTooltip(true)}
            closeOnBlur={true}
            isLazy={true}
          >
            <PopoverAnchor>
              <IconButton
                variant="ghost"
                icon={<InfoIcon color="inherit" />}
                aria-label="info"
                onClick={() => onToggleEsrsModal(!isEsrsLearnMoreClosed)}
                tooltipLabel={t('common:actions.help')}
              />
            </PopoverAnchor>
            <PopoverContent bg="text.info" border="none" w="220px">
              <PopoverArrow bg="text.info" border="none" />
              <Typography variant="body" padding="8px" color="text.onAccent">
                Find the “Getting started guidance” here if you need it again
              </Typography>
            </PopoverContent>
          </Popover>
        </HStack>
      }
      isLoading={loading}
    >
      <HStack
        as={Collapse}
        in={!isEsrsLearnMoreClosed}
        width="100%"
        justifyContent="stretch"
        marginBottom="14px"
      >
        <Infobox
          status="neutral"
          withIcon={false}
          marginBottom="16px"
          title={t('home:emptyState.taxonomy.subTitle')}
          description={
            <>
              Learn how to set up and perform your ESRS assessment in Celsia. <br />
              In onboarding 1, know how to structure your company/group, add subsidiaries, allocate
              owners, and configure materiality. The video also covers assessing disclosure
              requirements, metrics, and setting up data collection levels. <br />
              In onboarding 2, learn how to report both quantitative and qualitative data for your
              assessments, including how to input targets, actions, policies, and necessary
              disclosures based on your company materiality.
            </>
          }
          onClose={() => onToggleEsrsModal(true)}
          extra={
            <HStack alignItems="start" gap="4px">
              <Button
                ml="-8px"
                variant="ghost"
                onClick={() => onKnowledgeBaseOpen('esrs-onboarding-1')}
                size="sm"
                color="text.selected"
                leftIcon={<PlayIcon color="inherit" />}
              >
                Watch guide 1
              </Button>
              <Button
                ml="-8px"
                variant="ghost"
                onClick={() => onKnowledgeBaseOpen('esrs-onboarding-2')}
                size="sm"
                color="text.selected"
                leftIcon={<PlayIcon color="inherit" />}
              >
                Watch guide 2
              </Button>
            </HStack>
          }
        />
      </HStack>
      {esrsAssessments.length ? (
        <VStack alignItems="start" w="100%" spacing="4px">
          <HStack width="100%" justifyContent="space-between">
            <Typography variant="h2">Assessments</Typography>
            <Button onClick={onOpen}>{t('common:assessment.createAssessment')}</Button>
          </HStack>
          <EsrsGroupAssessmentTable esrsGroupAssessments={esrsAssessments} isGroup={isGroup} />
        </VStack>
      ) : (
        <EsrsAssessmentsEmptyState onCallToActionClick={onOpen} />
      )}
      <EsrsAssessmentModal isOpen={isOpen} onClose={onClose} />
    </ContentLayout>
  );
};
