import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout, SearchInput } from 'Molecules';
import {
  Box,
  HStack,
  useDisclosure,
  useMediaQuery,
  VStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  MenuButton,
} from '@chakra-ui/react';
import { useMemo, useState, useEffect } from 'react';
import { InvestmentCategory_Enum_, Portfolio, PortfolioFieldsFragment_ } from 'models';
import usePortfolios, {
  getReportPeriodValue,
  useUpdateDefaultReportingPeriod,
} from './Portfolios.hooks';
import { PortfoliosList, PortfolioSummary } from './pieces/PortfoliosList';
import { Route, Routes } from 'react-router-dom';
import {
  PortfolioView,
  ReportHeaderBox,
  ReportYearSelector,
  useCurrentReportPeriod,
} from 'Features/PortfolioView';
import { PortfolioEditorModal } from './pieces/PortfolioEditor';
import { NothingFoundIllustration, PortfolioIllustration, Typography } from 'Tokens';
import { formatDigits } from 'utils/numbers';
import { useCurrentCompany } from 'utils/hooks';
import { Button, EmptyState, VisibleIf } from 'Atoms';
import { ErrorElement } from 'App';
import { RefreshIcon, UploadIcon } from 'Tokens/Icons/Function';
import PortfolioSettings from './PortfolioSettings';
import { PortfolioBulkUploader } from './pieces/PortfolioBulkUploader';
import { useFeatureFlags, useUserSetting } from 'containers/Navigation/Navigation.hooks';
import { useIsAuditor } from 'containers/CompanyPai/CompanyPai.hooks';
import { formatQuarterYear } from 'utils/date';
import { Menu } from 'Molecules/Menu';
import { PaiBulkUploader } from './pieces/PaiBulkUploader';

const SUMMARY_HEIGHT_MAP = {
  max: '246px',
  large: '230px',
  medium: '198px',
  small: '182px',
  default: '172px',
};

export const PortfoliosPage = () => {
  const { t } = useTranslation(['portfolio', 'common']);
  const { portfolios, loading, refetchPortfolios } = usePortfolios();
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [portfolioToEdit, setPortfolioToEdit] = useState<Portfolio | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const { company: currentCompany } = useCurrentCompany();
  const { reportPeriod } = useCurrentReportPeriod();
  const [isFundsPeriodSelectorPopupDismissed, setIsFundsPeriodSelectorPopupDismissed] =
    useUserSetting('funds-period-selector-popup-dismissed', false);
  const { defaultReportingPeriod, updateDefaultReportingPeriod } = useUpdateDefaultReportingPeriod(
    currentCompany?.id
  );
  const { isAuditor } = useIsAuditor(currentCompany?.id);
  const { hasBulkUploadAccess } = useFeatureFlags({ company: currentCompany });

  const allYears = useMemo(() => {
    const tempAllYears: Array<string> = [
      ...new Set(
        portfolios
          .flatMap((ps) => ps.portfolioCompanies)
          .map((c) => formatQuarterYear(c.quarter, c.year))
      ),
    ]
      .filter((year): year is string => year !== undefined && year !== null)
      .sort((a, b) => a.localeCompare(b));

    return tempAllYears;
  }, [portfolios]);

  const latestPortfolioYear = useMemo(() => allYears[allYears.length - 1], [allYears]);
  const onlyOneReportingYear = useMemo(() => allYears.length === 1, [allYears]);

  const [selectedPortfolioYear, setSelectedPortfolioYear] = useState<string>(
    new Date().getFullYear().toString()
  );

  const closeFundsPeriodSelectorPopup = () => {
    setIsFundsPeriodSelectorPopupDismissed(true);
  };

  useEffect(() => {
    if (onlyOneReportingYear) {
      setSelectedPortfolioYear(latestPortfolioYear);
    } else if (defaultReportingPeriod || latestPortfolioYear) {
      setSelectedPortfolioYear(defaultReportingPeriod ?? latestPortfolioYear);
    }
  }, [latestPortfolioYear, defaultReportingPeriod, onlyOneReportingYear]);

  const filteredPortfolios = !searchValue
    ? portfolios
    : portfolios.filter((portfolio) =>
        portfolio.name.toLowerCase().includes(searchValue.toLowerCase())
      );

  const portfolioCompanies = useMemo(() => {
    return portfolios.flatMap((object) => object.portfolioCompanies);
  }, [portfolios]);

  const portfolioCompaniesForSelectedYear: PortfolioFieldsFragment_['portfolioCompanies'] =
    useMemo(() => {
      return portfolioCompanies.filter(
        (c) => formatQuarterYear(c.quarter, c.year) === selectedPortfolioYear
      );
    }, [selectedPortfolioYear, portfolioCompanies]);

  const { cash: totalCash, sovereigns: totalSovereigns } = portfolios.reduce(
    (totals, portfolio) => {
      portfolio.portfolioNonEligibleFinancials
        .filter((c) => formatQuarterYear(c.quarter, c.year) === selectedPortfolioYear)
        .forEach((c) => {
          if (c.type === InvestmentCategory_Enum_.Cash_) {
            totals.cash += getReportPeriodValue(reportPeriod, true, c.value);
          }
          if (c.type === InvestmentCategory_Enum_.Sovereign_) {
            totals.sovereigns += getReportPeriodValue(reportPeriod, true, c.value);
          }
        });
      return totals;
    },
    { cash: 0, sovereigns: 0 }
  );

  const totalInvestedInCompanies = useMemo(() => {
    return portfolioCompaniesForSelectedYear
      ?.map((pc) => pc.valueOfInvestments)
      .reduce((acc, curr) => {
        const quartersAverage = (curr.q1 + curr.q2 + curr.q3 + curr.q4) / 4;
        return acc + quartersAverage;
      }, 0);
  }, [portfolioCompaniesForSelectedYear]);

  const [isMax, isLarge, isMedium, isSmall] = useMediaQuery([
    '(width <= 1462px)',
    '(width <= 1452px)',
    '(width <= 1215px)',
    '(width <= 1132px)',
  ]);

  const summaryHeight = useMemo(() => {
    if (isSmall) {
      return SUMMARY_HEIGHT_MAP.max;
    }
    if (isMedium) return SUMMARY_HEIGHT_MAP.large;
    if (isLarge) {
      return SUMMARY_HEIGHT_MAP.medium;
    }
    if (isMax) {
      return SUMMARY_HEIGHT_MAP.small;
    }
    return SUMMARY_HEIGHT_MAP.default;
  }, [isMax, isLarge, isMedium, isSmall]);

  const {
    isOpen: isOpenTaxonomyBulk,
    onOpen: onOpenTaxonomyBulk,
    onClose: onCloseTaxonomyBulk,
  } = useDisclosure();
  const { onOpen: onOpenPaiBulk, isOpen: isOpenPaiBulk, onClose: onClosePaiBulk } = useDisclosure();

  const closeTaxonomuBulk = async () => {
    await refetchPortfolios();
    onCloseTaxonomyBulk();
  };

  return (
    <ContentLayout
      isLoading={loading}
      header={
        <ContentHeader
          title={t('portfolio:portfolio_plural')}
          actions={[
            allYears.length > 1 ? (
              <Popover
                placement="bottom-end"
                isOpen={!isFundsPeriodSelectorPopupDismissed}
                onClose={closeFundsPeriodSelectorPopup}
                closeOnBlur={false}
                isLazy={true}
              >
                <Box>
                  <PopoverAnchor>
                    <HStack spacing="8px" justifyContent="flex-end" zIndex={2} w="fit-content">
                      <Typography variant="bodyStrong">
                        {t('portfolio:reportingPeriod')}:
                      </Typography>
                      <ReportYearSelector
                        reportingYears={allYears}
                        withAddPeriod={false}
                        selectedYear={selectedPortfolioYear}
                        setSelectedYear={(newYear) => {
                          updateDefaultReportingPeriod(newYear);
                          setSelectedPortfolioYear(newYear);
                        }}
                      />
                    </HStack>
                  </PopoverAnchor>
                </Box>
                <Box zIndex={1}>
                  <PopoverContent
                    bg="bg.accent"
                    border="none"
                    w="275px"
                    sx={{
                      '.chakra-popover__arrow-positioner': {
                        transform: 'translate3d(226px, 0px, 0px)!important',
                      },
                    }}
                  >
                    <PopoverArrow bg="bg.accent" border="none" />
                    <PopoverCloseButton color="white" zIndex={2} />
                    <VStack w="100%" alignContent="start" padding="16px" spacing="4px" zIndex={1}>
                      <Typography variant="h2" color="white" w="100%">
                        {t('portfolio:yearSelectorPopup.title')}
                      </Typography>
                      <Typography variant="body" color="white">
                        {t('portfolio:yearSelectorPopup.body')}
                      </Typography>
                    </VStack>
                  </PopoverContent>
                </Box>
              </Popover>
            ) : undefined,
          ]}
        />
      }
    >
      {filteredPortfolios.length || searchValue ? (
        <>
          <HStack justifyContent="space-evenly" spacing="12px" w="100%">
            <VStack
              width="100%"
              alignItems="stretch"
              justifyContent="flex-start"
              bg="bg.muted"
              borderRadius="8px"
              flex="1"
              height={summaryHeight}
              minHeight="174px"
            >
              <ReportHeaderBox
                header={t('portfolio:overview')}
                layout="stacked"
                content={[
                  {
                    label: t('portfolio:portfolio_plural'),
                    element: <Typography variant="bodyLarge">{portfolios.length}</Typography>,
                  },
                  {
                    label: t('portfolio:companies'),
                    element: (
                      <Typography variant="bodyLarge">
                        {portfolioCompaniesForSelectedYear.length}
                      </Typography>
                    ),
                  },
                  {
                    label:
                      totalCash > 0 || totalSovereigns > 0
                        ? `${t('portfolio:invested')}, total*`
                        : t('portfolio:invested'),
                    element: (
                      <Typography variant="bodyLarge">
                        {formatDigits(totalInvestedInCompanies + totalSovereigns + totalCash) +
                          ' ' +
                          currentCompany?.currency}
                      </Typography>
                    ),
                    withActionButton: true,
                    extraData: {
                      cash: totalCash,
                      sovereigns: totalSovereigns,
                      currency: currentCompany?.currency ?? '',
                      companiesOnlyInvestment: totalInvestedInCompanies,
                    },
                  },
                ]}
              />
            </VStack>
            <Box flex="3" height={'100%'}>
              <PortfolioSummary
                portfolioCompanies={portfolioCompaniesForSelectedYear}
                reportPeriod={reportPeriod}
                cashAndSovereigns={{ cash: totalCash, sovereigns: totalSovereigns }}
              />
            </Box>
          </HStack>

          <HStack mt="40px" justifyContent="space-between">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={searchValue}
              setSearch={setSearchValue}
              minW="200px"
            />
            <HStack spacing="8px">
              <VisibleIf condition={hasBulkUploadAccess}>
                <Menu
                  size="md"
                  placement="bottom-start"
                  menuButton={
                    <MenuButton
                      as={Button}
                      variant="secondary"
                      leftIcon={<UploadIcon color="inherit" />}
                    >
                      Upload data...
                    </MenuButton>
                  }
                  sections={[
                    {
                      title: 'Upload data to portfolio companies ',
                      actions: [
                        {
                          title: 'EU Taxonomy data',
                          id: 'upload-taxonomu',
                          onClick: onOpenTaxonomyBulk,
                        },
                        { title: 'PAI data', id: 'upload-pai', onClick: onOpenPaiBulk },
                      ],
                    },
                  ]}
                />
              </VisibleIf>
              <Button
                variant="primary"
                onClick={() => setIsCreatingPortfolio(true)}
                isDisabled={isAuditor}
              >
                {t('portfolio:createButton')}
              </Button>
            </HStack>
          </HStack>

          {filteredPortfolios.length ? (
            <PortfoliosList
              portfolios={filteredPortfolios}
              onEdit={(p) => setPortfolioToEdit(p)}
              year={selectedPortfolioYear}
              isAuditor={isAuditor}
            />
          ) : (
            <Box w="100%" flexGrow="1" alignItems="stretch" mt="16px">
              <EmptyState
                title={t('common:search.filter.emptyTitle')}
                description={t('common:search.filter.emptyDescription')}
                callToAction={{
                  text: t('common:search.filter.emptyBtn'),
                  variant: 'secondary',
                  onClick: () => {
                    setSearchValue('');
                  },
                  leftIcon: <RefreshIcon color="inherit" />,
                }}
                icon={<NothingFoundIllustration boxSize="120px" />}
              />
            </Box>
          )}
        </>
      ) : (
        <Box w="100%" flexGrow="1">
          <EmptyState
            title={t('portfolio:noPortfolio')}
            description={t('portfolio:noPortfolioDescription')}
            callToAction={{
              text: t('portfolio:createButton'),
              variant: 'primary',
              onClick: () => setIsCreatingPortfolio(true),
              isDisabled: isAuditor,
            }}
            icon={<PortfolioIllustration boxSize="120px" />}
          />
        </Box>
      )}
      <PortfolioEditorModal
        isOpen={isCreatingPortfolio || !!portfolioToEdit}
        onClose={() => {
          setIsCreatingPortfolio(false);
          setPortfolioToEdit(undefined);
        }}
        portfolio={portfolioToEdit}
      />
      <PortfolioBulkUploader
        onOpen={onOpenTaxonomyBulk}
        onClose={closeTaxonomuBulk}
        isOpen={isOpenTaxonomyBulk}
      />
      <PaiBulkUploader onOpen={onOpenPaiBulk} onClose={onClosePaiBulk} isOpen={isOpenPaiBulk} />
    </ContentLayout>
  );
};

export const Portfolios = () => {
  return (
    <Routes>
      <Route index element={<PortfoliosPage />} errorElement={<ErrorElement />} />
      <Route
        path=":portfolioId/:reportingYear/create"
        element={<PortfolioSettings isEditing={false} />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":portfolioId/:reportingYear/edit"
        element={<PortfolioSettings isEditing={true} />}
        errorElement={<ErrorElement />}
      />
      <Route path=":portfolioId/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
        <Route path=":reportingYear/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
          <Route path=":currentTab" element={<PortfolioView />} errorElement={<ErrorElement />} />
        </Route>
      </Route>
    </Routes>
  );
};
