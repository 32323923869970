import { ApolloError } from '@apollo/client';
import { useUserData, useUserEmail } from '@nhost/react';
import { captureException } from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import {
  useUserCompaniesQuery,
  useCompanyDetailsQuery,
  CompanyDetailsQuery_,
  useCompanyAssessmentQuery,
} from 'models';
import { useCallback, useMemo, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { isCelsiaSupport, isIssSales } from 'utils/users';

export enum CompanyType {
  portfolio = 'portfolio-company',
  regular = 'regular-company',
  group = 'group-company',
}

export const getCompanyType = (
  company: CompanyDetailsQuery_['company'][0] | undefined
): CompanyType =>
  company?.isPortfolioOwner
    ? CompanyType.portfolio
    : company?.isGroupOwner
      ? CompanyType.group
      : CompanyType.regular;

export const useUserCompanyCheck = () => {
  const user = useUserData();
  const { data } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });
  const hasCompany = useCallback(
    (companyId?: string) =>
      companyId
        ? data?.data?.companies.map(({ company }) => company.id).includes(companyId)
        : false,
    [data]
  );
  return hasCompany;
};

export const useSetCurrentCompanyId = () => {
  const [, setLocalStorageCompanyId] = useLocalStorage<string | null>('currentCompanyId', null);

  const setCompanyId = useCallback(
    (newCompanyId: string) => {
      setLocalStorageCompanyId(newCompanyId);
      try {
        hotjar.identify(null, { companyId: newCompanyId });
      } catch (e) {
        captureException(e, {
          extra: { errorMessage: 'Could not capture company id in hotjar' },
        });
      }
    },
    [setLocalStorageCompanyId]
  );

  return setCompanyId;
};

export const useCurrentCompanyId = (): {
  companyId: string | null;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const user = useUserData();
  const { companyId: urlCompanyId } = useParams();
  const checkAccess = useUserCompanyCheck();

  const [localStorageCompanyId] = useLocalStorage<string | null>('currentCompanyId', null);
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const { data, loading, error } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  const currentCompanyId = useMemo(() => {
    if (urlCompanyId && checkAccess(urlCompanyId)) return urlCompanyId;
    if (localStorageCompanyId && checkAccess(localStorageCompanyId)) return localStorageCompanyId;
    const firstOnList = data?.data?.companies[0]?.company.id;
    return firstOnList;
  }, [data, urlCompanyId, localStorageCompanyId]);

  useEffect(() => {
    if (currentCompanyId && !localStorageCompanyId) {
      setCurrentCompanyId(currentCompanyId);
    }
  }, [currentCompanyId]);

  return { companyId: currentCompanyId ?? urlCompanyId, loading, error };
};

export const useCurrentCompany = () => {
  const { companyId: currentCompanyId } = useCurrentCompanyId();
  const { data, loading } = useCompanyDetailsQuery({
    variables: {
      id: currentCompanyId,
    },
    skip: !currentCompanyId,
  });

  return { company: data?.company[0], loading };
};

export const useCompanyType = (): {
  companyType: CompanyType;
  loading: boolean;
  isInGroups: boolean;
  isGroup: boolean;
} => {
  const { company, loading } = useCurrentCompany();
  return {
    companyType: getCompanyType(company),
    loading,
    isGroup: !!company?.isGroupOwner,
    isInGroups: !!company?.subsidiaries?.length,
  };
};

export const useRegisterMixpanelCompanyDetails = () => {
  const { company } = useCurrentCompany();
  const companyType = getCompanyType(company);
  const email = useUserEmail();

  return useCallback(() => {
    try {
      mixpanel.register({
        companyId: company?.id,
        companyName: company?.name,
        companyType: companyType,
        companyIsReal: company?.isReal,
        isCelsiaUser: isCelsiaSupport(email),
        isIssUser: isIssSales(email),
      });
    } catch (e) {
      captureException(e, {
        extra: { errorMessage: 'Could not capture company id in mixpanel' },
      });
    }
  }, [company, companyType, email]);
};

export const useGetCurrentCAssessment = () => {
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();

  const { data, loading } = useCompanyAssessmentQuery({
    variables: { cAssessmentId },
    skip: !cAssessmentId,
  });

  return { ...data?.companyAssessment, loading };
};
