import React, { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { AdditionalInfoButton } from '../AdditionalInfoModals/AdditionalInfoModals';
import { GoToSource } from '../GoToSource/GoToSource';
import { MetricRowTitle } from '../MetricRowTitle/MetricRowTitle';
import { MetricsWarningInputPage } from '../../../AggregatedMetrics';
import { TableData } from 'Molecules/NestedTable';
import { Row } from '@tanstack/react-table';
import { MetricsTableData } from '../../../MetricAnswers.hooks';

const calculateRowPadding = (row: Row<TableData<MetricsTableData>>, isNarrative?: boolean) => {
  const canExpand = row.getCanExpand();
  const hasDepth = row.depth > 0;
  if (isNarrative) {
    return (row.depth + 1) * 24;
  }

  if (canExpand && !row.depth) return 44;
  if (canExpand && hasDepth) return row.depth * 24 + 44;

  return row.depth * 24 + (canExpand || !row.depth ? 0 : 44);
};

export const DataPoint = ({
  row,
  notAggregatableMetrics,
  parentNotAggregatableMetrics,
  metricsMissingTagsConfiguration,
  isNarrative,
  setNumberOfRows,
  allMetrics,
  handleRowClick,
  companyReportingUnit,
  optedOutMetrics,
}: {
  row: Row<TableData<MetricsTableData>>;
  notAggregatableMetrics?: string[];
  parentNotAggregatableMetrics?: Set<string>;
  metricsMissingTagsConfiguration?: string[];
  isNarrative?: boolean;
  setNumberOfRows?: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  allMetrics?: MetricsTableData[];
  handleRowClick: (row: Row<TableData<MetricsTableData>>, isComments: boolean) => void;
  companyReportingUnit?: string;
  optedOutMetrics?: string[];
}) => {
  const isMissingTagOrNotAggregatable = useMemo(
    () =>
      notAggregatableMetrics?.includes(row.original?.metric?.reference) ||
      parentNotAggregatableMetrics?.has(row.original?.metric?.reference) ||
      metricsMissingTagsConfiguration?.includes(row.original?.metric?.reference),
    [notAggregatableMetrics, parentNotAggregatableMetrics, metricsMissingTagsConfiguration]
  );

  const rowPadding = useMemo(() => calculateRowPadding(row, isNarrative), [row, isNarrative]);

  const hasOptedOut = useMemo(
    () => optedOutMetrics?.includes(row.original.metric.reference),
    [optedOutMetrics, row]
  );

  const filteredNotAggregatableMetrics = useMemo(() => {
    const notAggregatableMetricSet = new Set(notAggregatableMetrics);

    parentNotAggregatableMetrics?.forEach((metric: string) => {
      notAggregatableMetricSet.delete(metric);
    });

    return Array.from(notAggregatableMetricSet);
  }, [notAggregatableMetrics, parentNotAggregatableMetrics]);

  if (row.original.isAdditionalInfoButton) {
    return (
      <Box pt="6px" pl={`${row.depth * 24}px`}>
        <AdditionalInfoButton
          metric={row.original.metric}
          companyReportingUnit={companyReportingUnit ?? ''}
        />
      </Box>
    );
  }
  return row.original.referenceToSource ? (
    <GoToSource
      row={row}
      isNarrative={isNarrative}
      filteredMetrics={allMetrics ?? []}
      setNumberOfRows={(val) => (setNumberOfRows ? setNumberOfRows(val) : {})}
    />
  ) : (
    <>
      <HStack
        gap="8px 0px"
        pt={'8px'}
        height="fit-content"
        id={
          !row.subRows.some((subrow) => subrow.original.referenceToSource)
            ? row.original.metric.reference
            : undefined
        }
      >
        <MetricRowTitle
          row={row}
          onClick={() => handleRowClick(row.original, false)}
          isNarrative={isNarrative}
          isMissingTagOrNotAggregatable={isMissingTagOrNotAggregatable}
          hasOptedOut={hasOptedOut}
        />
      </HStack>
      {!row.original.isAdditionalInfoButton && !row.original.referenceToSource && (
        <Box>
          {filteredNotAggregatableMetrics?.includes(row.original?.metric?.reference) && (
            <MetricsWarningInputPage
              warningIconColor="text.muted"
              padding={`${rowPadding}px`}
              toolTipLabel="We advise to review the configuration of this data point"
              contentColor="text.muted"
              content="Aggregated from subsidiaries"
            />
          )}
          {parentNotAggregatableMetrics?.has(row.original?.metric?.reference) && (
            <MetricsWarningInputPage
              warningIconColor="text.muted"
              padding={`${rowPadding - (!isNarrative ? 8 : 0)}px`}
              toolTipLabel="We advise to review the configuration of this data point"
              contentColor="text.muted"
              content="Sub-metric aggregated from subsidiaries"
            />
          )}
          {metricsMissingTagsConfiguration?.includes(row.original?.metric?.reference) && (
            <MetricsWarningInputPage
              warningIconColor="text.warning"
              padding={`${rowPadding}px`}
              toolTipLabel="Complete the required configuration (see details at the top of the page)"
              contentColor="text.warning"
              content="Data point is misconfigured"
            />
          )}
        </Box>
      )}
    </>
  );
};
