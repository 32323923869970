import { HStack, VStack, Box, FormLabel, ListItem, UnorderedList } from '@chakra-ui/react';
import { IconButton, Infobox, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Modal } from 'Molecules';
import { BusinessUnitsIcon } from 'Tokens/Icons/Data';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Insert_Input_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  GetMetricsDrDocument_,
  GetRequiredTagsMaterialMetricsDocument_,
  MaterialMetricsPerDisclosureDocument_,
  GroupMetricRequiredTagsPerStandardDocument_,
  useDeleteMaterialMetricTagMutation,
  useDeleteMaterialMetricTagValueMutation,
  useUpsertMaterialMetricsMutation,
} from 'models';
import { useToast } from 'utils/hooks';
import { TagSelectionMenu, TagValuesSelector } from './TagSelectMenus';
import { groupBy, uniq, uniqBy } from 'lodash';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { DataCollectionLevel, TableMetricData } from '../DataCollection.d';

export enum TagStatus {
  required = 'Required by ESRS',
  added = 'Added',
  requested = 'Requested by parent company',
}

export type MetricConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedMetricData?: TableMetricData;
  companyStandardId: string;
  parentStandardId: string;
};

export type MaterialMetricFields = {
  frequency: string;
  dataCollection?: string;
};

export type TagBreakdown = {
  type: string;
  values: string[];
  isFromESRS: boolean;
  isFromParent: boolean;
};

const isFullMetricOnGroupLevel = (metric: TableMetricData, companyStandardId: string): boolean => {
  const materialMetric = metric.materialMetrics?.find(
    (mm) => mm.materialStandardId === companyStandardId
  );
  if (!!metric.childrenMetrics.length) {
    return metric.childrenMetrics?.every(
      (child) =>
        child.childMetric &&
        isFullMetricOnGroupLevel(child.childMetric as TableMetricData, companyStandardId)
    );
  }
  return materialMetric?.dataCollection === DataCollectionLevel.group;
};

export const NoSelectedTags = ({
  checkedTags,
  setCheckedTags,
  tagOptions,
}: {
  checkedTags: TagBreakdown[];
  setCheckedTags: Dispatch<SetStateAction<TagBreakdown[]>>;
  tagOptions: TagBreakdown[];
}) => {
  return (
    <VStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      border="1px dashed"
      borderRadius="8px"
      borderColor="border.default"
      pt="24px"
      pb="16px"
    >
      <BusinessUnitsIcon color="text.hint" />
      <Typography variant="body" color="text.muted" w="275px" align="center">
        You can break down this metric into more granular sub-metrics.
        <br />
        Select how you want to break it down:
      </Typography>
      <TagSelectionMenu
        options={tagOptions ?? []}
        checked={checkedTags}
        setChecked={setCheckedTags}
      />
    </VStack>
  );
};

export const MetricConfigModalParent = ({
  isOpen,
  onClose,
  selectedMetricData,
  companyStandardId,
}: MetricConfigModalProps) => {
  const [showAll, setShowAll] = useState(false);
  const materialMetric = useMemo(
    () =>
      selectedMetricData?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
    [selectedMetricData, companyStandardId]
  );

  const selectedMaterialMetricId = useMemo(() => materialMetric?.id, [materialMetric]);

  const showParentWarning = useMemo(
    () => selectedMetricData?.isChild && (selectedMetricData?.parentMetrics?.length ?? 0) > 1,
    [selectedMetricData]
  );

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromCompany = useMemo(() => {
    return materialMetric?.materialMetricTags;
  }, [materialMetric]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const esrsMandatory =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const esrsOptional =
      adminPanelTags
        ?.filter((x) => !!x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: false,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const fromCompany =
      tagsFromCompany?.map((x) => ({
        type: x.tagType,
        isFromESRS: !!esrsMandatory.find((y) => y.type === x.tagType),
        isFromParent: false,
        values:
          adminPanelTags?.find((t) => t.type === x.tagType)?.values.tagValues.map((v) => v.value) ??
          [],
      })) ?? [];

    const existing: TagBreakdown[] = uniqBy([...esrsMandatory, ...fromCompany], 'type');
    const options: TagBreakdown[] = esrsOptional.filter(
      (x) => !existing.find((y) => y.type === x.type)
    );
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    return TagStatus.added;
  };

  const toast = useToast();

  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);
  const [configuredTags, setConfiguredTags] = useState<{ tagType: string; tagValue: string }[]>([]);

  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();

  const isGroupLevel = useMemo(
    () =>
      (selectedMetricData && isFullMetricOnGroupLevel(selectedMetricData, companyStandardId)) ??
      false,
    [selectedMetricData, companyStandardId]
  );

  const handleConfirm = async () => {
    const groupedConfiguredTags = groupBy(configuredTags, 'tagType');

    const tagsAvailable: Esrs_MaterialMetricTag_Insert_Input_[] = availableTags.map((tag) => {
      return {
        tagType: tag.type,
      };
    });

    upsertMaterialMetric({
      variables: {
        objects: [
          {
            id: selectedMaterialMetricId,
            frequency: materialMetric?.frequency,
            dataCollection: materialMetric?.dataCollection ?? DataCollectionLevel.group,
            materialStandardId: companyStandardId,
            metricRef: selectedMetricData?.reference,
            isMaterial: materialMetric?.isMaterial,
            materialMetricTags: {
              data: isGroupLevel
                ? Object.keys(groupedConfiguredTags).map((key) => ({
                    tagType: key,
                    materialTagValues: {
                      data: groupedConfiguredTags[key],
                      on_conflict: {
                        constraint:
                          Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                        update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                      },
                    },
                  }))
                : tagsAvailable,
              on_conflict: {
                constraint:
                  Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                update_columns: [Esrs_MaterialMetricTag_Update_Column_.IsOptional_],
              },
            },
          },
        ],
      },
      refetchQueries: [
        GetMetricsDrDocument_,
        MaterialMetricsPerDisclosureDocument_,
        EsrsAssessmentDocument_,
        GroupMetricRequiredTagsPerStandardDocument_,
        GetRequiredTagsMaterialMetricsDocument_,
      ],
    })
      .then(() => {
        toast({
          text: 'Configured metric data collection settings',
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          text: 'Unable to configure metric data collection settings',
          variant: 'danger',
        });
      });

    const existingMaterialTags = materialMetric?.materialMetricTags.flatMap((materialTag) =>
      materialTag.materialTagValues.map((t) => ({
        tagType: t.tagType,
        tagValue: t.tagValue,
      }))
    );

    // Move deleting tags to data gathering selector
    const tagValuesToDelete = existingMaterialTags?.filter((existingTag) => {
      return !configuredTags.some((configuredTag) => {
        return (
          configuredTag.tagType === existingTag.tagType &&
          configuredTag.tagValue === existingTag.tagValue
        );
      });
    });

    const existingMaterialTagTypes = materialMetric?.materialMetricTags.map((t) => t.tagType);
    const configuredMaterialTagTypes = uniq(availableTags.map((t) => t.type));
    const tagTypesToDelete = existingMaterialTagTypes?.filter(
      (tag) => !configuredMaterialTagTypes?.includes(tag)
    );

    const presentTags = materialMetric?.materialMetricTags.map((tag) => tag.tagType);

    const tagsToDelete = presentTags?.filter(
      (tag) => !availableTags?.map((x) => x.type).includes(tag)
    );

    try {
      if (tagTypesToDelete?.length)
        deleteMaterialMetricTag({
          variables: {
            materialMetricId: selectedMaterialMetricId,
            tagTypes: tagTypesToDelete ?? [],
          },
          refetchQueries: [
            GetMetricsDrDocument_,
            MaterialMetricsPerDisclosureDocument_,
            EsrsAssessmentDocument_,
            GetRequiredTagsMaterialMetricsDocument_,
            GroupMetricRequiredTagsPerStandardDocument_,
          ],
        });

      if (tagValuesToDelete?.length)
        tagValuesToDelete?.forEach((tag) => {
          deleteMaterialMetricTagValue({
            variables: {
              materialMetricId: selectedMaterialMetricId,
              tagType: tag.tagType,
              tagValue: tag.tagValue,
            },
            refetchQueries: [
              GetMetricsDrDocument_,
              MaterialMetricsPerDisclosureDocument_,
              EsrsAssessmentDocument_,
              GetRequiredTagsMaterialMetricsDocument_,
              GroupMetricRequiredTagsPerStandardDocument_,
            ],
          });
        });

      if (tagsToDelete?.length && !isGroupLevel)
        return await deleteMaterialMetricTag({
          variables: {
            materialMetricId: selectedMaterialMetricId,
            tagTypes: tagsToDelete ?? [],
          },
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (materialMetric?.materialMetricTags.length === 0) {
      setConfiguredTags(
        selectedMetricData?.adminPanelTags.flatMap((tag) => {
          if (tag.values.alwaysAll) {
            return tag.values.tagValues.map((t) => ({
              tagType: t.type,
              tagValue: t.value,
            }));
          }
          return [];
        }) ?? []
      );
    } else {
      setConfiguredTags(
        materialMetric?.materialMetricTags.flatMap((materialTag) =>
          materialTag.materialTagValues.map((t) => ({
            tagType: t.tagType,
            tagValue: t.tagValue,
          }))
        ) ?? []
      );
    }
  }, [materialMetric]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle } = useMemo(() => {
    return {
      title: selectedMetricData?.title,
      shortTitle: selectedMetricData?.shortTitle,
    };
  }, [selectedMetricData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <VStack spacing="2px" alignItems="start">
          <Typography variant="h2" mr="40px" justifyContent="center">
            {shortTitle ?? title ?? ''}
            {!!adminPanelTags?.length && (
              <>
                {' by '}
                <MetricTagsList tags={adminPanelTags.map((tag) => tag.type)} isHStack={false} />
              </>
            )}
          </Typography>
          <Typography variant="detail">
            {selectedMetricData?.isChild ? 'Datapoint settings' : 'Metric settings'}
          </Typography>
        </VStack>
      }
      subtitle="Metric settings"
      size={hasBreakdown ? 'lg' : 'md'}
      onConfirm={() => {
        handleConfirm();
        onClose();
      }}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        {showParentWarning && (
          <Box w="100%">
            <Infobox
              status="warning"
              title="Important"
              closable={false}
              description={
                <VStack spacing="0px" alignItems="start">
                  <Typography variant="body">
                    Note that this metric is used to calculate several other metrics, so changing
                    the settings here will propagate throughout the system.
                    {!showAll && '... '}
                    {!showAll && (
                      <Typography
                        variant="bodyStrong"
                        color="text.muted"
                        cursor="pointer"
                        as="span"
                        onClick={() => setShowAll(true)}
                      >
                        Show more
                      </Typography>
                    )}
                    {showAll && ' Below is the full list of metrics that rely on this metric.'}
                  </Typography>
                  {showAll && (
                    <>
                      <br />
                      <UnorderedList>
                        {selectedMetricData?.parentMetrics.map((parent) => (
                          <ListItem>{parent.parentMetric.title}</ListItem>
                        ))}
                      </UnorderedList>
                      {showAll && (
                        <Typography
                          variant="bodyStrong"
                          color="text.muted"
                          cursor="pointer"
                          as="span"
                          mt="8px"
                          onClick={() => setShowAll(false)}
                        >
                          Show less
                        </Typography>
                      )}
                    </>
                  )}
                </VStack>
              }
            />
          </Box>
        )}

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag w="47px" variant="info" borderRadius="6px" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box>
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>
                        {isGroupLevel && (
                          <TagValuesSelector
                            options={tag.values.map((v) => ({ tagType: tag.type, tagValue: v }))}
                            configured={configuredTags}
                            type={tag.type}
                            setConfigured={setConfiguredTags}
                          />
                        )}
                        {!tag.isFromESRS ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                              setConfiguredTags((prev) =>
                                prev.filter((t) => t.tagType !== tag.type)
                              );
                            }}
                          />
                        ) : (
                          <Tooltip
                            label="'This breakdown is required by the ESRS. You can not delete this breakdown'"
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}

            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
