import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import Papa from 'papaparse';
import { Modal } from 'Molecules';
import { Button } from 'Atoms';
import { ColumnMatcher } from './ColumnMatcher';
import { Uploader } from './Uploader';
import { useToast } from 'utils/hooks';
import { CheckIcon } from 'Tokens/Icons/Status';
import { ArrowNarrowLeftIcon } from 'Tokens/Icons/Direction';
import { useTranslation } from 'utils/translation/translation.ts';
import { ConfirmUpload } from './ConfirmUpload.tsx';
import { useGetFinancialsDataAsCSV, useUploadCSVFinancials } from './CSVUploader.hooks.ts';

export type CSVFile = {
  file: File;
  header: string[];
  data: (string | number)[][];
  meta: Papa.ParseMeta;
};

interface CSVUploaderProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const CSVUploader = ({ onOpen, isOpen, onClose, title }: CSVUploaderProps) => {
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const [isUploadConfirmed, setIsUploadConfirmed] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [csvFile, setCSVFile] = useState<CSVFile | null>(null);
  const [rejectionMessages, setRejectionMessages] = useState<string[]>([]);
  const [columnMappings, setColumnMappings] = useState<{ [key: string]: string }>({});
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const { t } = useTranslation('csv');
  const { onUploadDone } = useUploadCSVFinancials();
  const dataToCSV = useGetFinancialsDataAsCSV();

  const DESCRIPTION = [
    {
      title: t('csv:steps.financialStep1'),
      description: t('csv:steps.financialDesc1'),
      button: t('csv:button.download'),
    },
    {
      title: t('csv:steps.financialStep2'),
      description: t('csv:steps.financialDesc2'),
    },
    {
      title: t('csv:steps.financialStep3'),
      description: t('csv:steps.financialDesc3'),
    },
    {
      title: t('csv:steps.financialStep4'),
    },
  ];

  const COLUMNS = [
    {
      title: 'Reporting unit name',
      key: 'reportingUnitName',
      required: true,
      type: 'string',
    },
    { title: 'Activity name', key: 'activityName', required: true, type: 'string' },
    { title: 'Turnover', key: 'revenue', required: false, type: 'number' },
    { title: 'CapEx', key: 'capex', required: false, type: 'number' },
    { title: 'OpEx', key: 'opex', required: false, type: 'number' },
    {
      title: 'Adaptation CapEx',
      key: 'adaptationCapex',
      required: false,
      type: 'number',
    },
    {
      title: 'Adaptation OpEx',
      key: 'adaptationOpex',
      required: false,
      type: 'number',
    },
  ];

  const toast = useToast();
  const errorMessage = {
    columnsNb: t('csv:error.columnsNb'),
    stringsRequired: t('csv:error.stringsRequired'),
    numbersRequired: t('csv:error.numbersRequired'),
  };

  const resetStates = () => {
    setCSVFile(null);
    setIsUploadConfirmed(false);
    setIsFileUploaded(false);
    setRejectionMessages([]);
    setColumnMappings({});
  };

  useEffect(() => {
    setRejectionMessages([]);
  }, [csvFile]);

  function parseFile(file: File): Promise<CSVFile> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: 'greedy',
        header: false,
        dynamicTyping: true,
        complete: (parseResults: any) => {
          if (parseResults.errors.length > 0) {
            toast({ text: t('csv:error.parseError'), variant: 'danger' });
            return reject({ file, ...parseResults.errors[0] });
          }
          resolve({
            file,
            header: parseResults.data.slice(0, 1).flat() as string[],
            data: parseResults.data.slice(1) as string[][],
            meta: parseResults.meta,
          });
        },
      });
    });
  }

  const handleCSVUpload = (file: File) => {
    parseFile(file).then((csvData) => {
      setIsFileUploaded(true);
      setCSVFile({
        ...csvData,
        data: csvData?.data.map((data) =>
          data.map((cell) =>
            typeof cell === 'string' && /^[0-9,.-]*$/.test(cell)
              ? parseFloat(cell.replaceAll(',', ''))
              : cell
          )
        ),
      });
      setFileName(file.name);
    });
  };

  const rowsToColumns = (array: (string | number)[][]) => {
    const [row] = array;
    return row?.map((_value: string | number, column: number) =>
      array?.map((line: (string | number)[]) => line[column])
    );
  };
  const csvColumns = useMemo(() => rowsToColumns(csvFile?.data ?? []), [csvFile]);

  const isMatched = useMemo(
    () =>
      COLUMNS.every((element) => {
        const index = csvFile?.header.findIndex((header) => header === element.title);
        if (
          index !== -1 &&
          index !== undefined &&
          csvColumns[index].every(
            (data) => typeof data == element.type || data == null || data == undefined
          )
        ) {
          return true;
        }
        return false;
      }),
    [COLUMNS, csvFile]
  );

  const getColumnsCounts = () => {
    const requiredColumnsCount = { string: 0, number: 0 };
    const csvColumnsCount = { string: 0, number: 0 };
    const requiredColumns: { title: string; key: string; required: boolean; type: string }[] = [];

    COLUMNS.forEach((column) => {
      if (column.required) {
        if (!requiredColumns.includes(column)) requiredColumns.push(column);
        if (column.type === 'number') requiredColumnsCount.number++;
        if (column.type === 'string') requiredColumnsCount.string++;
      }
    });

    csvColumns.forEach((column) => {
      if (
        column.every((cell) => typeof cell === 'number' || cell === null) &&
        column.some((cell) => typeof cell === 'number')
      )
        csvColumnsCount.number++;
      if (
        column.every((cell) => typeof cell === 'string' || cell === null) &&
        column.some((cell) => typeof cell === 'string')
      )
        csvColumnsCount.string++;
    });
    return { requiredColumnsCount, csvColumnsCount, requiredColumns };
  };

  const checkCSVErrors = (
    requiredColumnsCount: { string: number; number: number },
    csvColumnsCount: {
      string: number;
      number: number;
    },
    requiredColumns: { title: string; key: string; required: boolean; type: string }[]
  ) => {
    const newErrorMessages: string[] = [...rejectionMessages];
    if (csvColumnsCount.number < requiredColumnsCount.number) {
      if (
        !newErrorMessages.includes(
          errorMessage.numbersRequired +
            requiredColumns.filter((col) => col.type === 'number').map((c) => ' ' + c.title)
        )
      )
        newErrorMessages.push(
          errorMessage.numbersRequired +
            requiredColumns.filter((col) => col.type === 'number').map((c) => ' ' + c.title)
        );
    }
    if (csvColumnsCount.string < requiredColumnsCount.string) {
      if (
        !newErrorMessages.includes(
          errorMessage.stringsRequired +
            requiredColumns.filter((col) => col.type === 'string').map((c) => ' ' + c.title)
        )
      )
        newErrorMessages.push(
          errorMessage.stringsRequired +
            requiredColumns.filter((col) => col.type === 'string').map((c) => ' ' + c.title)
        );
    }
    if (csvColumns?.length < requiredColumns?.length) {
      if (!newErrorMessages.includes(errorMessage.columnsNb + requiredColumns.length + ')'))
        newErrorMessages.push(errorMessage.columnsNb + requiredColumns.length + ')');
    }
    return newErrorMessages;
  };

  const validateCSV = () => {
    if (csvFile?.data && csvFile?.data.length < 1) {
      toast({ text: t('csv:error.data'), variant: 'danger' });
    } else {
      const { requiredColumnsCount, csvColumnsCount, requiredColumns } = getColumnsCounts();

      const newErrorMessages = checkCSVErrors(
        requiredColumnsCount,
        csvColumnsCount,
        requiredColumns
      );
      setRejectionMessages(newErrorMessages);

      if (!newErrorMessages?.length) {
        if (isMatched) {
          COLUMNS.forEach((column) => {
            setColumnMappings((curValue) => ({ ...curValue, [column.key]: column.title }));
          });
          onClose();
          onConfirmOpen();
        } else setIsUploadConfirmed(true);
      } else {
        toast({ text: t('csv:toast.uploadError') });
      }
    }
  };

  const onConfirm = async () => {
    const rows: { [key: string]: string | number }[] = [];
    csvFile?.data.forEach((row: (string | number)[]) => {
      const newRow: { [key: string]: string | number } = {};
      Object.keys(columnMappings).forEach((key) => {
        newRow[key] = row[csvFile.header.indexOf(columnMappings[key])];
      });
      rows.push(newRow);
    });

    onUploadDone(
      rows.map((row) => ({
        reportingUnitName: row.reportingUnitName as string,
        activityName: row.activityName as string,
        revenue: row.revenue as number,
        capex: row.capex as number,
        opex: row.opex as number,
        adaptationCapex: row.adaptationCapex as number,
        adaptationOpex: row.adaptationOpex as number,
      }))
    ).then(
      (_result) => {
        onConfirmClose();
        toast({
          text: t('csv:toast.uploadSuccess'),
          closable: false,
          icon: <CheckIcon color="white" />,
        });
      },
      (_error) => {
        toast({
          text: t('csv:toast.uploadError'),
          closable: false,
          icon: <CheckIcon color="white" />,
        });
        resetStates();
        onConfirmClose();
      }
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetStates();
          onClose();
        }}
        title={title}
        size="md"
        confirmText={isUploadConfirmed ? t('csv:button.map') : t('csv:button.uploadFile')}
        onConfirm={() => {
          if (!isUploadConfirmed) {
            validateCSV();
          } else {
            onClose();
            onConfirmOpen();
          }
        }}
        confirmButtonProps={
          isUploadConfirmed
            ? {
                isDisabled:
                  !columnMappings ||
                  !COLUMNS.filter((c) => c.required).every((c) => !!columnMappings[c.key]),
              }
            : { isDisabled: !isFileUploaded }
        }
        actions={
          isUploadConfirmed
            ? [
                <Button
                  variant="ghost"
                  leftIcon={<ArrowNarrowLeftIcon color="inherit" />}
                  onClick={() => {
                    setIsUploadConfirmed(false);
                    setResetSelect(true);
                  }}
                >
                  {t('csv:button.back')}
                </Button>,
              ]
            : []
        }
      >
        <Suspense>
          {!isUploadConfirmed ? (
            <Uploader
              onUpload={handleCSVUpload}
              isFileUploaded={isFileUploaded}
              dataToCSV={dataToCSV}
              setIsFileUploaded={setIsFileUploaded}
              rejectionMessages={rejectionMessages}
              fileName={fileName}
              description={DESCRIPTION}
            />
          ) : (
            <ColumnMatcher
              setColumnMappings={setColumnMappings}
              columnMappings={columnMappings}
              columns={COLUMNS}
              csvFile={csvFile}
              resetSelect={resetSelect}
              setResetSelect={setResetSelect}
            />
          )}
        </Suspense>
      </Modal>

      <Modal
        isOpen={isConfirmOpen}
        onClose={() => {
          onConfirmClose();
          resetStates();
        }}
        onConfirm={() => {
          onConfirm();
          resetStates();
        }}
        size="sm"
        title={t('csv:title.confirmUpload')}
      >
        <ConfirmUpload />
      </Modal>
    </>
  );
};
