import { QuarterEnum_Enum_, QuestionType_Enum_ } from './__generated__/graphql';
import ExcelJS from 'exceljs';

export type QuestionMetadataType = {
  activityReference: string;
  questionId: string;
  question: string;
  objective: string;
  type: string;
  unit: string;
  options: string;
  version: string;
};
export type CompanyAnswerData = {
  companyId: string;
  company: string;
  assessmentId: string;
  assessment: string;
  businessUnitId: string;
  businessUnit: string;
  reportId: string;
  answerId: string;
  answer: string | string[];
  answeredBy: string;
  updatedAt: string;
  noteHistoryId: string;
  noteId: string;
  note: string;
};

export const fundHeaderRow: Partial<ExcelJS.Column>[] = [
  { header: 'Fund owner company ID', width: 50 },
  { header: 'Fund ID', width: 50 },
  { header: 'Fund name', width: 20 },
  { header: 'Company ID', width: 50 },
  { header: 'Company name', width: 40 },
  { header: 'Contact person name', width: 40 },
  { header: 'Contact person email', width: 40 },
  { header: 'currency', width: 40 },
  { header: 'year', width: 40 },
  { header: 'quarter', width: 40 },
  { header: 'Market value', width: 20 },
  { header: 'Value of investment Q1', width: 30 },
  { header: 'Value of investment Q2', width: 30 },
  { header: 'Value of investment Q3', width: 30 },
  { header: 'Value of investment Q4', width: 30 },
];

export const FUND_HEADER_ROW_MAP_INDEXES = {
  ownerCompanyId: 1,
  fundId: 2,
  fundName: 3,
  companyId: 4,
  companyName: 5,
  contactPersonName: 6,
  contactPersonEmail: 7,
  currency: 8,
  year: 9,
  quarter: 10,
  marketValue: 11,
  valueOfInvestmentQ1: 12,
  valueOfInvestmentQ2: 13,
  valueOfInvestmentQ3: 14,
  valueOfInvestmentQ4: 15,
};

export const questionHeaderRowColumns: Partial<ExcelJS.Column>[] = [
  { header: 'qestionId', hidden: true },
  { header: 'Question', width: 41 },
  { header: 'Objective', width: 40 },
  { header: 'Type', width: 40 },
  { header: 'Unit', width: 40 },
  { header: 'Options', width: 40 },
  { header: 'Version', width: 40, hidden: true },
];
export const questionOtherInfoRowColumns: Partial<ExcelJS.Column>[] = [
  { header: 'Qestion unique ID', width: 20 },
  { header: 'Aligned', width: 20 },
  { header: 'Editable', width: 20 },
  { header: 'Visible', width: 20 },
];

export const QUESTION_HEADER_ROW_MAP_INDEXES = {
  questionId: 1,
  question: 2,
  objective: 3,
  type: 4,
  unit: 5,
  options: 6,
  version: 7,
};

export const QUESTION_OTHER_INFO_ROW_MAP_INDEXES = {
  questionUniqueId: 1,
  aligned: 2,
  editable: 3,
  visible: 4,
};

export const companyHeaderColumns = (
  companyName: string,
  assessmentTitle: string,
  businessUnitName: string
) => [
  { header: 'companyId', hidden: true },
  { header: `Company (${companyName})`, width: 40, hidden: true },
  { header: 'assessmentId', hidden: true },
  { header: `Assessment (${assessmentTitle})`, width: 40 },
  { header: 'businessUnitId', hidden: true },
  { header: `Reporting Unit (${businessUnitName ?? 'Company Level'})`, width: 40 },
  { header: 'reportId', hidden: true },
  { header: 'answerId', hidden: true },
  { header: 'Answered By', width: 30 },
  { header: 'Updated at', width: 15 },
  { header: 'noteHistoryId', hidden: true },
  { header: 'noteId', hidden: true },
  { header: 'Answer', width: 20 },
  { header: 'Note', width: 40 },
];

export const COMPANY_HEADER_KEY_MAP = {
  1: 'companyId',
  2: 'company',
  3: 'assessmentId',
  4: 'assessment',
  5: 'businessUnitId',
  6: 'businessUnit',
  7: 'reportId',
  8: 'answerId',
  9: 'answeredBy',
  10: 'updatedAt',
  11: 'noteHistoryId',
  12: 'noteId',
  13: 'answer',
  14: 'note',
};

export const COMPANIES_COLUMNS_NUMBER = companyHeaderColumns('', '', '').length;

export const getQuarterEnum = (quarter: string): QuarterEnum_Enum_ => {
  switch (quarter) {
    case 'Q1':
      return QuarterEnum_Enum_.Q1_;
    case 'Q2':
      return QuarterEnum_Enum_.Q2_;
    case 'Q3':
      return QuarterEnum_Enum_.Q3_;
    case 'Q4':
      return QuarterEnum_Enum_.Q4_;
    default:
      return QuarterEnum_Enum_.Q1_;
  }
};
export const getFundsRowData = (row: ExcelJS.Row) => ({
  ownerCompanyId: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.ownerCompanyId).text ?? '',
  fundId: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.fundId).text ?? '',
  fundName: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.fundName).text ?? '',
  companyId: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.companyId).text ?? '',
  companyName: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.companyName).text ?? '',
  contactPersonName: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.contactPersonName).text ?? '',
  contactPersonEmail: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.contactPersonEmail).text ?? '',
  currency: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.currency).text ?? '',
  year: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.year).text ?? '',
  quarter: getQuarterEnum(row.getCell(FUND_HEADER_ROW_MAP_INDEXES.quarter).text),
  marketValue: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.marketValue).text ?? 0,
  valueOfInvestmentQ1: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.valueOfInvestmentQ1).text ?? 0,
  valueOfInvestmentQ2: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.valueOfInvestmentQ2).text ?? 0,
  valueOfInvestmentQ3: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.valueOfInvestmentQ3).text ?? 0,
  valueOfInvestmentQ4: row.getCell(FUND_HEADER_ROW_MAP_INDEXES.valueOfInvestmentQ4).text ?? 0,
});

export const getQuestionRowMetadata = (
  row: ExcelJS.Row,
  activityReference: string
): QuestionMetadataType => ({
  activityReference,
  questionId: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.questionId).text,
  question: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.question).text,
  objective: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.objective).text,
  type: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.type).text,
  unit: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.unit).text,
  options: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.options).text,
  version: row.getCell(QUESTION_HEADER_ROW_MAP_INDEXES.version).text,
});

export const getCompanyAnswerData = (
  row: ExcelJS.Row,
  questionType: string,
  startCol: number = 1,
  endCol: number
): CompanyAnswerData => {
  const companyAnswerData = <CompanyAnswerData>{};
  for (let i = startCol; i <= endCol; i++) {
    const cell = row.getCell(i);
    const key = COMPANY_HEADER_KEY_MAP[
      (i - startCol + 1) as keyof typeof COMPANY_HEADER_KEY_MAP
    ] as keyof CompanyAnswerData;
    if (
      questionType === 'multiple choice' &&
      key === 'answer' &&
      !cell.text?.startsWith('[') &&
      !cell.text?.endsWith(']')
    ) {
      const cellText = cell.text
        ?.split(/[^a-zA-Z0-9]/)
        .map((item) => item.trim())
        .filter((item) => item !== '');

      companyAnswerData[key] = cellText;
    } else {
      companyAnswerData[key] = cell.text ?? '';
    }
  }
  return companyAnswerData;
};

export const GENERAL_ACTIVITY_REF = '0.0';

export type ObjectiveType = string;
export type ObjectiveId =
  | 'adaptation'
  | 'biodiversity'
  | 'circular'
  | 'general'
  | 'mitigation'
  | 'pollution'
  | 'social'
  | 'water';

export const OBJECTIVE_INDEX_MAP: Record<ObjectiveId | ObjectiveType, number> = {
  general: 0,
  social: 1,
  mitigation: 2,
  adaptation: 3,
  water: 4,
  circular: 5,
  pollution: 6,
  biodiversity: 7,
};

export const getObjectiveIndex = (key: ObjectiveType) => OBJECTIVE_INDEX_MAP[key] ?? 100;

export type ObjectiveWithKey = { key: ObjectiveId | string };

export const sortObjectives = (a: ObjectiveWithKey, b: ObjectiveWithKey) =>
  getObjectiveIndex(a.key) - getObjectiveIndex(b.key);

export const formatType = (type: string) => {
  if (type.startsWith('YES')) {
    return `${type.replaceAll('_', ' / ')}\n`;
  }

  return type.toLowerCase().replaceAll('_', ' ');
};

export const formatChoices = (
  choices: {
    value: string;
    displayName: string;
    order: string;
  }[]
) => {
  return choices.length
    ? `${choices.map((qc) => `${qc.value}) ${qc.displayName}`).join('\n')}\n`
    : '';
};
export const formatAnswer = (answer: string | string[] | undefined, type: string) => {
  const multipleChoiceTypes: string[] = [
    QuestionType_Enum_.MultipleChoice_,
    QuestionType_Enum_.SingleChoice_,
  ];
  if (typeof answer !== 'string' && multipleChoiceTypes.includes(type)) {
    return answer?.join(', ');
  }
  return answer;
};

export const formatQuestion = (question: string, type: string) => {
  if (type === QuestionType_Enum_.MultipleChoice_) {
    return `${question}\n\nOnly the letters corresponding to the selected options should be written, separated by commas. For example, entering a, b, e will include options a, b, and e in the selection.`;
  }

  return question;
};

export const quarterValue = {
  Q1: '01.01',
  Q2: '01.04',
  Q3: '01.07',
  Q4: '01.10',
};

export const formatQuarterYearToDate = (quarter: string, year?: string | number | null) => {
  if (!year) year = new Date().getFullYear();
  const quarterMonthDay = quarterValue[quarter as QuarterEnum_Enum_];
  const month = Number(quarterMonthDay?.split('.')[1] ?? 1) - 1;
  const date = new Date(Number(year), month, 1);

  return date;
};

export function getEndQuarterYear(quarter: string, year?: string | number | null) {
  switch (quarter) {
    case 'Q1':
      return ['31.12', ''];
    case 'Q2':
      return ['31.03', `${Number(year) + 1}`];
    case 'Q3':
      return ['30.06', `${Number(year) + 1}`];
    case 'Q4':
      return ['30.09', `${Number(year) + 1}`];
    default:
      return ['', ''];
  }
}
