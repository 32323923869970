import { ChakraTheme } from '@chakra-ui/react';

// TAG STYLES

export const TagTheme: ChakraTheme['components']['Tag'] = {
  parts: ['container'],
  baseStyle: {
    container: {
      fontFamily: 'Inter',
      fontSize: 'md',
      justifyContent: 'center',
      cursor: 'default',
      _hover: {
        cursor: 'pointer',
      },
      _active: {
        cursor: 'pointer',
      },
    },
  },
  sizes: {
    md: {
      container: {
        minH: '36px',
        px: '16px',
        py: '8px',
        borderRadius: '8px',
      },
    },
    sm: {
      container: {
        minH: '28px',
        px: '10px',
        py: '4px',
        borderRadius: '6px',
      },
    },
    xs: {
      container: {
        minH: '20px',
        px: '4px',
        py: '2px',
        borderRadius: '4px',
        fontSize: 'sm',
      },
    },
  },
  variants: {
    default: {
      container: {
        color: 'text.default',
        bg: 'bg.default',
        border: '1px solid',
        borderColor: 'border.decorative',
        _hover: {
          bg: 'bg.hover',
        },
        _active: {
          bg: 'bg.pressed',
        },
        _disabled: {
          bg: 'bg.disabled',
          border: 'none',
          color: 'text.disabled',
        },
      },
    },
    success: {
      container: {
        color: 'text.success',
        bg: 'bg.success',
        _hover: {
          bg: 'bg.success.hover',
        },
        _active: {
          bg: 'bg.success.pressed',
        },
      },
    },
    warning: {
      container: {
        color: 'text.warning',
        bg: 'bg.warning',
        _hover: {
          bg: 'bg.warning.hover',
        },
        _active: {
          bg: 'bg.warning.pressed',
        },
      },
    },
    critical: {
      container: {
        color: 'text.critical',
        bg: 'bg.critical',
        _hover: {
          bg: 'bg.critical.hover',
        },
        _active: {
          bg: 'bg.critical.pressed',
        },
      },
    },
    undefined: {
      container: {
        color: 'text.muted',
        bg: 'bg.unknown',
        _hover: {
          bg: 'bg.unknown.hover',
        },
        _active: {
          bg: 'bg.unknown.pressed',
        },
      },
    },
    info: {
      container: {
        color: 'text.info',
        bg: 'bg.info',
        _hover: {
          bg: 'bg.info.hover',
        },
        _active: {
          bg: 'bg.info.pressed',
        },
      },
    },
    compliant: {
      container: {
        color: 'text.compliant',
        bg: 'bg.compliant',
        _hover: {
          bg: 'bg.compliant.hover',
        },
        _active: {
          bg: 'bg.compliant.pressed',
        },
      },
    },
    notCompliant: {
      container: {
        color: 'text.notCompliant',
        bg: 'bg.notCompliant',
        _hover: {
          bg: 'bg.notCompliant.hover',
        },
        _active: {
          bg: 'bg.notCompliant.pressed',
        },
      },
    },
    ghost: {
      container: {
        color: 'text.default',
        bg: 'bg.unknown',
      },
    },
  },
};
