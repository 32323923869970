import { aggregateFinancials, FINANCIAL_SECTIONS } from 'utils/financials.ts';
import { useEffect, useState } from 'react';
import {
  AttachmentBox,
  Financials,
  GetFinancialsDocumentationByIdDocument_,
  NoteHistory,
  ReportingGroup,
  ScoreSectionsEnum,
  useGetFinancialsDocumentationByIdQuery,
  useReportingGroupsLazyQuery_,
  useReportingGroupsQuery,
  useUpdateFinancialEstimateManyMutation,
} from 'models/index.ts';
import { useParams } from 'react-router-dom';
import { FinancialFilter, useGetFilterData } from '../Header/FinancialsHeader.hooks.ts';
import { CompanyFinancialResults, useFinancials } from 'Features/Financials/Financials.hooks.ts';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation.tsx';
import { useUserData } from '@nhost/react';
import { useCurrentCompany, useGetCurrentCAssessment } from 'utils/hooks/General.hooks.ts';
import { useDisclosure } from '@chakra-ui/react';
import { useKnowledgeBase } from 'Features/KnowledgeBase/KnowledgeBase.tsx';
import {
  ARTICLE_ADAPTATION_CAPEX_OPEX,
  ARTICLE_WHAT_IS_CAPEX,
  ARTICLE_WHAT_IS_OPEX,
} from 'Features/KnowledgeBase/KnowledgeBaseArticles.ts';
import { useBusinessUnitsSorting } from 'containers/Assessments/pieces/Assessment.hooks.ts';

export const MAX_ALLOWED_NUMBER = 900000000000000;
export const MIN_ALLOWED_NUMBER = 0;

export const allFinancialSectionKeys = FINANCIAL_SECTIONS.map((section) => section.key);

const useCommonFinancialData = () => {
  const { financials } = useFinancials({});
  const { isLocked } = useGetCurrentCAssessment();
  const { company } = useCurrentCompany();
  const currency = company?.currency;

  return { financials, isLocked, currency };
};

const useDocumentationData = (financialsId: string, onAttachmentDrawerOpen: () => void) => {
  const user = useUserData();
  const { isLocked } = useGetCurrentCAssessment();
  const { data: documentationData } = useGetFinancialsDocumentationByIdQuery({
    variables: { financialsId },
    skip: !financialsId,
  });

  const attachmentBox: AttachmentBox | undefined =
    documentationData?.Financials_by_pk?.attachmentBox ?? undefined;

  const noteHistory: NoteHistory | undefined =
    documentationData?.Financials_by_pk?.noteHistory ?? undefined;

  const documentation: InputCardDocumentationProps = {
    isHidden: isLocked,
    currentAuthor: user ?? undefined,
    attachmentBox,
    noteHistory,
    openAttachmentDrawer: onAttachmentDrawerOpen,
    refetchQueries: [GetFinancialsDocumentationByIdDocument_],
  };

  return { documentation, attachmentBox };
};

export const useGetFilteredFinancialsData = () => {
  const { filter, filteredById } = useGetFilterData();
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const [reportingGroups, setReportingGroups] = useState<ReportingGroup[]>([]);
  const [getReportingGroupsQuery] = useReportingGroupsLazyQuery_();
  const { financials, loading } = useFinancials({});
  const { sortAlphabetically, groupsFirst } = useBusinessUnitsSorting();

  useEffect(() => {
    getReportingGroupsQuery({ variables: { cAssessmentId } }).then((result) =>
      setReportingGroups(result.data?.reportingGroups ?? [])
    );
  }, [cAssessmentId, getReportingGroupsQuery]);

  const filteredFinancials =
    filter === FinancialFilter.byId
      ? financials.businessUnits.find((bu) => bu.businessUnit?.id === filteredById)
      : undefined;

  const sortedItems = [
    ...reportingGroups
      .filter((item) => item.parentId === null)
      .map((rg) => ({
        id: rg.id,
        name: rg.name,
        isGroup: true,
        data: rg,
      })),
    ...financials.businessUnits
      .filter(
        (bu) =>
          !reportingGroups.find((grp) =>
            grp.businessUnits.find((grpBu) => grpBu.businessUnit?.id === bu.businessUnit?.id)
          )
      )
      .map((bu) => ({
        id: bu.id,
        name: bu.businessUnit?.name ?? '',
        isGroup: false,
        data: bu,
      })),
  ].sort((a, b) => {
    if (groupsFirst) {
      if (a.isGroup && !b.isGroup) return -1;
      if (!a.isGroup && b.isGroup) return 1;
    }
    return sortAlphabetically ? a.name.localeCompare(b.name) : 0;
  });

  return {
    filter,
    filteredById,
    filteredFinancials,
    loading,
    sortedItems,
  };
};

export const useToggleEstimateStatus = () => {
  const [updateFinancials] = useUpdateFinancialEstimateManyMutation();

  const toggleEstimateStatus = async (financialIds: string[], isEstimate: boolean) => {
    await updateFinancials({
      variables: {
        ids: financialIds,
        isEstimate: !isEstimate,
      },
    });
  };

  return { toggleEstimateStatus };
};

export const useGetBusinessUnitTableData = ({
  businessUnit,
  onAttachmentDrawerOpen,
}: {
  businessUnit: CompanyFinancialResults['businessUnits'][number];
  onAttachmentDrawerOpen: () => void;
}) => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const documentation = useDocumentationData(businessUnit.financials?.id, onAttachmentDrawerOpen);

  const businessUnitFinancials = financials.businessUnits.find((bu) => bu.id === businessUnit.id);

  if (!businessUnitFinancials) {
    return { activities: [], total: undefined, eligible: undefined };
  }
  const activityFinancials = businessUnitFinancials.activities.map((act) => ({
    ...(act.financials as Financials),
    name: act.activity?.name ?? 'NA',
  }));

  const notEligibleFinancials = {
    ...businessUnitFinancials.financials,
    name: 'Taxonomy-non-eligible activities',
  };

  const editableFinancials = [...activityFinancials, notEligibleFinancials];
  const eligible = aggregateFinancials(activityFinancials);
  const total = aggregateFinancials(editableFinancials);

  const isEstimate = editableFinancials?.every((financial) => financial.isEstimate) ?? false;

  return {
    documentation,
    isLocked,
    currency,
    editableFinancials,
    total,
    eligible,
    isEstimate,
    toggleEstimateStatus,
  };
};

export const useGetFinancialsSummaryData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { documentation, attachmentBox } = useDocumentationData(
    financials.notEligibleFinancials?.id,
    onAttachmentDrawerOpen
  );

  const totalFinancials = aggregateFinancials([
    financials.totalNotEligibleFinancials,
    financials.totalEligibleFinancials,
  ]);

  const documentationData = {
    isAttachmentDrawerOpen,
    onAttachmentDrawerOpen,
    onAttachmentDrawerClose,
    documentation,
    attachmentBox,
  };

  const isMarkedAsDone =
    (financials.businessUnits?.every((bu) => !bu.financials?.isEstimate) &&
      financials.businessUnits?.every((bu) =>
        bu.activities?.every((act) => !act.financials?.isEstimate)
      )) ||
    isLocked;

  return {
    notEligibleFinancials: financials.totalNotEligibleFinancials,
    eligibleFinancials: financials.totalEligibleFinancials,
    totalFinancials,
    documentationData,
    currency,
    isMarkedAsDone,
  };
};

export const useGetCompanyNotEligibleTableData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const notEligibleFinancials = financials.notEligibleFinancials;

  return {
    financials: notEligibleFinancials,
    currency,
    isLocked,
    toggleEstimateStatus,
  };
};

export const useGetTotalComparisonTableData = () => {
  const { financials, isLocked, currency } = useCommonFinancialData();
  const { toggleEstimateStatus } = useToggleEstimateStatus();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { documentation, attachmentBox } = useDocumentationData(
    financials.comparisonTotalFinancials?.id,
    onAttachmentDrawerOpen
  );

  const documentationData = {
    isAttachmentDrawerOpen,
    onAttachmentDrawerOpen,
    onAttachmentDrawerClose,
    documentation,
    attachmentBox,
  };

  const totalFinancials = aggregateFinancials([
    financials.totalEligibleFinancials,
    financials.totalNotEligibleFinancials,
  ]);

  return {
    comparisonFinancials: financials.comparisonTotalFinancials,
    totalFinancials,
    isLocked,
    currency,
    documentationData,
    toggleEstimateStatus,
  };
};

export const useFinancialsHelp = () => {
  const { onOpen } = useKnowledgeBase();
  const onFinancialsHelpClicked = (title: string) => {
    if (title.toLowerCase() === ScoreSectionsEnum.capex) {
      onOpen(ARTICLE_WHAT_IS_CAPEX.slug);
    } else if (title.toLowerCase() === ScoreSectionsEnum.opex) {
      onOpen(ARTICLE_WHAT_IS_OPEX.slug);
    } else if (title.toLowerCase().includes('adapt')) {
      onOpen(ARTICLE_ADAPTATION_CAPEX_OPEX.slug);
    }
  };
  return onFinancialsHelpClicked;
};

export const useGroupFinancials = (reportingGroupId: string) => {
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const { data: reportingGroupsData } = useReportingGroupsQuery({
    variables: { cAssessmentId },
    skip: !cAssessmentId,
  });
  const { financials } = useFinancials({});
  const reportingGroups = reportingGroupsData?.reportingGroups ?? [];

  const reportingGroup = reportingGroups.find((rg) => rg.id === reportingGroupId);
  const childGroups = reportingGroups.filter((child) => child.parentId === reportingGroupId);
  const groupBusinessUnits =
    financials?.businessUnits.filter((bu) =>
      reportingGroup?.businessUnits.find((rgBu) => rgBu.businessUnit?.id === bu.businessUnit?.id)
    ) ?? [];

  const allBusinessUnits = financials?.businessUnits;
  const childrenBu = allBusinessUnits.filter((bu) =>
    [...childGroups, reportingGroup].find((grp) => grp?.id === bu.reportingGroupId)
  );
  const isDone = childrenBu.every((bu) => bu.financials?.isEstimate === false);

  return {
    reportingGroup,
    childGroups,
    groupBusinessUnits,
    isDone,
  };
};
