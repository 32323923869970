import { VStack, HStack, Skeleton, Thead, Tr, Th, Tbody, Td, Table } from '@chakra-ui/react';
import { InputCard } from 'Molecules/InputCard/InputCard.tsx';

export const FinancialTableSkeleton = () => {
  return (
    <InputCard>
      <VStack spacing="4" width="100%">
        <HStack width="100%" justifyContent="space-between">
          <Skeleton height="24px" width="200px" />
          <Skeleton height="24px" width="100px" />
        </HStack>
        <Table width="100%">
          <Thead>
            <Tr>
              <Th>
                <Skeleton height="20px" width="100px" />
              </Th>
              {[1, 2, 3, 4, 5].map((i) => (
                <Th key={i}>
                  <Skeleton height="20px" width="80px" />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {[1, 2, 3].map((row) => (
              <Tr key={row}>
                <Td>
                  <Skeleton height="20px" width="150px" />
                </Td>
                {[1, 2, 3, 4, 5].map((col) => (
                  <Td key={col}>
                    <Skeleton height="20px" width="60px" />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </InputCard>
  );
};
