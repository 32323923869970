import { ReportSettingsForm } from 'containers/Portfolios/PortfolioSettings';
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfQuarter,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { GetPaiPortfolioReportQuery_, GetTaxonomyPortfolioReportQuery_ } from 'models';
import { UseFormSetValue } from 'react-hook-form';

const calculateDeadline = (
  reportingPeriod: Date,
  reportingPeriodEndDate: Date,
  isCustomPeriod: boolean
) => {
  if (differenceInCalendarDays(reportingPeriod, new Date()) < 0) {
    return new Date(new Date().getFullYear() + 1, 0, 31); // If reporting period has passed, set to end of January next year
  }
  return isCustomPeriod
    ? addDays(reportingPeriodEndDate ?? new Date(), 14) // 14 days after the end of custom reporting period
    : new Date(reportingPeriod.getFullYear() + 1, 0, 31); // End of January of the reporting period's year
};

const calculateSendOutDate = (reportingPeriod: Date, isCustomPeriod: boolean) => {
  return differenceInCalendarDays(reportingPeriod, new Date()) <= 0
    ? new Date()
    : isCustomPeriod
      ? reportingPeriod
      : startOfYear(reportingPeriod);
};

export const calculatePaiQuarters = (reportingPeriod: Date) => {
  const q1 = startOfDay(startOfMonth(reportingPeriod));
  const q2 = addMonths(q1, 3);
  const q3 = addMonths(q2, 3);
  const q4 = addMonths(q3, 3);

  const sendOutQ1 = endOfQuarter(q1);
  const sendOutQ2 = endOfQuarter(q2);
  const sendOutQ3 = endOfQuarter(q3);
  const sendOutQ4 = endOfQuarter(q4);

  const deadlineQ1 = addDays(sendOutQ1, 14);
  const deadlineQ2 = addDays(sendOutQ2, 14);
  const deadlineQ3 = addDays(sendOutQ3, 14);
  const deadlineQ4 = addDays(sendOutQ4, 14);

  return {
    q1: {
      sendOut: sendOutQ1,
      deadline: deadlineQ1,
    },
    q2: {
      sendOut: sendOutQ2,
      deadline: deadlineQ2,
    },
    q3: {
      sendOut: sendOutQ3,
      deadline: deadlineQ3,
    },
    q4: {
      sendOut: sendOutQ4,
      deadline: deadlineQ4,
    },
  };
};

export const calculateTaxonomyDates = (
  taxonomyReport: GetTaxonomyPortfolioReportQuery_['TaxonomyPortfolioReport'][number] | undefined,
  reportingPeriod: Date,
  reportingPeriodEndDate: Date,
  setValue: UseFormSetValue<ReportSettingsForm>,
  isCustomPeriod: boolean = false
) => {
  const deadline = calculateDeadline(reportingPeriod, reportingPeriodEndDate, isCustomPeriod);
  const sendOutDate = calculateSendOutDate(reportingPeriod, isCustomPeriod);

  if (!!taxonomyReport) {
    setValue('euContactPersonId', taxonomyReport?.contactPersonId ?? '');
  }
  setValue(
    'euYearlyDateToSendOut',
    startOfDay(taxonomyReport?.sendOutDate ?? sendOutDate) || new Date()
  );
  setValue('euYearlyDeadlineDate', startOfDay(taxonomyReport?.dueDate ?? deadline) || new Date());
};

export const calculatePaiDates = (
  paiReport: GetPaiPortfolioReportQuery_['report'][number] | undefined,
  reportingPeriod: Date,
  reportingPeriodEndDate: Date,
  setValue: UseFormSetValue<ReportSettingsForm>,
  isCustomPeriod: boolean = false
) => {
  const paiQuartersDates = calculatePaiQuarters(reportingPeriod);
  const yearlyDeadline = calculateDeadline(reportingPeriod, reportingPeriodEndDate, isCustomPeriod);
  const yearlySendOutDate = calculateSendOutDate(reportingPeriod, isCustomPeriod);

  if (!!paiReport) {
    setValue('paiContactPersonId', paiReport?.contactPerson?.id ?? '');
    setValue(
      'indicators',
      paiReport?.selectedIndicators.map((x) => ({
        ...x,
        selectedPortfolioCompanyIds:
          x.paiReportIndicatorPortfolioCompanies.map((c) => c?.portfolioCompanyId) ?? [],
        periods: x.periods,
      })) ?? []
    );
  }

  setValue(
    'paiQ1DateToSendOut',
    startOfDay(paiReport?.sendOutDates?.['Q1'] ?? paiQuartersDates?.q1?.sendOut) ?? new Date()
  );
  setValue(
    'paiQ1DeadlineDate',
    startOfDay(paiReport?.dueDates?.['Q1'] ?? paiQuartersDates?.q1?.deadline) ?? new Date()
  );
  setValue(
    'paiQ2DateToSendOut',
    startOfDay(paiReport?.sendOutDates?.['Q2'] ?? paiQuartersDates?.q2?.sendOut) ?? new Date()
  );
  setValue(
    'paiQ2DeadlineDate',
    startOfDay(paiReport?.dueDate?.['Q2'] ?? paiQuartersDates?.q2?.deadline) ?? new Date()
  );
  setValue(
    'paiQ3DateToSendOut',
    startOfDay(paiReport?.sendOutDates?.['Q3'] ?? paiQuartersDates?.q3?.sendOut) ?? new Date()
  );
  setValue(
    'paiQ3DeadlineDate',
    startOfDay(paiReport?.dueDates?.['Q3'] ?? paiQuartersDates?.q3?.deadline) ?? new Date()
  );
  setValue(
    'paiQ4DateToSendOut',
    startOfDay(paiReport?.sendOutDates?.['Q4'] ?? paiQuartersDates?.q4?.sendOut) ?? new Date()
  );
  setValue(
    'paiQ4DeadlineDate',
    startOfDay(paiReport?.dueDates?.['Q4'] ?? paiQuartersDates?.q4?.deadline) ?? new Date()
  );

  setValue(
    'paiYearlyDateToSendOut',
    startOfDay(paiReport?.sendOutDates?.['year'] ?? yearlySendOutDate) ?? new Date()
  );
  setValue(
    'paiYearlyDeadlineDate',
    startOfDay(paiReport?.dueDates?.['year'] ?? yearlyDeadline) ?? new Date()
  );
};
