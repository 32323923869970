import {
  ChakraTheme,
  IconProps,
  AlertProps as ChakraAlertProps,
  Alert as ChakraAlert,
  StackProps,
  HStack,
  VStack,
  Collapse,
} from '@chakra-ui/react';
import { AlertStatus, ALERT_COLORS } from 'Atoms/Alert';
import { IconButton } from 'Atoms/Buttons';
import { CheckIcon, DangerIcon, InfoIcon, WarningIcon } from 'Tokens/Icons/Status';

import { Typography, TypographyProps } from 'Tokens';
import React from 'react';
import { RemoveIcon } from 'Tokens/Icons/Function';

const AlertInfoboxIcon = ({
  status,
  icon,
  ...props
}: IconProps & {
  status: AlertStatus;
  icon?: React.ReactNode;
}) => {
  if (icon) return <>{icon}</>;
  if (status === 'critical') {
    return <DangerIcon {...props} color="text.critical" />;
  }
  if (status === 'warning') {
    return <WarningIcon {...props} color="text.warning" />;
  }
  if (status === 'success') {
    return <CheckIcon {...props} color="text.success" />;
  }
  if (status === 'neutral') {
    return <InfoIcon {...props} color="text.muted" />;
  }
  if (status === 'info' || status === 'highlight') {
    return <InfoIcon {...props} color="text.info" />;
  }
  return null;
};

export const InfoboxVariant: ChakraTheme['components']['Alert']['variants'] = {
  infoBox: (props) => {
    return {
      container: {
        backgroundColor: ALERT_COLORS[props.colorScheme]?.bg,
        borderRadius: '8px',
        minH: '80px',
        height: 'auto',
        minWidth: '240px',
        border: '1px',
        borderColor: ALERT_COLORS[props.colorScheme]?.border,
        width: 'fit-content',
        padding: '16px',
      },
    };
  },
};

export type InfoboxProps = Omit<ChakraAlertProps, 'status'> & {
  status: AlertStatus;
  title?: string | React.ReactNode;
  titleProps?: TypographyProps;
  withIcon?: boolean;
  description?: string | React.ReactNode;
  extra?: string | React.ReactNode;
  icon?: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
  rightElement?: React.ReactNode;
  isVisible?: boolean;
  textVariant?: string;
  textWidth?: string;
  spacing?: string;
  containerStyles?: StackProps;
};
export const Infobox = ({
  status,
  withIcon = true,
  icon,
  title,
  titleProps,
  description,
  extra,
  closable = true,
  onClose,
  rightElement,
  textVariant,
  isVisible = true,
  textWidth,
  spacing,
  ...props
}: InfoboxProps) => {
  return (
    <Collapse in={isVisible} animateOpacity>
      <ChakraAlert variant="infoBox" colorScheme={status} status="success" width="100%" {...props}>
        <HStack
          justifyContent="space-between"
          alignItems="stretch"
          width={textWidth ?? '100%'}
          height="100%"
          spacing={spacing ?? '30px'}
          {...props.containerStyles}
        >
          <HStack spacing="12px" alignItems="flex-start">
            {withIcon && (
              <VStack justifyContent="flex-start" mt="2px">
                <AlertInfoboxIcon status={status} icon={icon} marginTop="4px" />
              </VStack>
            )}
            <VStack justifyContent="flex-start" alignItems="flex-start" spacing="24px">
              <VStack spacing="4px" justifyContent="flex-start" alignItems="flex-start">
                {title && (
                  <HStack justifyContent="flex-start">
                    <Typography variant="h3" {...titleProps} color="text.default">
                      {title}
                    </Typography>
                  </HStack>
                )}
                {description && (
                  <HStack justifyContent="flex-start" width="100%" alignItems="flex-start">
                    <Typography variant="body" color="text.default">
                      {description}
                    </Typography>
                  </HStack>
                )}
              </VStack>
              {extra && <HStack>{extra}</HStack>}
            </VStack>
          </HStack>

          {rightElement && <VStack justifyContent="center">{rightElement}</VStack>}

          {closable && (
            <VStack justifyContent="flex-start">
              <IconButton
                position="absolute"
                top="8px"
                right="8px"
                variant="ghost"
                aria-label="RemoveIcon"
                size="md"
                alignSelf="flex-start"
                icon={<RemoveIcon color="inherit" />}
                onClick={onClose}
              />
            </VStack>
          )}
        </HStack>
      </ChakraAlert>
    </Collapse>
  );
};
