import { HStack, Input, Skeleton } from '@chakra-ui/react';
import { CurrencyConversionTableRow } from './CurrencyConversion.d';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { Typography } from 'Tokens';
import { EditIcon } from 'Tokens/Icons/Data';
import {
  GetCurrencyConversionRateDocument_,
  useGetCurrencyConversionRateQuery,
  useUpsertCurrencyConversionRateMutation,
} from 'models';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

export const CurrencyConversionInput = ({ row }: { row: CurrencyConversionTableRow }) => {
  const { esrsAssessmentId = '' } = useParams();
  const { data: conversionRate, loading: conversionRateLoading } =
    useGetCurrencyConversionRateQuery({
      variables: {
        assessmentId: esrsAssessmentId,
        from: row.from,
        to: row.to,
      },
      skip: row.isGroupCurrency,
    });

  const [upsertCurrencyConversion] = useUpsertCurrencyConversionRateMutation();

  
  const debounceSave = useDebounceCallback((newValue) => {
    upsertCurrencyConversion({
      variables: {
        object: {
          assessmentId: esrsAssessmentId,
          from: row.from,
          to: row.to,
          rate: newValue,
        },
      },
      refetchQueries: [GetCurrencyConversionRateDocument_],
    });
  }, 1000);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === '' ? null : e.target.value; // You might want to ensure newValue is a number
    debounceSave(newValue);
  };

  return (
    <HStack gap="8px">
      {row.isGroupCurrency ? <ArrowUpRightIcon /> : <EditIcon />}
      {row.isGroupCurrency ? (
        <Typography variant="body">1.00</Typography>
      ) : (
        <Skeleton isLoaded={!conversionRateLoading}>
          <Input
            type="number"
            border="none"
            placeholder="Input conversion rate"
            padding="0px"
            defaultValue={conversionRate?.esrs_AssessmentCurrencyConversion_by_pk?.rate?.toFixed(2)}
            onChange={handleChange}
          />
        </Skeleton>
      )}
    </HStack>
  );
};
