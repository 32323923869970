import {
  Table as ChakraTable,
  CircularProgress,
  HStack,
  Td,
  Tr,
  VStack,
  MenuButton,
  Box,
  Skeleton,
} from '@chakra-ui/react';
import { IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { StructureNode } from 'containers/Esrs/pieces/Assessment/Structure';
import { ShortUser, User } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Menu } from 'Molecules/Menu';
import { DREnums } from 'containers/Esrs';
import { useTranslation } from 'utils/translation';
import { nhost } from 'utils/nhost';
import { WarningIcon } from 'Tokens/Icons/Status';

const ActionAndTargetButton = ({
  targetsCount,
  actionsCount,
  type,
}: {
  targetsCount: number;
  actionsCount: number;
  type?: string;
}) => {
  const count = useMemo(() => (type === DREnums.action ? actionsCount : targetsCount), [type]);

  const getActionTooltipText = useMemo(() => {
    if (actionsCount === 0) return "You don't have any actions";
    return `You have ${actionsCount} ${actionsCount === 1 ? 'action' : 'actions'}`;
  }, [actionsCount]);

  const getTargetTooltipText = useMemo(() => {
    if (targetsCount === 0) return "You don't have any targets";
    return `You have ${targetsCount} ${targetsCount === 1 ? 'target' : 'targets'}`;
  }, [targetsCount]);

  const toolTipText = useMemo(
    () => (type === DREnums.action ? getActionTooltipText : getTargetTooltipText),
    [type, actionsCount, targetsCount]
  );

  return (
    <Menu
      customMenuButton={
        <Tooltip placement={'bottom-start'} label={toolTipText}>
          <MenuButton onClick={(e: any) => e.stopPropagation()} _hover={{ cursor: 'pointer' }}>
            <Tag
              variant={count ? 'info' : ''}
              backgroundColor={!count ? 'bg.pressed' : ''}
              size="xs"
              title={String(count)}
            />
          </MenuButton>
        </Tooltip>
      }
      sections={[]}
      size="sm"
    />
  );
};

const CompanyDrProgress = ({
  drRef,
  assessmentId,
  isActionOrTarget,
  standardRef,
  parentAssessmentId,
}: {
  drRef: string;
  assessmentId: string;
  isActionOrTarget: boolean;
  standardRef: string;
  parentAssessmentId: string | undefined;
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/disclosure-requirement-progress', {
      drRef,
      assessmentId,
      standardRef,
      parentAssessmentId,
      type: 'company',
    });
    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRef, assessmentId, standardRef, parentAssessmentId]);

  return (
    <Skeleton isLoaded={!loading}>
      <Tooltip
        placement={'bottom-start'}
        label={isActionOrTarget ? t('common:targetsAndActionsProgressBar') : ''}
      >
        <HStack spacing="8px">
          <CircularProgress
            size="16px"
            thickness="12px"
            color="text.info"
            value={isActionOrTarget ? 0 : Number(progress ?? 0)}
          />
          <Typography variant="body" color={isActionOrTarget ? 'text.hint' : 'text.primary'}>
            {!isActionOrTarget ? `${progress}%` : 'N/A'}
          </Typography>
        </HStack>
      </Tooltip>
    </Skeleton>
  );
};

const GroupDrProgress = ({
  drRef,
  assessmentId,
  isActionOrTarget,
  standardRef,
}: {
  drRef: string;
  assessmentId: string;
  isActionOrTarget: boolean;
  standardRef: string;
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/disclosure-requirement-progress', {
      drRef,
      assessmentId,
      standardRef,
      type: 'group',
    });
    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRef, assessmentId, standardRef]);
  return (
    <Skeleton isLoaded={!loading}>
      <Tooltip
        placement={'bottom-start'}
        label={isActionOrTarget ? t('common:targetsAndActionsProgressBar') : ''}
      >
        <HStack spacing="8px">
          <CircularProgress
            size="16px"
            thickness="12px"
            color="text.info"
            value={isActionOrTarget ? 0 : Number(progress ?? 0)}
          />
          <Typography variant="body" color={isActionOrTarget ? 'text.hint' : 'text.primary'}>
            {!isActionOrTarget ? `${progress}%` : 'N/A'}
          </Typography>
        </HStack>
      </Tooltip>
    </Skeleton>
  );
};

const BusinessUnitDrProgress = ({
  drRef,
  assessmentId,
  isActionOrTarget,
  standardRef,
  parentAssessmentId,
  reportingUnitId,
}: {
  drRef: string;
  assessmentId: string;
  isActionOrTarget: boolean;
  standardRef: string;
  parentAssessmentId: string | undefined;
  reportingUnitId: string;
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('0');

  const getProgress = async () => {
    setLoading(true);
    const res = await nhost.functions.call('esrs/progress/disclosure-requirement-progress', {
      drRef,
      assessmentId,
      standardRef,
      parentAssessmentId,
      reportingUnitId,
      type: 'reportingUnit',
    });
    if (!res.error) {
      setProgress((res.res?.data as { progress: string })?.progress);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProgress();
  }, [drRef, assessmentId, standardRef, parentAssessmentId]);
  return (
    <Skeleton isLoaded={!loading}>
      <Tooltip
        placement={'bottom-start'}
        label={isActionOrTarget ? t('common:targetsAndActionsProgressBar') : ''}
      >
        <HStack spacing="8px">
          <CircularProgress
            size="16px"
            thickness="12px"
            color="text.info"
            value={isActionOrTarget ? 0 : Number(progress ?? 0)}
          />
          <Typography variant="body" color={isActionOrTarget ? 'text.hint' : 'text.primary'}>
            {!isActionOrTarget ? `${progress}%` : 'N/A'}
          </Typography>
        </HStack>
      </Tooltip>
    </Skeleton>
  );
};

export const DisclosureRequirementTable = ({
  title,
  requirements,
  owner,
  isGroup,
  parentAssessmentId,
  isConfig,
  drRefsMissingTagsConfiguration,
}: {
  title: string;
  requirements: StructureNode[];
  owner?: Partial<User> | null;
  isGroup: boolean;
  parentAssessmentId?: string | undefined;
  isConfig?: boolean;
  drRefsMissingTagsConfiguration?: string[];
}) => {
  const { esrsAssessmentId, reportingUnitId = '', standardRef = '' } = useParams();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const navigate = useNavigate();
  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);
  const rowStyling = {
    p: '8px',
    borderColor: 'border.decorative',
  };

  const handleRowClick = (drRef: string, category?: string) => {
    if (isBusinessUnit || isConfig) navigate(`disclosure-requirement/${drRef}`);
    else navigate(`disclosure-requirement/${category}/${drRef}`);
  };

  return (
    <VStack alignItems="start" gap="4px">
      <Typography variant="overline" color="text.hint">
        {title.toUpperCase()}
      </Typography>
      <ChakraTable borderTop="1px solid" borderColor="border.decorative">
        {requirements.map((req, index) => (
          <Tr
            _hover={{ background: 'bg.hover', cursor: 'pointer' }}
            transition="0.16s all"
            onMouseEnter={() => setHoveredRowIndex(index)}
            onMouseLeave={() => setHoveredRowIndex(-1)}
            onClick={() => handleRowClick(req.reference ?? '', req.key?.split('_')[0] ?? '')}
          >
            {/* icon */}
            <Td
              w="28px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              {drRefsMissingTagsConfiguration?.includes(req.reference as string) ? (
                <WarningIcon boxSize="16px" color="text.warning" />
              ) : (
                <DocumentIcon boxSize="16px" />
              )}
            </Td>
            {/* ref */}
            <Td
              w="92px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <TruncatableText variant="body" text={req.reference ?? ''} />
            </Td>
            {/* title */}
            <Td
              w="540px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <Box display="flex" gap="8px">
                <TruncatableText
                  w="fit-content"
                  tooltipMaxWidth="400px"
                  variant="bodyStrong"
                  text={req.title}
                />
                {(req.type === DREnums.action || req.type === DREnums.target) && (
                  <ActionAndTargetButton
                    targetsCount={req.targetsCount || 0}
                    actionsCount={req.actionsCount || 0}
                    type={req.type}
                  />
                )}
              </Box>
            </Td>
            {isConfig ? (
              <Td
                borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
                {...rowStyling}
              >
                <Tag
                  size="xs"
                  title={req.isConfigured ? 'Done' : 'To setup'}
                  variant={req.isConfigured ? 'success' : 'warning'}
                  borderRadius="6px"
                />
              </Td>
            ) : (
              <>
                <Td
                  w="88px"
                  borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
                  {...rowStyling}
                >
                  {isBusinessUnit ? (
                    <BusinessUnitDrProgress
                      drRef={req.reference ?? ''}
                      assessmentId={esrsAssessmentId ?? ''}
                      isActionOrTarget={req.type === DREnums.action || req.type === DREnums.target}
                      standardRef={standardRef}
                      parentAssessmentId={parentAssessmentId}
                      reportingUnitId={reportingUnitId}
                    />
                  ) : isGroup ? (
                    <GroupDrProgress
                      drRef={req.reference ?? ''}
                      assessmentId={esrsAssessmentId ?? ''}
                      isActionOrTarget={req.type === DREnums.action || req.type === DREnums.target}
                      standardRef={standardRef}
                    />
                  ) : (
                    <CompanyDrProgress
                      drRef={req.reference ?? ''}
                      assessmentId={esrsAssessmentId ?? ''}
                      isActionOrTarget={req.type === DREnums.action || req.type === DREnums.target}
                      standardRef={standardRef}
                      parentAssessmentId={parentAssessmentId}
                    />
                  )}
                </Td>
                <Td
                  w="64px"
                  borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
                  {...rowStyling}
                >
                  <UserAvatar user={owner as ShortUser} size="xs" border="none" />
                </Td>{' '}
              </>
            )}

            {/* action */}
            <Td
              w="54px"
              borderBottom={index === requirements.length - 1 ? 'none' : '1px solid'}
              {...rowStyling}
            >
              <IconButton
                aria-label="side-bar"
                size="sm"
                variant="ghost"
                bg={hoveredRowIndex === index ? 'bg.hover' : ''}
                transition="0.16s all"
                icon={<ArrowNarrowRightIcon />}
                onClick={() => handleRowClick(req.reference ?? '', req.key?.split('_')[0] ?? '')}
              />
            </Td>
          </Tr>
        ))}
      </ChakraTable>
    </VStack>
  );
};
