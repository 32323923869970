import { format, getYear, parse } from 'date-fns';
import { UseTranslationResponse } from 'react-i18next';
import { Namespace } from 'i18next';
import { QuarterEnum_Enum_ } from 'models';

export const quarterValue = {
  Q1: '01.01',
  Q2: '01.04',
  Q3: '01.07',
  Q4: '01.10',
};

export const endQuarterValue = {
  Q1: '31.12',
  Q2: '31.03',
  Q3: '30.06',
  Q4: '30.09',
};

export function formatDateTime(date: Date | string | number) {
  return format(new Date(date), 'Pp');
}

export const formatDate = (date: Date | string) => format(new Date(date), 'P');

export const formatDateToDDMMYYYY = (dateString: string) => {
  const [month, day, year] = dateString.split('/');
  return `${day}/${month}/${year}`;
};

export const dateToString = (d?: Date) => {
  if (!d) return new Date().toISOString().slice(0, 10);
  return d.toISOString().slice(0, 10);
};

export const dateToYYYYMMDD = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const stringDate = new Date(date.getTime() - userTimezoneOffset);
  return format(stringDate, 'yyyy-MM-dd');
};

export const stringToDate = (s = '', formatString = 'yyyy-mm-dd') => {
  try {
    return parse(s, formatString, new Date());
  } catch (e) {
    return new Date();
  }
};

export const stringToYear = (s: string) => getYear(stringToDate(s));

export const timeSinceDate = (
  date: Date,
  translations: UseTranslationResponse<Namespace<string>, string>
) => {
  const currentDate = new Date();
  const diffTime = Math.abs(date.valueOf() - currentDate.valueOf());
  const days = diffTime / (24 * 60 * 60 * 1000);
  const hours = (days % 1) * 24;
  const minutes = (hours % 1) * 60;
  const secs = (minutes % 1) * 60;
  const weeks = days / 7;
  const { t } = translations;

  if (weeks >= 1) {
    return date.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' });
  }
  if (days > 1) {
    return Math.floor(days) + t('timeSinceDate.days');
  }
  if (hours > 1) {
    return Math.floor(hours) + t('timeSinceDate.hours');
  }
  if (minutes > 1) {
    return Math.floor(minutes) + t('timeSinceDate.minutes');
  }
  if (secs < 1) {
    return t('timeSinceDate.justNow');
  }
  return Math.floor(secs) + t('timeSinceDate.seconds');
};

export const timeUntil = (date: Date) => {
  const distance = date.valueOf() - new Date().valueOf();
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  return days < 0 ? `${Math.abs(days)} days overdue` : `${days} days left`;
};

export const getQuarterFromDate = (dateOrString: Date | string): QuarterEnum_Enum_ => {
  const date = new Date(dateOrString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const dateString = `${day}.${month}`;

  for (const [key, value] of Object.entries(quarterValue)) {
    if (value === dateString) {
      return key as QuarterEnum_Enum_;
    }
  }
  return QuarterEnum_Enum_.Q1_;
};

export const formatQuarterYear = (quarter: string, year?: string | number | null) => {
  return `${quarter}-${year ?? new Date().getUTCFullYear()}`.toUpperCase();
};

export const formatDisplayQuarterYear = (period?: string) => {
  if (!period) return '';

  const [quarter, yearStr] = period.split('-');
  const year = parseInt(yearStr, 10);

  let endYear = year;

  switch (quarter) {
    case 'Q1':
      return `${year}`;
    case 'Q2':
      endYear += 1;
      break;
    case 'Q3':
      endYear += 1;
      break;
    case 'Q4':
      endYear += 1;
      break;
    default:
      return '';
  }

  return `${quarterValue[quarter]}.${year} - ${endQuarterValue[quarter]}.${endYear}`;
};

export const formatQuarterYearToDate = (quarter: string, year?: string | number | null) => {
  if (!year) year = new Date().getUTCFullYear();
  const quarterMonthDay = quarterValue[quarter as QuarterEnum_Enum_];
  const month = Number(quarterMonthDay?.split('.')[1] ?? 1) - 1;
  const date = new Date(Number(year), month, 1);

  return date;
};

export function getEndQuarterYear(quarter: string, year?: string | number | null) {
  if (Object.values(QuarterEnum_Enum_).includes(quarter as QuarterEnum_Enum_)) {
    if (quarter === QuarterEnum_Enum_.Q1_) {
      return [endQuarterValue[quarter], ''];
    } else {
      return [endQuarterValue[quarter as QuarterEnum_Enum_], `${Number(year) + 1}`];
    }
  } else {
    return ['', ''];
  }
}

export function splitQuarterYear(period?: string) {
  const regex = /^(q[1-4])-(\d{4})$/i;
  if (!period || !regex.test(period)) return ['', ''];
  return period.split('-');
}

export function getReportingPeriod(date?: Date | string) {
  if (!date) {
    return formatQuarterYear(`Q1`, new Date().getUTCFullYear());
  }

  const year = getYearFromDate(date);
  const quarter = getQuarterFromDate(date);

  return formatQuarterYear(quarter, year);
}

export function getNextReportingPeriod(reportingPeriods?: string[]) {
  const years = reportingPeriods?.map((reportingYear) =>
    Number(splitQuarterYear(reportingYear)[1])
  );
  const sortedUniqueYears = [...new Set(years)].sort();
  const latestReportingYear = sortedUniqueYears[sortedUniqueYears.length - 1];

  return `Q1-${latestReportingYear + 1}`;
}

export function getYearFromDate(date: Date | string) {
  const fullDate = new Date(date);
  return fullDate.getUTCFullYear();
}

export enum Month {
  Jan = 0,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}
