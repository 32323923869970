import { Td, HStack, Tr } from '@chakra-ui/react';
import { FinancialsFieldsFragment_ } from 'models/index.ts';
import { Financials } from 'models/types.ts';
import { HelpTooltip } from 'Molecules/index.ts';
import { useCallback } from 'react';
import { CalculatorIcon } from 'Tokens/Icons/Data.tsx';
import { Typography } from 'Tokens/index.ts';
import { formatNumber } from 'utils/numbers.ts';
import { scoreSections } from 'utils/financials.ts';

export const DifferenceRow = ({
  title,
  helpLabel,
  totalFinancials,
  comparisonFinancials,
}: {
  title: string;
  helpLabel?: string;
  totalFinancials: Partial<Financials> | null | undefined;
  comparisonFinancials: Partial<Financials> | null | undefined;
}) => {
  type FinancialsKeys = keyof Partial<FinancialsFieldsFragment_>;

  const renderDifferenceCells = (sections: FinancialsKeys[]) => {
    return sections.map((section) => {
      const total = totalFinancials?.[section] || 0;
      const comparison = comparisonFinancials?.[section] || 0;
      const difference = comparison - total;
      return (
        <Td borderBottom="0px" key={`difference-${section}`}>
          <HStack width="100%" justifyContent="flex-end">
            <Typography variant="h4" maxWidth="200px" padding="0px">
              {formatNumber(difference ?? 0)}
            </Typography>
          </HStack>
        </Td>
      );
    });
  };

  return (
    <Tr>
      <Td borderBottom="0px">
        <HStack spacing="10px">
          <CalculatorIcon color="text.hint" />
          <Typography variant="h4">{title}</Typography>
          {helpLabel && <HelpTooltip label={helpLabel} />}
        </HStack>
      </Td>
      {renderDifferenceCells(scoreSections)}
    </Tr>
  );
};
