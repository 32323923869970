import { ReportPeriods } from 'Features/PortfolioView';
import {
  CashSovereignsOptions,
  EnvironmentalObjectiveKeyEnum,
  PortfolioCompany,
  ScoreSectionsEnum,
  TaxonomyScore,
} from 'models';
import { scoreSections } from 'utils/financials';

export const getInvestmentValue = (valueOfInvestments: any, reportPeriod: ReportPeriods) => {
  const quarterValue = valueOfInvestments?.[reportPeriod] ?? 0;
  const yearValue =
    (valueOfInvestments?.q1 +
      valueOfInvestments?.q2 +
      valueOfInvestments?.q3 +
      valueOfInvestments?.q4) /
    4;
  if (reportPeriod === ReportPeriods.year) {
    return yearValue ?? 0;
  }
  return quarterValue;
};

export const getPortfolioTotal = (
  portfolioCompanies: PortfolioCompany[],
  reportPeriod: ReportPeriods,
  cashAndSovereigns?: CashSovereignsOptions
) => {
  const cash = cashAndSovereigns?.cash ?? 0;
  const sovereigns = cashAndSovereigns?.sovereigns ?? 0;

  const total =
    portfolioCompanies.reduce(
      (sum, { valueOfInvestments }) =>
        sum + (getInvestmentValue(valueOfInvestments, reportPeriod) ?? 0),
      0
    ) +
    cash +
    sovereigns;

  return total;
};

export const getAggregatePortfolioScores = (
  portfolioCompanies: PortfolioCompany[],
  reportPeriod: ReportPeriods,
  cashAndSovereigns?: CashSovereignsOptions
) => {
  const total = getPortfolioTotal(portfolioCompanies, reportPeriod, cashAndSovereigns);

  const isAllInProgress = portfolioCompanies.every(
    (c) => (c.progress?.financials === 0 || c.progress?.screening === 0) && !c.progress?.isLocked
  );

  if (isAllInProgress) {
    return scoreSections.reduce(
      (acc, section) => {
        acc[section] = {
          inProgress: 100,
          total: 100,
          aligned: 0,
          eligible: 0,
          enabling: 0,
          transitional: 0,
        };
        return acc;
      },
      {} as Record<ScoreSectionsEnum, TaxonomyScore>
    );
  }

  const sectionScores = scoreSections.reduce(
    (scores, section) => {
      const aligned = portfolioCompanies.reduce((sum, pCompany) => {
        const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);

        const percentage = investmentValue / total;

        if (!pCompany?.company) {
          return sum + percentage * (pCompany?.estimateCompany?.scores?.[section]?.aligned ?? 0);
        }
        return sum + percentage * (pCompany?.scores?.[section]?.aligned ?? 0);
      }, 0);

      const eligible = portfolioCompanies.reduce((sum, pCompany) => {
        const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);
        const percentage = investmentValue / total;

        const isFullyInProgress =
          (pCompany?.progress?.financials === 0 || pCompany?.progress?.screening === 0) &&
          !pCompany?.progress?.isLocked;

        const isDoneAndNull =
          pCompany?.progress?.financials === 100 &&
          pCompany?.progress?.screening === 100 &&
          pCompany?.scores?.[section]?.inProgress === null;

        if (!pCompany?.company) {
          return sum + percentage * pCompany?.estimateCompany?.scores?.[section]?.eligible;
        }

        return (
          sum +
          percentage *
            (isFullyInProgress
              ? 100
              : isDoneAndNull
                ? 0
                : (pCompany?.scores?.[section]?.eligible ?? 100))
        );
      }, 0);

      const inProgress = portfolioCompanies.reduce((sum, pCompany) => {
        const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);

        const percentage = investmentValue / total;
        if (!pCompany?.company) {
          return sum;
        }
        const isFullyInProgress =
          (pCompany?.progress?.financials === 0 || pCompany?.progress?.screening === 0) &&
          !pCompany?.progress?.isLocked;
        const isDoneAndNull =
          pCompany?.progress?.financials === 100 &&
          pCompany?.progress?.screening === 100 &&
          pCompany?.scores?.[section]?.inProgress === null;

        return (
          sum +
          percentage *
            (isFullyInProgress
              ? 100
              : isDoneAndNull
                ? 0
                : (pCompany?.scores?.[section]?.inProgress ?? 100))
        );
      }, 0);

      const enabling = portfolioCompanies.reduce((sum, pCompany) => {
        const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);
        const percentage = investmentValue / total;

        return sum + percentage * (pCompany?.scores?.[section]?.enabling ?? 0);
      }, 0);

      const transitional = portfolioCompanies.reduce((sum, pCompany) => {
        const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);

        const percentage = investmentValue / total;

        return sum + percentage * (pCompany?.scores?.[section]?.transitional ?? 0);
      }, 0);

      scores[section] = {
        total: 100,
        aligned,
        eligible,
        inProgress,
        enabling,
        transitional,
      };
      return scores;
    },
    {} as Record<ScoreSectionsEnum, TaxonomyScore>
  );

  return sectionScores;
};

export const aggregatePortfolioProgress = (
  portfolioCompanies: PortfolioCompany[],
  reportPeriod: ReportPeriods,
  cashAndSovereigns?: CashSovereignsOptions
) => {
  const total = getPortfolioTotal(portfolioCompanies, reportPeriod, cashAndSovereigns);

  const portfolioProgress = portfolioCompanies.reduce(
    (agg, pCompany) => {
      const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);

      const percentage = investmentValue / total;
      const currentProgress = pCompany.progress ?? {
        screening: 0,
        financials: 0,
      };
      return {
        financials: agg.financials + percentage * currentProgress.financials,
        screening: agg.screening + percentage * currentProgress.screening,
        isLocked: (agg.isLocked && pCompany.sharedAssessment?.isLocked) || false,
      };
    },
    { financials: 0, screening: 0, isLocked: true }
  );
  return portfolioProgress;
};

export const getAggregatePortfolioSubstantialContribution = (
  portfolioCompanies: PortfolioCompany[],
  reportPeriod: ReportPeriods,
  cashAndSovereigns?: CashSovereignsOptions
) => {
  const total = getPortfolioTotal(portfolioCompanies, reportPeriod, cashAndSovereigns);

  const isAllInProgress = portfolioCompanies.every(
    (c) => c.progress?.financials === 0 || c.progress?.screening === 0
  );

  if (isAllInProgress) {
    return Object.values(EnvironmentalObjectiveKeyEnum).reduce(
      (acc, currentObjective) => {
        acc[currentObjective] = {
          revenue: 0,
          capex: 0,
          opex: 0,
        };
        return acc;
      },
      {} as Record<EnvironmentalObjectiveKeyEnum, { revenue: number; opex: number; capex: number }>
    );
  }

  const proportionsPerObjective = Object.values(EnvironmentalObjectiveKeyEnum).reduce(
    (acc, currentObjective) => {
      const sectionScores = scoreSections.reduce(
        (scores, section) => {
          const weighedSubstantialContributionPercentage = portfolioCompanies.reduce(
            (sum, pCompany) => {
              const investmentValue = getInvestmentValue(pCompany.valueOfInvestments, reportPeriod);
              const percentage = investmentValue / total;

              return (
                sum +
                percentage *
                  (pCompany?.substantialContributionProportion?.[currentObjective]?.[section] ?? 0)
              );
            },
            0
          );

          scores[section] = weighedSubstantialContributionPercentage;
          return scores;
        },
        {} as Record<ScoreSectionsEnum, number>
      );

      acc[currentObjective] = sectionScores;
      return acc;
    },
    {} as Record<EnvironmentalObjectiveKeyEnum, { revenue: number; opex: number; capex: number }>
  );

  return proportionsPerObjective;
};
