import {
  BareFinancials,
  Financials,
  Financials_Set_Input_,
  ObjectiveKeyEnum,
  useCompanyAssessmentCachedResultsQueryQuery,
  useUpdateFinancialsByIdMutation,
} from 'models/index.ts';
import { useEffect, useState } from 'react';
import { useGetCurrentCAssessment } from 'utils/hooks/General.hooks.ts';
import { MAX_ALLOWED_NUMBER } from '../FinancialTables.hooks.ts';
import { useFinancials } from 'Features/Financials/Financials.hooks.ts';
import { useParams } from 'react-router-dom';

const validateFinancials = (
  newValue: number,
  section: string,
  financials: Financials
): {
  validationErrorMessage: string | undefined;
  validationValue: number;
} => {
  if (newValue < 0) {
    return {
      validationErrorMessage:
        'Please reach out to our customer success team in the chat if you wish to input negative numbers',
      validationValue: 0,
    };
  }
  if (section === 'capex' && newValue < financials?.adaptationCapex) {
    return {
      validationErrorMessage: `Value can't be less than adaptation capex - this value has been set to ${financials?.adaptationCapex}`,
      validationValue: financials?.adaptationCapex ?? 0,
    };
  }
  if (section === 'opex' && newValue < financials?.adaptationOpex) {
    return {
      validationErrorMessage: `Value can't be less than adaptation opex - this value has been set to ${financials?.adaptationOpex}`,
      validationValue: financials?.adaptationOpex ?? 0,
    };
  }
  if (section === 'adaptationCapex' && newValue > financials?.capex) {
    return {
      validationErrorMessage: `Value can't be greater than capex - this value has been set to ${financials?.capex}`,
      validationValue: financials?.capex ?? 0,
    };
  }
  if (section === 'adaptationOpex' && newValue > financials?.opex) {
    return {
      validationErrorMessage: `Value can't be greater than opex - this value has been set to ${financials?.opex}`,
      validationValue: financials?.opex ?? 0,
    };
  }
  if (newValue > MAX_ALLOWED_NUMBER) {
    return {
      validationErrorMessage: `Value can't be greater than ${MAX_ALLOWED_NUMBER}`,
      validationValue: MAX_ALLOWED_NUMBER,
    };
  }

  return { validationErrorMessage: undefined, validationValue: newValue };
};

function revalidateFinancials(
  section: string,
  financials: Financials,
  setErrorMessage: (errorMessage: string | undefined) => void
) {
  const { validationErrorMessage } = validateFinancials(
    financials?.[section as keyof BareFinancials],
    section,
    financials
  );
  setErrorMessage(validationErrorMessage);
}

const useActivityAdaptationFinancialsLock = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const isAdaptationSection = section.includes(ObjectiveKeyEnum.adaptation);

  const { data, loading } = useCompanyAssessmentCachedResultsQueryQuery({
    variables: {
      cAssessmentId,
    },
    skip: !isAdaptationSection || (isAdaptationSection && !cAssessmentId),
    nextFetchPolicy: 'cache-only',
  });

  const activityResults = data?.companyResults?.businessUnitResults
    ?.flatMap((buResults) => buResults.activityResults)
    .find((actResults) => actResults?.financials?.id === financialsId);

  if (isAdaptationSection && !!activityResults) {
    const objectivesState = activityResults.cachedResult?.objectivesState;
    if (
      !objectivesState?.significantHarmObjectives?.includes(ObjectiveKeyEnum.adaptation) &&
      objectivesState?.substantialContributionObjectives?.includes(ObjectiveKeyEnum.adaptation)
    ) {
      return { adaptationLock: false, loading };
    } else {
      return { adaptationLock: true, loading };
    }
  } else {
    return { adaptationLock: false, loading: false };
  }
};

const useFinancialsById = ({ financialsId }: { financialsId: string }) => {
  const { financials: allFinancials } = useFinancials({ cacheOnly: true });

  if (allFinancials.comparisonTotalFinancials?.id === financialsId) {
    return allFinancials.comparisonTotalFinancials;
  }

  if (allFinancials.notEligibleFinancials?.id === financialsId) {
    return allFinancials.notEligibleFinancials;
  }

  const businessUnitFinancials = allFinancials?.businessUnits?.find(
    (bu) => bu.financials?.id === financialsId
  )?.financials;

  if (businessUnitFinancials) {
    return businessUnitFinancials;
  }

  const activityFinancials = allFinancials.businessUnits
    ?.flatMap((bu) => bu.activities || [])
    .find((act) => act.financials?.id === financialsId)?.financials;

  if (activityFinancials) {
    return activityFinancials;
  }

  return null;
};

export const useGetFinancialInputData = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const { isLocked, loading: cAssessmentLoading } = useGetCurrentCAssessment();
  const [updateFinancials] = useUpdateFinancialsByIdMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const financials = useFinancialsById({ financialsId });

  const { adaptationLock, loading: adaptationLockLoading } = useActivityAdaptationFinancialsLock({
    section,
    financialsId,
  });

  useEffect(() => {
    if (errorMessage !== undefined && !!financials) {
      revalidateFinancials(section, financials, setErrorMessage);
    }
  }, [financials]);

  const updateFinancialsById = async (newValue: number) => {
    const isValueChanged = newValue !== financials?.[section as keyof BareFinancials];
    if (!!financials && isValueChanged) {
      const { validationErrorMessage, validationValue } = validateFinancials(
        newValue,
        section,
        financials
      );
      const set: Financials_Set_Input_ = { [section]: validationValue };

      setLoading(true);
      await updateFinancials({
        onError: () => {
          setLoading(false);
          setErrorMessage('Failed to update financials');
        },
        onCompleted: () => {
          setLoading(false);
          if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
          } else {
            setErrorMessage(undefined);
          }
        },
        variables: {
          id: financialsId,
          _set: set,
        },
      });
    }
  };

  const value: number = financials?.[section as keyof BareFinancials] ?? '';

  const isDisabled = !financials?.isEstimate;

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  return {
    value,
    isLocked,
    isAdaptationLocked: adaptationLock,
    isDisabled,
    errorMessage,
    onChange: updateFinancialsById,
    updateFinancialsLoading: loading,
    loading: adaptationLockLoading || cAssessmentLoading,
    resetErrorMessage,
  };
};
