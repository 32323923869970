import React, { useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Tooltip, NumberInput, NumberInputProps } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { ReportPeriods } from './PortfolioView';
import { DangerIcon } from 'Tokens/Icons/Status';
import { Typography } from 'Tokens';
import { useHandleInvestmentBlur, useInvestmentValidation } from './PortfolioFinancials.hooks';

export const InvestmentInput = React.memo(
  (
    props: NumberInputProps & {
      reportPeriod: ReportPeriods;
      valueOfInvestments: Record<string, number>;
      maxValue: number;
      isAuditor?: boolean;
      isQuarter?: boolean;
    }
  ) => {
    const [localValue, setLocalValue] = useState<number>(props.value ? Number(props.value) : 0);
    const { t } = useTranslation('portfolio');

    const { error } = useInvestmentValidation({
      value: localValue,
      maxValue: props.maxValue,
      isQuarter: props.isQuarter,
    });

    const handleBlur = useHandleInvestmentBlur();

    return (
      <Tooltip
        label={
          !props.isAuditor && !props.isQuarter
            ? t('portfolio:investmentValue.editTooltipQuarter')
            : ''
        }
      >
        <Box display="flex" flexDirection="column">
          <NumberInput
            isInvalid={!!error}
            {...props}
            value={localValue}
            onChange={(value) => setLocalValue(isNaN(value) ? 0 : Number(value))}
            format={(value) => value.toLocaleString('en', { useGrouping: true })}
            onBlur={() =>
              handleBlur(localValue, {
                id: props.id,
                value: props.value ? Number(props.value) : undefined,
                reportPeriod: props.reportPeriod,
                valueOfInvestments: {
                  q1: props.valueOfInvestments.q1 ?? 0,
                  q2: props.valueOfInvestments.q2 ?? 0,
                  q3: props.valueOfInvestments.q3 ?? 0,
                  q4: props.valueOfInvestments.q4 ?? 0,
                  [props.reportPeriod]: localValue,
                },
              })
            }
          />
          {error && (
            <HStack mt="4px" gap="4px" alignItems="flex-start">
              <DangerIcon color="text.critical" boxSize="10px" mt="2px" />
              <Typography color="text.critical" variant="detail">
                {error}
              </Typography>
            </HStack>
          )}
        </Box>
      </Tooltip>
    );
  }
);
