import { Th, HStack, VStack } from '@chakra-ui/react';
import { HelpTooltip } from 'Molecules/index.ts';
import { Typography } from 'Tokens/index.ts';
import { useFinancialsHelp } from '../FinancialTables.hooks.ts';

export const FinancialTableColumnHeader = ({
  title,
  helpLabel,
  caption,
}: {
  title: string;
  helpLabel?: string;
  caption?: string;
}) => {
  const onFinancialsHelpClicked = useFinancialsHelp();

  return (
    <Th key={title} padding="8px" letterSpacing="normal">
      <HStack spacing="8px" justifyContent={caption ? 'flex-end' : 'flex-start'}>
        <VStack alignItems={caption ? 'flex-end' : 'flex-start'} spacing="2px">
          <Typography variant="detailStrong" textTransform="capitalize" noOfLines={2}>
            {title}
          </Typography>
          {caption && <Typography variant="micro">{caption}</Typography>}
        </VStack>
        {helpLabel && (
          <VStack justifyContent={'center'}>
            <HelpTooltip label={helpLabel} onButtonClick={() => onFinancialsHelpClicked(title)} />
          </VStack>
        )}
      </HStack>
    </Th>
  );
};
