import {
  IconTargetOff,
  IconAlignJustified,
  IconBook,
  IconBuilding,
  IconClipboardData,
  IconBrandStackshare,
  IconListDetails,
  IconCalculator,
  IconCalendar,
  IconChartDonut,
  IconFile,
  IconFileCheck,
  IconFileInvoice,
  IconFolder,
  IconFolders,
  IconLayoutGrid,
  IconUser,
  IconUsers,
  IconApps,
  IconWorld,
  IconBriefcase,
  IconHierarchy3,
  IconTargetArrow,
  IconBuildingSkyscraper,
  IconChartHistogram,
  IconTable,
  IconHash,
  IconSum,
  IconMessages,
  IconLink,
  IconPencil,
  IconMinus,
  IconCash,
  IconBuildingBank,
  IconBuildingPlus
} from '@tabler/icons-react';
import { tablerIcon } from './Icons';

export const DocumentIcon = tablerIcon(IconFile);
export const DocumentCheckmarkIcon = tablerIcon(IconFileCheck);
export const FinancesIcon = tablerIcon(IconFileInvoice);
export const ResourceIcon = tablerIcon(IconBook);

export const FolderIcon = tablerIcon(IconFolder);
export const DriveIcon = tablerIcon(IconFolders);
export const IntegrationsIcon = tablerIcon(IconApps);
export const PortfolioIcon = tablerIcon(IconClipboardData);
export const DescriptionIcon = tablerIcon(IconAlignJustified);
export const GroupIcon = tablerIcon(IconHierarchy3);

export const CompanyIcon = tablerIcon(IconBuilding);
export const ParentCompanyIcon = tablerIcon(IconBuildingSkyscraper);
export const BusinessUnitsIcon = tablerIcon(IconBrandStackshare);
export const ListIcon = tablerIcon(IconListDetails);
export const WorldIcon = tablerIcon(IconWorld);

export const DonutChartIcon = tablerIcon(IconChartDonut);
export const CalculatorIcon = tablerIcon(IconCalculator);
export const TeamMembersIcon = tablerIcon(IconUsers);
export const FundIcon = tablerIcon(IconBriefcase);
export const CashIcon = tablerIcon(IconCash);
export const SovereignsIcon = tablerIcon(IconBuildingBank);
export const AddCompanyIcon = tablerIcon(IconBuildingPlus);
export const ForumIcon = tablerIcon(IconMessages);
export const ESGPortalIcon = tablerIcon(IconWorld);

export const CalendarIcon = tablerIcon(IconCalendar);
export const DashboardIcon = tablerIcon(IconLayoutGrid);
export const UserIcon = tablerIcon(IconUser);
export const TargetIcon = tablerIcon(IconTargetArrow);
export const TargetOffIcon = tablerIcon(IconTargetOff);
export const HistogramIcon = tablerIcon(IconChartHistogram);
export const TableIcon = tablerIcon(IconTable);
export const HashIcon = tablerIcon(IconHash);
export const SumIcon = tablerIcon(IconSum);
export const LinkedIcon = tablerIcon(IconLink);
export const EditIcon = tablerIcon(IconPencil);
export const Hierarchy3Icon = tablerIcon(IconHierarchy3);
export const ListDetailsIcon = tablerIcon(IconListDetails);
export const ChartHistogramIcon = tablerIcon(IconChartHistogram);
export const financeIcon = tablerIcon(IconFileInvoice);
export const MinusIcon = tablerIcon(IconMinus);
