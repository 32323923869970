import {
  useUpdatePortfolioCompanyMarketValue,
  useUploadPortfolioCompaniesFinancials,
} from 'containers/Portfolios/Portfolios.hooks';
import { ReportPeriods } from 'Features/PortfolioView';
import { PortfoliosQuery_, ValueOfInvestments } from 'models';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { captureException } from '@sentry/browser';
import { useToast } from 'utils/hooks';

export const useInvestmentValidation = ({
  value,
  maxValue,
  isYear,
  isQuarter,
}: {
  value: number;
  maxValue: number;
  isYear?: boolean;
  isQuarter?: boolean;
}) => {
  const { t } = useTranslation('portfolio');

  const error =
    isQuarter && value > maxValue
      ? t('portfolio:investmentValue.notice')
      : isYear && value < maxValue
        ? t('portfolio:fields.marketValue.notice')
        : '';

  return { error };
};

export const useHandleInvestmentBlur = () => {
  const { portfolioId } = useParams();
  const toast = useToast();
  const { t } = useTranslation('portfolio');
  const upsertPortfolioCompaniesFinancials = useUploadPortfolioCompaniesFinancials();

  const handleBlur = (
    localValue: number,
    props: {
      id?: string;
      value?: number;
      reportPeriod: ReportPeriods;
      valueOfInvestments: ValueOfInvestments;
    }
  ) => {
    if (localValue !== props.value) {
      try {
        upsertPortfolioCompaniesFinancials([
          {
            id: props.id ?? '',
            portfolioId: portfolioId ?? '',
            valueOfInvestments: {
              q1: props.valueOfInvestments.q1 ?? 0,
              q2: props.valueOfInvestments.q2 ?? 0,
              q3: props.valueOfInvestments.q3 ?? 0,
              q4: props.valueOfInvestments.q4 ?? 0,
              [props.reportPeriod]: localValue,
            },
          },
        ]);
        toast({ text: `${t('portfolio:toasts.success')}` });
      } catch (error) {
        toast({ text: `${t('portfolio:toasts.fail')}`, variant: 'danger' });
        captureException(error, { extra: { errorMessage: 'Error saving investment value' } });
      }
    }
  };

  return handleBlur;
};

export const useHandleMarketBlur = () => {
  const toast = useToast();
  const { t } = useTranslation('portfolio');
  const updateMarketValue = useUpdatePortfolioCompanyMarketValue();

  const handleBlur = (localValue: number, props: { id?: string; value?: number }) => {
    if (localValue !== props.value) {
      try {
        updateMarketValue(props.id ?? '', localValue);
        toast({ text: `${t('portfolio:toasts.success')}` });
      } catch (error) {
        toast({ text: `${t('portfolio:toasts.fail')}`, variant: 'danger' });
        captureException(error, { extra: { errorMessage: 'Error saving market value' } });
      }
    }
  };

  return handleBlur;
};

export const useInvalidFinancialCompanies = (
  portfolio: Partial<PortfoliosQuery_['portfolios'][number]> | undefined,
  reportPeriod: ReportPeriods
) => {
  if (!portfolio?.portfolioCompanies) return [];

  return portfolio.portfolioCompanies.filter((portfolioCompany) => {
    const marketValue = portfolioCompany.marketValue ?? 0;

    if (reportPeriod !== ReportPeriods.year) {
      const quarterValue = portfolioCompany.valueOfInvestments?.[reportPeriod] ?? 0;
      return quarterValue > marketValue;
    } else {
      const q1 = portfolioCompany.valueOfInvestments?.q1 ?? 0;
      const q2 = portfolioCompany.valueOfInvestments?.q2 ?? 0;
      const q3 = portfolioCompany.valueOfInvestments?.q3 ?? 0;
      const q4 = portfolioCompany.valueOfInvestments?.q4 ?? 0;
      const largestQuarter = Math.max(q1, q2, q3, q4);
      return marketValue < largestQuarter;
    }
  });
};
