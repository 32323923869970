import { NhostClient, NhostReactClientConstructorParams } from '@nhost/react';
import axios from 'axios';

const subdomain = import.meta.env?.REACT_APP_NHOST_SUBDOMAIN;
const region = import.meta.env?.REACT_APP_NHOST_REGION;

const authUrl = import.meta.env?.REACT_APP_NHOST_AUTH_URL;
const hasuraUrl = import.meta.env?.REACT_APP_NHOST_HASURA_URL;
const functionsUrl = import.meta.env?.REACT_APP_NHOST_FUNCTIONS_URL;

const nhostClientConfig: NhostReactClientConstructorParams =
  authUrl && hasuraUrl && functionsUrl
    ? {
        authUrl: `${authUrl}/v1`,
        graphqlUrl: `${hasuraUrl}/v1/graphql`,
        // functionsUrl: `${functionsUrl}/v1`, temporary fix for functions as DNS is not working
        functionsUrl: `https://${subdomain}.functions.${region}.nhost.run/v1`,
        storageUrl: `https://${subdomain}.storage.${region}.nhost.run/v1`,
      }
    : {
        subdomain,
        region,
      };

export const nhost = new NhostClient(nhostClientConfig);

export const setUpNhostSessionManagement = () => {
  nhost.auth.onTokenChanged(async (session) => {
    let refreshTokenId = session?.refreshTokenId;

    if (!refreshTokenId) {
      const tokenRes = await axios.post(`${nhost.auth.url}/token`, {
        refreshToken: session?.refreshToken,
      });

      refreshTokenId = tokenRes.data.refreshTokenId;
    }

    await nhost.functions.call('validate-session', {
      tokenId: refreshTokenId,
    }); // Todo: Handle errors
  });
};
