import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  isFullMetricOnBULevel,
  isFullMetricOnSubLevel,
  isFullMetricQuarterlyLevel,
} from '../../../AggregatedMetrics';

export const checkProgress = (
  row: AggregatedMetricsTableData,
  answersData?: AggregatedQualitativeAnswers,
  companyReportingUnit?: string
) => {
  const isAggregated = !!row?.subRows?.length;
  const isSubsidiaries = isFullMetricOnSubLevel(row.metric);
  const isBusinessUnits = isFullMetricOnBULevel(row.metric);
  const isYearly = !isFullMetricQuarterlyLevel(row.metric);

  const numberOfBUs =
    answersData?.find((data) => data.metricRef === row.metric?.reference)?.reportingUnits?.length ??
    1 - 1;

  const numberOfAnsweredBUs =
    answersData
      ?.find((data) => data.metricRef === row.metric?.reference)
      ?.reportingUnits?.filter((bu) => !!bu.answer).length ?? 1 - 1;

  const numberOfSubs =
    answersData?.find((data) => data.metricRef === row.metric?.reference)?.subsidiaries?.length ??
    0;

  const numberOfAnsweredSubs =
    answersData
      ?.find((data) => data.metricRef === row.metric?.reference)
      ?.subsidiaries?.filter((sub) => !!sub.answer || sub.reportingUnits?.some((ru) => !!ru.answer))
      .length ?? 0;

  const dataPointsPerQuarter = Object.entries(row.result ?? {})
    .filter(([key]) => key !== 'Year')
    .map(([key, value]) => ({
      field: key.toUpperCase(),
      value: value,
    }));

  if (isAggregated) {
    let total = 0;
    if (!!row.subRows) {
      // This should be fixed asap!!
      row.subRows.forEach((subRow) => {
        total += checkProgress(subRow, answersData, companyReportingUnit);
      });
      return Math.floor(total / row.subRows.length);
    }
  }

  if (isBusinessUnits) {
    if (isYearly) {
      if (!isNaN(row.result?.Year ?? NaN))
        return Math.floor((numberOfAnsweredBUs / numberOfBUs) * 100);
      else return 0;
    }
  }

  if (isSubsidiaries) {
    if (isYearly) {
      if (!isNaN(row.result?.Year ?? NaN))
        return Math.floor((numberOfAnsweredSubs / numberOfSubs) * 100);
      else return 0;
    }
  }
  const isAssessedYearly = row.result?.Year ? true : false;
  const assessedQuarters = isAggregated
    ? []
    : dataPointsPerQuarter.filter((item) => item.value !== 0);
  return isYearly ? (isAssessedYearly ? 100 : 0) : Math.floor((assessedQuarters.length * 100) / 4);
};
