import { useCallback, useMemo } from 'react';
import {
  ShortUser,
  useUpsertDatapointMutation,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  Esrs_DatapointTag_Constraint_,
  GetEsrsMetricsDatapointsDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  GetSingleEsrsMetricAnswerDocument_,
  GetMetricAnswersDocument_,
  EsrsAssessmentAnswersAggregateDocument_,
  Esrs_DatapointMdrm_Constraint_,
  Esrs_DatapointMdrm_Update_Column_,
  useUpsertDatapointMdrmMutation,
  useGetDatapointMdrmAnswersQuery,
  GetDatapointMdrmAnswersDocument_,
  GetCompanyLevelMdrmAnswersDocument_,
} from 'models';
import { useUserData } from '@nhost/react';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { useParams } from 'react-router-dom';
import { MdrmAnswers, MdrmTypeEnum, OnMdrmAnswerChangeType, MdrmType } from './MDRM.types';

export const useMdrmAnswers = ({
  datapointId,
  metricRef,
  row,
  companyReportingUnitId,
  timeframe,
}: {
  datapointId: string;
  metricRef: string;
  row: MetricsTableData;
  companyReportingUnitId?: string;
  timeframe?: TimePeriods;
}) => {
  const { esrsAssessmentId = '', reportingUnitId = '' } = useParams();

  const [upsertDatapointMdrm] = useUpsertDatapointMdrmMutation();

  const [upsertDatapoint] = useUpsertDatapointMutation();

  const user: ShortUser | null = useUserData();

  const isEntitySpecific = useMemo(
    () =>
      row.metric.additionalTypes.some((type) => {
        return type.additionalType.reference === 'Entity-specific';
      }),
    [row.metric.additionalTypes]
  );

  const { data: datapointMdrmData, loading: mdrmLoading } = useGetDatapointMdrmAnswersQuery({
    variables: {
      datapointId,
    },
    skip: !datapointId,
  });

  const answers: MdrmAnswers = useMemo(() => {
    const mdrmAnswers = datapointMdrmData?.esrs_DatapointMdrm;
    return {
      methodology: mdrmAnswers?.find((mdrm) => mdrm.mdrmRef === MdrmTypeEnum.methodology),
      metricUsed: mdrmAnswers?.find((mdrm) => mdrm.mdrmRef === MdrmTypeEnum.metricUsed),
      validation: mdrmAnswers?.find((mdrm) => mdrm.mdrmRef === MdrmTypeEnum.validation),
      entitySpecific: mdrmAnswers?.find((mdrm) => mdrm.mdrmRef === MdrmTypeEnum.entitySpecific),
    };
  }, [datapointMdrmData]);

  const allMdrmAnswered = useMemo(() => {
    return Object.entries(answers).every(([key, answer]) => {
      if (!isEntitySpecific && key === MdrmTypeEnum.entitySpecific) return true;
      return answer?.value !== null && answer?.value !== '';
    });
  }, [answers, isEntitySpecific]);

  const upsertEmptyDatapoint = useCallback(async () => {
    await upsertDatapoint({
      variables: {
        objects: {
          value: null,
          timeframe: timeframe ?? TimePeriodsEnums.year,
          authorId: user?.id,
          source: null,
          datapointTags: {
            data: row?.tags ?? [],
            on_conflict: {
              constraint: Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
            },
          },
          datapointMdrms: {
            data: [
              {
                mdrmRef: MdrmTypeEnum.methodology,
                value: null,
              },
              {
                mdrmRef: MdrmTypeEnum.metricUsed,
                value: null,
              },
              {
                mdrmRef: MdrmTypeEnum.validation,
                value: null,
              },
              ...(isEntitySpecific
                ? [
                    {
                      mdrmRef: MdrmTypeEnum.entitySpecific,
                      value: null,
                    },
                  ]
                : []),
            ],
            on_conflict: {
              constraint: Esrs_DatapointMdrm_Constraint_.DatapointMdrmDatapointIdMdrmRefKey_,
            },
          },
          answer: {
            data: {
              metricRef: metricRef,
              reportingUnitId: companyReportingUnitId ? companyReportingUnitId : reportingUnitId,
              assessmentId: esrsAssessmentId,
              attachmentBox: {
                data: {},
                on_conflict: {
                  constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                  update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                },
              },
              noteHistory: {
                data: {},
                on_conflict: {
                  constraint: NoteHistory_Constraint_.NoteHistoryMetricAnswerIdKey_,
                  update_columns: [NoteHistory_Update_Column_.MetricAnswerId_],
                },
              },
              optOutAuthorId: user?.id,
            },
            on_conflict: {
              constraint: Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
              update_columns: [
                Esrs_Answer_Update_Column_.HasOptedOut_,
                Esrs_Answer_Update_Column_.OptOutReason_,
                Esrs_Answer_Update_Column_.OptOutAuthorId_,
              ],
            },
          },
        },
      },
      refetchQueries: [
        GetEsrsMetricsDatapointsDocument_,
        GetSingleEsrsMetricAnswerDocument_,
        GetDatapointMdrmAnswersDocument_,
        GetCompanyLevelMdrmAnswersDocument_,
        EsrsAssessmentAnswersAggregateDocument_,
      ],
    });
  }, [upsertDatapoint, timeframe, row, companyReportingUnitId, esrsAssessmentId, user, metricRef]);

  const onMdrmAnswerChange: OnMdrmAnswerChangeType = useCallback(
    (value: string, mdrmId: MdrmType) => {
      if (!datapointId)
        return upsertDatapoint({
          variables: {
            objects: {
              value: null,
              timeframe: timeframe ?? TimePeriodsEnums.year,
              authorId: user?.id,
              source: null,
              datapointTags: {
                data: row?.tags ?? [],
                on_conflict: {
                  constraint:
                    Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
                },
              },
              answer: {
                data: {
                  metricRef: row.metric.reference,
                  reportingUnitId: companyReportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  noteHistory: {
                    data: {},
                    on_conflict: {
                      constraint: NoteHistory_Constraint_.NoteHistoryMetricAnswerIdKey_,
                      update_columns: [NoteHistory_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
              datapointMdrms: {
                data: [
                  {
                    mdrmRef: mdrmId,
                    value: value ?? '',
                    authorId: user?.id,
                  },
                ],
                on_conflict: {
                  constraint: Esrs_DatapointMdrm_Constraint_.DatapointMdrmDatapointIdMdrmRefKey_,
                  update_columns: [
                    Esrs_DatapointMdrm_Update_Column_.UpdatedAt_,
                    Esrs_DatapointMdrm_Update_Column_.HasOptedOut_,
                    Esrs_DatapointMdrm_Update_Column_.Value_,
                  ],
                },
              },
            },
          },
          refetchQueries: [
            GetEsrsMetricsDatapointsDocument_,
            GetSingleEsrsMetricAnswerDocument_,
            GetDatapointMdrmAnswersDocument_,
            GetCompanyLevelMdrmAnswersDocument_,
            EsrsAssessmentAnswersAggregateDocument_,
          ],
        });

      upsertDatapointMdrm({
        variables: {
          object: {
            datapointId: datapointId,
            mdrmRef: mdrmId,
            value: value ?? '',
            authorId: user?.id,
          },
        },
        refetchQueries: [
          GetDatapointMdrmAnswersDocument_,
          GetCompanyLevelMdrmAnswersDocument_,
          GetMetricAnswersDocument_,
        ],
      });
    },
    [upsertDatapointMdrm, answers, datapointId, metricRef, user?.id, upsertDatapoint]
  );

  return {
    answers,
    loading: mdrmLoading,
    onMdrmAnswerChange,
    allMdrmAnswered,
    upsertEmptyDatapoint,
  };
};
