import { useUserData } from '@nhost/react';
import { ReportPeriods } from 'Features/PortfolioView';
import { keyBy, uniqBy } from 'lodash';
import {
  PaiCompanyReport,
  PaiCompanyReportsDocument_,
  PaiIndicator,
  PaiReportRequestsDocument_,
  PaiRequest,
  usePaiCompanyReportsQuery,
  usePaiReportRequestsQuery,
  useUpsertPaiAnswerMutation,
  useUpsertPaiCompanyReportMutation,
  useAllPaiReportAnswersSubscription,
  AllPaiReportAnswersSubscription_,
  PaiAnswers_Insert_Input_,
  useGetUserRoleInCompanyQuery,
  UserRole_Enum_,
} from 'models';
import { useState } from 'react';
import { useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

export type PaiCompanyRequest = {
  year: number;
  requests: PaiRequest[];
  period: ReportPeriods;
  report?:
    | undefined
    | (PaiCompanyReport & {
        answers: AllPaiReportAnswersSubscription_['reportAnswers'][number]['answers'];
        lastEditedAnswer: { updatedAt?: string }[];
      });
};

export const usePaiRequests = (companyId?: string) => {
  const {
    data: requestsData,
    loading: RequestsLoading,
    error: requestsError,
    ...rest
  } = usePaiReportRequestsQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const {
    data: reportsData,
    loading: CompanyReportLoading,
    error: reportsError,
  } = usePaiCompanyReportsQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const {
    data: answersData,
    loading: ReportAnswersLoading,
    error: answersError,
  } = useAllPaiReportAnswersSubscription({
    variables: { companyId },
    skip: !companyId,
  });

  // Filter on PAI requests that have a year where the company was IN the portfolio
  const allRequestsWhereCompanyIsPresentInYear =
    requestsData?.paiReportRequests?.filter((req) => {
      return !!req.portfolio?.companyMemberships?.find((m) => m.year === req.year);
    }) ?? [];

  // Filter on PAI requests where there is a selected indicator that matches the company's ID
  const allRequestsWhereCompanyIsSelected =
    allRequestsWhereCompanyIsPresentInYear.filter((req) => {
      return req.selectedIndicators?.find(
        (ind) =>
          ind.isForAllCompanies ||
          ind.paiReportIndicatorPortfolioCompanies?.find(
            (c) => c.portfolioCompany?.companyId === companyId
          )
      );
    }) ?? [];

  const yearlyReports = allRequestsWhereCompanyIsSelected
    .filter((req) => req.selectedIndicators?.find((ind) => !!ind.periods?.year))
    .map((x) => ({
      ...x,
      selectedIndicators: x.selectedIndicators?.filter((ind) => !!ind.periods?.year),
      period: ReportPeriods.year,
    }))
    .filter((yReports) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sendOutDate = yReports.sendOutDates?.[yReports.period]
        ? new Date(yReports.sendOutDates?.[yReports.period])
        : today;
      sendOutDate.setHours(0, 0, 0, 0);
      return sendOutDate <= today;
    });

  const quarterlyReports = allRequestsWhereCompanyIsSelected
    .filter((req) => req.selectedIndicators?.find((ind) => !!ind.periods?.q1))
    .map((req) => [
      {
        ...req,
        selectedIndicators: req.selectedIndicators?.filter((ind) => !!ind.periods?.q1),
        period: ReportPeriods.q1,
      },
      {
        ...req,
        selectedIndicators: req.selectedIndicators?.filter((ind) => !!ind.periods?.q2),
        period: ReportPeriods.q2,
      },
      {
        ...req,
        selectedIndicators: req.selectedIndicators?.filter((ind) => !!ind.periods?.q3),
        period: ReportPeriods.q3,
      },
      {
        ...req,
        selectedIndicators: req.selectedIndicators?.filter((ind) => !!ind.periods?.q4),
        period: ReportPeriods.q4,
      },
    ])
    .flat()
    .filter((qReports) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sendOutDate = new Date(qReports.sendOutDates[qReports.period.toUpperCase()]);
      sendOutDate.setHours(0, 0, 0, 0);
      return sendOutDate <= today;
    });

  const validRequests = [...yearlyReports, ...quarterlyReports];

  const byYearAndPeriod = validRequests.reduce(
    (acc, request) => {
      const year = request?.year ?? 2022;
      const period = request?.period ?? ReportPeriods.year;

      if (!acc[`${year}-${period}`]) {
        const correctReport = reportsData?.paiCompanyReports?.find(
          (report) => report.year === year && report.period === period
        );
        const correctAnswers =
          answersData?.reportAnswers?.find(
            (answerReport) => answerReport.year === year && answerReport.period === period
          )?.answers ?? [];

        acc[`${year}-${period}`] = {
          requests: [],
          report: correctReport ? { ...correctReport, answers: correctAnswers } : undefined,
        };
      }
      acc[`${year}-${period}`].requests.push(request);
      return acc;
    },
    {} as Record<string, { requests: PaiRequest[]; report?: PaiCompanyRequest['report'] }>
  );

  const uniqueRequests: PaiCompanyRequest[] = Object.entries(byYearAndPeriod ?? {}).map(
    ([yearAndPeriod, { requests, report }]) => ({
      year: Number(yearAndPeriod.split('-')[0]),
      period: yearAndPeriod.split('-')[1] as ReportPeriods,
      requests,
      report,
    })
  );

  return {
    requests: uniqueRequests ?? [],
    loading: RequestsLoading || CompanyReportLoading || ReportAnswersLoading,
    error: requestsError || reportsError || answersError,
    ...rest,
  };
};

export type PaiCompanyReportWithMetrics = PaiCompanyRequest['report'] & {
  indicators: Array<
    PaiIndicator & {
      isNew: boolean;
      portfoliosRequesting: PaiRequest['portfolio'][];
    }
  >;
  portfoliosRequesting: Array<
    PaiRequest['portfolio'] & {
      id: string;
      dueDates: any;
      sendOutDates: any;
    }
  >;
  updatedAt: Date;
};

export const useCompanyPaiReport = (year: number, period: ReportPeriods, companyId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [upsertPaiReport] = useUpsertPaiCompanyReportMutation();
  const { requests, loading: requestsLoading, error: requestsError } = usePaiRequests(companyId);

  const currentReport = requests.find(
    (request) => request.year === year && request.period === period
  );

  let report: PaiCompanyReportWithMetrics | undefined;

  if (currentReport?.report) {
    report = {
      ...currentReport.report,
      indicators: uniqBy(
        currentReport.requests
          .map((request) => request.selectedIndicators.map((s) => s.indicator))
          .flat(),
        'reference'
      ).map((indicator) => ({
        ...indicator,
        isNew: currentReport.requests
          .map((request) => request.selectedIndicators)
          .flat()
          .filter((selected) => selected.indicator.reference === indicator.reference)
          .reduce((acc, s) => acc && s.isNew, true),
        portfoliosRequesting:
          currentReport.requests
            .filter((req) =>
              req.selectedIndicators.find((s) => s.indicator.reference === indicator.reference)
            )
            .map((req) => req.portfolio) ?? [],
      })),
      portfoliosRequesting: currentReport.requests.map((request) => ({
        ...request.portfolio,
        id: request.portfolio?.id ?? '',
        dueDates: request.dueDates,
        sendOutDates: request.sendOutDates,
      })),
      updatedAt: new Date(
        Math.max(...currentReport.requests.map((request) => Number(new Date(request.updatedAt))))
      ),
    };
  } else if (!isLoading && companyId) {
    setIsLoading(true);
    upsertPaiReport({
      variables: {
        input: {
          companyId,
          year,
          period,
        },
      },
      refetchQueries: [PaiReportRequestsDocument_, PaiCompanyReportsDocument_],
    })
      .then(() => setIsLoading(false))
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }

  return {
    report,
    isLoading: isLoading || requestsLoading,
    error: error || requestsError,
  };
};

export const usePaiAnswer = (companyId: string, paiReportId: string, investeeMetricRef: string) => {
  const { t } = useTranslation('question');
  const { requests, loading, error: requestsError } = usePaiRequests(companyId);
  const [error, setError] = useState<Error | null>(null);

  const toast = useToast();
  const [upsertPaiAnswer] = useUpsertPaiAnswerMutation();

  const report = requests.find((req) => req.report?.id === paiReportId);
  const byAnswerKey = keyBy(report?.report?.answers, 'investeeMetricReference');
  const answer = byAnswerKey[investeeMetricRef];

  const onAnswerChange = async (newValue?: string) => {
    const input: PaiAnswers_Insert_Input_ = {
      id: answer?.id ?? undefined,
      paiCompanyReportId: paiReportId,
      investeeMetricReference: investeeMetricRef,
      data: newValue,
    };

    if (!answer?.id) {
      input.noteHistory = {
        data: {},
      };
      input.attachmentBox = {
        data: {},
      };
    }

    try {
      await upsertPaiAnswer({
        variables: { input },
      });
      toast({
        text: t('saved'),
        destroyAll: true,
      });
    } catch (err) {
      setError(err as Error);
      toast({
        text: t('savingFailed'),
        variant: 'danger',
        destroyAll: true,
      });
    }
  };

  return {
    answer,
    onAnswerChange,
    loading,
    error: error || requestsError,
  };
};

export const useIsAuditor = (companyId?: string) => {
  const user = useUserData();

  const { data, loading } = useGetUserRoleInCompanyQuery({
    variables: { companyId: companyId ?? '', userId: user?.id ?? '' },
    skip: !companyId || !user,
  });

  return {
    isAuditor: data?.roleInCompany?.role === UserRole_Enum_.Auditor_,
    loading,
  };
};

export const usePaiCategories = (report: PaiCompanyReportWithMetrics | undefined) => {
  const allCategories =
    report?.indicators.reduce(
      (aggregate, currentIndicator) => {
        if (aggregate[currentIndicator.category.title]) {
          aggregate[currentIndicator.category.title].push(currentIndicator);
        } else {
          aggregate[currentIndicator.category.title] = [currentIndicator];
        }
        return aggregate ?? {};
      },
      {} as Record<string, PaiCompanyReportWithMetrics['indicators']>
    ) ?? {};

  const categories = Object.entries(allCategories).map(([_, indicators]) => {
    const investeeMetrics = uniqBy(
      indicators
        .map((i) =>
          i.investorMetrics.map((im) => im.investorMetric.investeeMetrics.map((m) => m.metric))
        )
        .flat(3),
      'reference'
    );
    const answers =
      report?.answers.filter((a) =>
        investeeMetrics.some((m) => m.reference === a.metric.reference)
      ) ?? [];

    const isDone = investeeMetrics.every((metric) =>
      answers.some(
        (a) =>
          a.metric.reference === metric.reference &&
          a?.data !== null &&
          a?.data !== undefined &&
          a?.data !== ''
      )
    );

    return {
      category: indicators?.[0].category,
      indicators,
      investeeMetrics,
      answers,
      isDone,
    };
  });

  return categories;
};
