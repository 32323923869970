import {
  Box,
  Table as ChakraTable,
  HStack,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { HelpTooltip, LearnMoreDrawer, MetricLearnMoreHeader, Select } from 'Molecules';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { MetricNameWithTag } from 'containers/Esrs/pieces/DataCollection/DataCollectionUtils';
import { AdditionalTypesGroup } from 'Molecules/MetricTagsList';
import { IconButton, TruncatableText } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';
import { Dispatch, MutableRefObject, SetStateAction, useMemo, useRef, useState } from 'react';
import { GetDrMaterialMetricsQuery_, QuestionType_Enum_ } from 'models';
import { useTranslation } from 'utils/translation';
import {
  MappedDisclosureRequirements,
  MaterialityState,
  MaterialityStatus,
  MetricMaterialityState,
  ParentMaterialMetrics,
} from '../DoubleMaterialityAssessment.d';
import {
  COLLECT_ONLY_OPTIONS,
  MATERIALITY_LABELS,
  METRIC_MATERIALITY_OPTIONS,
} from './DrMaterialityModalUtils';
import {
  checkMateriality,
  handleMetric,
  isParentMaterial,
  isParentMaterialForSub,
  isParentMetricOnGroupLevel,
} from './DrMaterialityModal.hooks';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Metric } from '../../MaterialityAssessment.d';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { MaterialityStatusBox } from '../MaterialityUtils';

export const MetricsMaterialityTable = ({
  readOnly,
  disclosureRequirement,
  isGroup,
  hasParent,
  drMateriality,
  setMaterialMetrics,
  materialMetrics,
  parentMetrics,
  isCollectOnly,
}: {
  readOnly: boolean;
  disclosureRequirement?: MappedDisclosureRequirements[number];
  isGroup: boolean;
  hasParent: boolean;
  drMateriality: MaterialityState | null;
  setMaterialMetrics: Dispatch<SetStateAction<GetDrMaterialMetricsQuery_['esrs_MaterialMetric']>>;
  materialMetrics: GetDrMaterialMetricsQuery_['esrs_MaterialMetric'];
  parentMetrics: ParentMaterialMetrics;
  isCollectOnly: boolean;
}) => {
  const { t } = useTranslation('esrs');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const metricNameRef = useRef<HTMLDivElement>(null);
  const [selectedMetric, setSelectedMetric] = useState<Metric>();

  const [drMetrics, someMetricsHaveAdditionalTypes] = useMemo(
    () => [
      disclosureRequirement?.metrics.filter((metric) => metric.isAssessable === true),
      disclosureRequirement?.metrics.some((metric) => !!metric.additionalTypes.length),
    ],
    [disclosureRequirement]
  );

  const showGroup = useMemo(
    () => !readOnly && !isGroup && hasParent,
    [readOnly, isGroup, hasParent]
  );
  const isDRAssessed = useMemo(
    () => drMateriality !== MaterialityState.toAssess && drMateriality !== null,
    [drMateriality]
  );
  const selectedMetricFromDR = useMemo(() => {
    return disclosureRequirement?.metrics.find(
      (metric) => metric.reference === selectedMetric?.reference
    );
  }, [selectedMetric]);

  const selectOptions = useMemo(() => {
    if (isCollectOnly) {
      return COLLECT_ONLY_OPTIONS;
    }
    return METRIC_MATERIALITY_OPTIONS;
  }, [isCollectOnly]);

  const handleUpdateMateriality = (
    selection: MetricMaterialityState,
    metric: Metric,
    isChild?: boolean
  ) => {
    const isMaterial = checkMateriality(selection, isCollectOnly);
    setMaterialMetrics((prevMetrics) => {
      const foundMetric = prevMetrics.find((m) => m.metricRef === metric.reference);
      const isDataGatheringOnly = selection === MetricMaterialityState.gatherData;

      if (foundMetric) {
        if (isChild) {
          if (!metric.isAssessable) {
            const isAnyParentMaterial = metric.parentMetrics?.some(
              (parentMet) =>
                prevMetrics.find((m) => m.metricRef === parentMet.parentMetric.reference)
                  ?.isMaterial
            );
            if (isAnyParentMaterial && foundMetric.isMaterial && !foundMetric.isDataGatheringOnly) {
              return [...prevMetrics];
            }
            return prevMetrics.map((m) =>
              m.metricRef === metric.reference ? { ...m, isMaterial, isDataGatheringOnly } : m
            );
          }
          if (
            (foundMetric.isMaterial && !foundMetric.isDataGatheringOnly) ||
            selection === MetricMaterialityState.notMaterial
          ) {
            return [...prevMetrics];
          }
        }
        return prevMetrics.map((m) =>
          m.metricRef === metric.reference ? { ...m, isMaterial, isDataGatheringOnly } : m
        );
      } else {
        return [...prevMetrics, { metricRef: metric.reference, isMaterial, isDataGatheringOnly }];
      }
    });
    //handle children
    if (metric.childrenMetrics?.length > 0) {
      metric.childrenMetrics.forEach((child) => {
        if (child.childMetric)
          handleUpdateMateriality(selection, child.childMetric as Metric, true);
      });
    }
  };

  const filterOptions = (metric: Metric) => {
    if (isCollectOnly) {
      return selectOptions;
    }
    return selectOptions.filter((option) => {
      const isParentMaterialMetric = isParentMaterial(
        metric.reference,
        parentMetrics,
        disclosureRequirement?.parentMateriality
      );

      if (
        isParentMaterialMetric &&
        !isParentMetricOnGroupLevel(disclosureRequirement?.metrics ?? [], parentMetrics, metric)
      ) {
        return option.value !== MetricMaterialityState.notMaterial;
      }
      return true;
    });
  };

  return (
    <VStack pl="32px" ml="16px" borderColor="border.hover" pb="24px" alignItems="stretch" gap="8px">
      <ActiveStepLabels title={'Assess metrics'} hasSteps={false} ml="63px" clickable={false} />
      <Box width="100%" border="1px solid" borderColor="border.decorative" borderRadius="8px">
        <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
          <Thead borderBottom="1px solid" borderColor="border.decorative" borderRadius="8px">
            <Tr>
              <Th alignSelf="flex-start" p="12px" textTransform="none">
                <Typography variant="bodyStrong">Metric</Typography>
              </Th>

              {someMetricsHaveAdditionalTypes && (
                <Th w="184px" alignSelf="flex-start" p="12px" textTransform="none">
                  <Typography variant="bodyStrong">Information</Typography>
                </Th>
              )}

              <Th p="5px 8px" w="70px" textTransform="none">
                <Typography variant="bodyStrong">Ref.</Typography>
              </Th>

              {showGroup && (
                <Th p="12px" w="128px" textTransform="none">
                  <HStack spacing="2px">
                    <Typography variant="bodyStrong">Group</Typography>
                    <HelpTooltip
                      label={t('assessment.materialityAssessment.table.tooltip.group')}
                    />
                  </HStack>
                </Th>
              )}

              {!readOnly && (
                <Th p="12px" w="184px" textTransform="none">
                  <HStack spacing="2px">
                    <Typography variant="bodyStrong">
                      {isGroup ? 'Group materiality' : 'Your company'}
                    </Typography>
                    {!readOnly && (
                      <HelpTooltip
                        label={
                          isGroup || !hasParent
                            ? t('assessment.materialityAssessment.table.tooltip.company')
                            : t('assessment.materialityAssessment.table.tooltip.subsidiary')
                        }
                      />
                    )}
                  </HStack>
                </Th>
              )}
              <Th w="24px"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {drMetrics?.map((metric, index) => {
              const { materiality, additionalTypes, metricRefNumber } = handleMetric(
                metric,
                materialMetrics,
                isDRAssessed,
                isCollectOnly,
                parentMetrics
              );
              return (
                <Tr
                  key={index}
                  borderBottom={index + 1 < drMetrics.length ? '1px solid' : 'none'}
                  borderColor="border.decorative"
                >
                  <Td p="12px">
                    <HStack spacing="2px" justifyContent={'left'}>
                      <HStack flex={1}>
                        <MetricTypeIcon
                          type={
                            metric.metricType === QuestionType_Enum_.Decimal_
                              ? MetricTypes.number
                              : MetricTypes.text
                          }
                        />
                        <Box ref={metricNameRef} width="100%">
                          <MetricNameWithTag
                            name={metric.shortTitle ?? metric.title}
                            tags={metric.tags.map((tag) => tag.type)}
                            rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                  </Td>

                  {someMetricsHaveAdditionalTypes && (
                    <Td p="12px" w="128px">
                      <HStack spacing="2px">
                        <AdditionalTypesGroup tagsWithHelp={additionalTypes} />
                      </HStack>
                    </Td>
                  )}

                  <Td p="5px 8px">
                    <TruncatableText variant="body" text={metricRefNumber} />
                  </Td>

                  {showGroup && (
                    <Td p="12px" w="128px">
                      <HStack spacing="2px">
                        <Typography variant="body">
                          {parentMetrics?.some((m) => {
                            return (
                              m.metricRef === metric.reference &&
                              m.isMaterial &&
                              m.isDataGatheringOnly
                            );
                          })
                            ? 'Collect data'
                            : !parentMetrics.find((m) => m.metricRef === metric.reference)
                              ? MaterialityStatus.toAssess
                              : isParentMaterial(
                                    metric.reference,
                                    parentMetrics,
                                    disclosureRequirement?.parentMateriality
                                  )
                                ? MaterialityStatus.material
                                : MaterialityStatus.notMaterial}
                        </Typography>
                      </HStack>
                    </Td>
                  )}

                  {!readOnly && (
                    <Td p="5px 8px">
                      <Box w="100%">
                        {materiality !== MetricMaterialityState.collectDataMandatory ? (
                          <Select
                            size="sm"
                            isDisabled={
                              !isDRAssessed ||
                              (materiality === MetricMaterialityState.collectData &&
                                isParentMaterialForSub(
                                  metric.reference,
                                  parentMetrics,
                                  disclosureRequirement?.parentMateriality
                                ))
                            }
                            isClearable={true}
                            options={filterOptions(metric)}
                            isOptionDisabled={(option) => {
                              return !isCollectOnly
                                ? option.value === MetricMaterialityState.material &&
                                    drMateriality === MaterialityState.gatherData
                                : false;
                            }}
                            value={{
                              label: MATERIALITY_LABELS[materiality],
                              value: materiality as MetricMaterialityState,
                            }}
                            onChange={(selection) => {
                              if (selection?.value !== materiality) {
                                handleUpdateMateriality(
                                  selection?.value ?? MetricMaterialityState.toAssess,
                                  metric
                                );
                              }
                            }}
                            // deselect={handleDeselect}
                          />
                        ) : (
                          <MaterialityStatusBox
                            materiality={MaterialityState.collectDataMandatory}
                          />
                        )}
                      </Box>
                    </Td>
                  )}

                  <Td w="12px" p="10px 4px">
                    <IconButton
                      aria-label="learn more"
                      variant="ghost"
                      icon={<HelpIcon />}
                      size="md"
                      onClick={() => {
                        setSelectedMetric(metric);
                        onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
        <LearnMoreDrawer
          isOpen={isOpen}
          onClose={onClose}
          description={selectedMetric?.description ?? ''}
          header={!selectedMetric ? disclosureRequirement?.title : undefined}
          customHeader={
            selectedMetric ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetricFromDR?.tags ?? []}
              />
            ) : undefined
          }
        />
      </Box>
    </VStack>
  );
};
