import { Modal } from 'Molecules';

import { useDuplicateBusinessUnit } from 'Features/Screening/Screening.hooks';
import { useEffect, useMemo, useState } from 'react';
import { VStack, HStack, Collapse, Box } from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { useForm, useFormState } from 'react-hook-form';
import { useBusinessUnitQuery, useBusinessUnitsQuery } from 'models';
import { ReportingUnitController } from 'containers/BusinessUnits/pieces/ReportingUnitNameController';
import { useCurrentCompanyId } from 'utils/hooks';

export const DuplicateBusinessUnitModal = ({
  id,
  isOpen,
  onClose,
}: {
  id: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { data: businessUnitData } = useBusinessUnitQuery({
    variables: {
      id: id,
    },
    skip: !id,
  });

  const { companyId } = useCurrentCompanyId();
  const { data: businessUnitsData } = useBusinessUnitsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const businessUnits = useMemo(() => {
    return businessUnitsData?.businessunits;
  }, [businessUnitsData]);

  const duplicateBusinessUnit = useDuplicateBusinessUnit();

  const oldName = businessUnitData?.businessUnit?.name;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation(['assessment', 'common']);
  const [type, setType] = useState<'custom' | 'standard'>();
  const [options, setOptions] = useState<{
    withNotes: boolean;
    withAttachments: boolean;
    withAnswers: boolean;
  }>({
    withNotes: true,
    withAttachments: true,
    withAnswers: true,
  });

  const { reset, control, getValues } = useForm<{ name: string }>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      name: oldName ? `[Copy] ${oldName}` : '',
    },
  });
  const { isValid } = useFormState({ control });

  const onConfirm = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await duplicateBusinessUnit(id, getValues('name'), options);
    onClose();
  };

  const countCopies = (name: string): number => {
    const count = businessUnits?.filter((bu) => bu.name.endsWith(`[Copy] ${name}`)).length ?? 0;
    return count;
  };

  useEffect(() => {
    const oldNameSafe = oldName ?? '';
    const copyCount = countCopies(oldNameSafe);
    const name =
      copyCount !== null && copyCount !== undefined
        ? `${'[Copy]'.repeat(copyCount + 1)} ${oldNameSafe}`
        : '';
    reset({ name });
    setType('standard');
  }, [isOpen, onClose, businessUnitData, businessUnitsData]);

  return (
    <Modal
      title={t('assessment:duplicateAssessmentModal.modal.ruHeader')}
      onClose={onClose}
      isOpen={isOpen}
      hasFooter
      confirmText={t('common:button.confirm')}
      onConfirm={onConfirm}
      confirmButtonProps={{
        disabled: !isValid || isLoading,
        form: 'dupBuForm',
        isLoading: isLoading,
      }}
    >
      <VStack width="100%" alignItems="stretch">
        <Box marginBottom="8px">
          <form id="dupBuForm">
            <ReportingUnitController control={control} />
          </form>
        </Box>

        <Typography variant="bodyStrong">
          {t('assessment:duplicateAssessmentModal.modal.typography.optionsTitle')}
        </Typography>
        <VStack
          borderColor={type === 'standard' ? 'border.selected.accent' : 'border.default'}
          borderWidth={type === 'standard' ? '2px' : '1px'}
          padding="16px"
          borderRadius="8px"
          spacing="8px"
          cursor="pointer"
          _hover={{
            bg: 'bg.hover',
          }}
          onClick={() => setType('standard')}
          alignItems="flex-start"
        >
          <Typography variant="h3">
            {t('assessment:duplicateAssessmentModal.modal.typography.standard.title')}
          </Typography>
          <Typography variant="body">
            {t('assessment:duplicateAssessmentModal.modal.typography.standard.reportingUnitBody')}
          </Typography>
        </VStack>
        <VStack
          borderColor={type === 'custom' ? 'border.selected.accent' : 'border.default'}
          borderWidth={type === 'custom' ? '2px' : '1px'}
          padding="16px"
          borderRadius="8px"
          spacing="8px"
          cursor="pointer"
          _hover={{
            bg: 'bg.hover',
          }}
          onClick={() => setType('custom')}
          alignItems="flex-start"
        >
          <Typography variant="h3">
            {t('assessment:duplicateAssessmentModal.modal.typography.custom.title')}
          </Typography>
          <Typography variant="body">
            {t('assessment:duplicateAssessmentModal.modal.typography.custom.reportingUnitBody')}
          </Typography>
          <Collapse in={type === 'custom'}>
            <VStack spacing="12px" alignItems="flex-start">
              <HStack spacing="8px">
                <Checkbox
                  isChecked={options.withAnswers}
                  onChange={(e) =>
                    setOptions((prev) => ({ ...prev, withAnswers: e.target.checked }))
                  }
                />
                <Typography variant="body">{t('common:words.answers')}</Typography>
              </HStack>
              <HStack spacing="8px">
                <Checkbox
                  isChecked={options.withAttachments}
                  onChange={(e) =>
                    setOptions((prev) => ({ ...prev, withAttachments: e.target.checked }))
                  }
                />
                <Typography variant="body">{t('common:words.documents')}</Typography>
              </HStack>
              <HStack spacing="8px">
                <Checkbox
                  isChecked={options.withNotes}
                  onChange={(e) => setOptions((prev) => ({ ...prev, withNotes: e.target.checked }))}
                />
                <Typography variant="body">{t('common:words.notes')}</Typography>
              </HStack>
            </VStack>
          </Collapse>
        </VStack>
      </VStack>
    </Modal>
  );
};
