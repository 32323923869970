import { CalendarIcon } from '@chakra-ui/icons';
import {
  VStack,
  HStack,
  useColorMode,
  Popover,
  PopoverTrigger,
  Circle,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import { ProgressBar } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { QUARTERS_FIELDS } from 'containers/Esrs/pieces/DisclosureRequirements/Requirement';
import { useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  MetricAnswer,
  ReportingUnitType,
  SubsidiaryAssessmentsType,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  isFullMetricOnBULevel,
  isFullMetricOnSubLevel,
} from '../../../AggregatedMetrics';
import { DetailsCard } from '../../../MetricSidebar/MetricSource';
import {
  convertCurrency,
  getUniqueDataPoints,
  MetricsTableData,
} from '../../../MetricAnswers.hooks';
import { checkProgress } from './MetricProgress.hooks';
import { useParams } from 'react-router-dom';
import { GetAssessmentCurrencyConversionQuery_ } from 'models';
import { uniqBy } from 'lodash';

type SubsidiaryType = {
  subsidiary: SubsidiaryAssessmentsType;
  answer: MetricAnswer | undefined;
  isCompanyLevel?: boolean;
  reportingUnits?: {
    reportingUnit: ReportingUnitType;
    answer: MetricAnswer | undefined;
  }[];
};

export const getBusinessUnitAnswer = (
  row: AggregatedMetricsTableData,
  businessUnit: {
    reportingUnit: ReportingUnitType;
    answer: MetricAnswer | undefined;
  }
) => {
  if (!businessUnit.answer) return undefined;

  const materialTags = row.metric.materialMetrics[0].materialMetricTags.map(
    (tag) => tag.materialTagValues
  );

  const datapoints = businessUnit.answer?.datapoints.map((dp) => ({
    ...dp,
    tagValues: dp.datapointTags.map((dpTag) => dpTag.tagValue),
    metricRef: row.metric?.reference,
  }));
  const uniqueDatapoints = getUniqueDataPoints(datapoints);

  if (row.tagName) {
    return businessUnit.answer?.datapoints?.find((dp) =>
      dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName)
    )?.value;
  }

  const isCompanyLevel = businessUnit.reportingUnit.isCompanyLevel;

  if (isCompanyLevel) return businessUnit.answer?.datapoints?.[0]?.value;

  return String(
    uniqueDatapoints
      ?.filter((dp) =>
        !!dp.datapointTags.length
          ? dp.datapointTags.every(
              (dpTag: { tagType: string; tagValue: string }) =>
                materialTags.some((mt) =>
                  mt.some((mtv) => mtv.tagType === dpTag.tagType && mtv.tagValue === dpTag.tagValue)
                ) ||
                row.tags?.some(
                  (mt) => mt.tagType === dpTag.tagType && mt.tagValue === dpTag.tagValue
                )
            )
          : true
      )
      .reduce((acc, curr) => acc + Number(curr.value), 0)
  );
};

export const getSubsidiaryAnswer = (
  row: AggregatedMetricsTableData,
  sub: SubsidiaryType,
  standardRef: string,
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion']
) => {
  if (!sub.answer && !sub.reportingUnits?.filter((ru) => !!ru?.answer).length) return undefined;
  const subMaterialMetrics = sub?.subsidiary.materialStandards.find(
    (mS) => mS.standardRef === standardRef
  )?.materialMetrics;
  const subMaterialMetricTags =
    subMaterialMetrics?.find(
      (m) =>
        m.metricRef === sub.answer?.metricRef ||
        sub.reportingUnits?.some((ru) => ru.answer?.metricRef === m.metricRef)
    )?.materialMetricTags ?? [];
  const subParentMaterialMetrics =
    subMaterialMetrics?.find((m) =>
      row.parentMetric
        ? row.parentMetric?.reference === m.metricRef
        : row.metric.parentMetrics.some((pM) => pM.parentMetricRef === m.metricRef)
    )?.materialMetricTags ?? [];

  const subTags = uniqBy([...subMaterialMetricTags, ...subParentMaterialMetrics], 'tagType');

  const subHasTagsButNotRow = subTags.length && subTags.length > (row.tags?.length ?? 0);

  const materialRowTags = row.tags?.filter((tag) =>
    subTags.some(
      (subT) =>
        subT.tagType === tag.tagType &&
        subT.materialTagValues.some((tV) => tV.tagValue === tag.tagValue)
    )
  );

  const datapoint = sub.answer?.datapoints.find((dp) => {
    if (row.tagName) {
      return dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName);
    }

    if (row.tags) {
      return (
        dp.datapointTags.length === materialRowTags?.length &&
        dp.datapointTags.every((dpTag) =>
          materialRowTags?.some(
            (mt) => mt.tagType === dpTag.tagType && mt.tagValue === dpTag.tagValue
          )
        )
      );
    }

    return dp.datapointTags.length === 0;
  });

  let total = Number(datapoint?.value || 0);

  const isMonetaryDP =
    row.metric?.unitOfMeasurement === 'currency' ||
    row.metric?.unitOfMeasurement?.includes('Monetary unit') ||
    row.metric?.unitOfMeasurement?.includes('€');

  const subCurrency = sub.subsidiary.company.currency;
  const rate = currencyConversionData?.find((cr) => cr.from === subCurrency)?.rate;

  if (subHasTagsButNotRow) {
    if (sub.answer) {
      sub.answer?.datapoints
        .filter(
          (dp) =>
            dp.datapointTags.length &&
            subTags.every((subTag) =>
              dp.datapointTags.some(
                (dpTag) =>
                  dpTag.tagType === subTag.tagType &&
                  subTag.materialTagValues.some(
                    (subTagValue) => subTagValue.tagValue === dpTag.tagValue
                  )
              )
            )
        )
        .forEach((dp) => (total += Number(dp.value)));
    } else {
      sub.reportingUnits?.forEach((ru) => {
        ru.answer?.datapoints
          .filter(
            (dp) =>
              dp.datapointTags.length &&
              subTags.every((subTag) =>
                dp.datapointTags.some(
                  (dpTag) =>
                    dpTag.tagType === subTag.tagType &&
                    subTag.materialTagValues.some(
                      (subTagValue) => subTagValue.tagValue === dpTag.tagValue
                    )
                )
              )
          )
          .forEach((dp) => (total += Number(dp.value)));
      });
    }

    return isMonetaryDP ? String(convertCurrency(total, rate)) : String(isNaN(total) ? 0 : total);
  }

  if (row.tagName || row.tags?.length) {
    const materialRowTags = row.tags?.filter((tag) =>
      subTags.some(
        (subT) =>
          subT.tagType === tag.tagType &&
          subT.materialTagValues.some((tV) => tV.tagValue === tag.tagValue)
      )
    );
    sub.reportingUnits?.forEach((ru) => {
      const ruAnswer =
        Number(
          ru.answer?.datapoints.find((dp) =>
            row.tagName
              ? dp.datapointTags.some((dpTag) => dpTag.tagValue === row.tagName)
              : dp.datapointTags.length === materialRowTags?.length &&
                dp.datapointTags.every((dpTag) =>
                  materialRowTags?.some(
                    (mt) => mt.tagType === dpTag.tagType && mt.tagValue === dpTag.tagValue
                  )
                )
          )?.value
        ) ?? 0;

      if (!isNaN(ruAnswer)) total += ruAnswer;
    });

    if (materialRowTags?.length === subTags.length) {
      return isMonetaryDP ? String(convertCurrency(total, rate)) : String(isNaN(total) ? 0 : total);
    }

    return undefined;
  }

  if (sub.answer && !sub.reportingUnits?.filter((ru) => !!ru.answer).length) {
    return isMonetaryDP
      ? String(convertCurrency(Number(sub.answer?.datapoints[0]?.value), rate))
      : sub.answer?.datapoints[0]?.value;
  }

  sub.reportingUnits?.forEach((ru) => {
    const ruAnswer = Number(ru.answer?.datapoints?.[0]?.value);
    if (!isNaN(ruAnswer)) total = total + ruAnswer;
  });

  return isMonetaryDP ? String(convertCurrency(total, rate)) : String(isNaN(total) ? 0 : total);
};

const CollectedPopover = ({
  row,
  answersData,
  isGroup,
  progress,
  currencyConversionData,
}: {
  row: AggregatedMetricsTableData;
  answersData?: AggregatedQualitativeAnswers;
  isGroup: boolean;
  progress: number;
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion'];
}) => {
  const answerData = answersData?.find((data) => data.metricRef === row.metric?.reference);
  const { standardRef = '' } = useParams();

  const [isSubsidiaries, isBusinessUnits] = useMemo(
    () => [isFullMetricOnSubLevel(row.metric), isFullMetricOnBULevel(row.metric)],
    [row]
  );

  const isMixed = useMemo(
    () =>
      row.subRows?.length &&
      ((hasChildOnSubLevel(row.metric) && !isSubsidiaries) ||
        (hasChildOnBULevel(row.metric) && !isBusinessUnits)),
    [row]
  );

  const companyLevelTotal = useMemo(() => answerData?.answer?.datapoints[0]?.value, [answerData]);

  return (
    <VStack alignItems="start" justifyContent="start" p={0} spacing="16px">
      <VStack alignItems="start" w="100%" gap="8px">
        <HStack justifyContent="space-between" w="100%">
          <Typography variant="bodyStrong">Data collection progress</Typography>
          <Typography variant="h4">{progress}%</Typography>
        </HStack>
        <ProgressBar completed={progress} />
      </VStack>
      <VStack alignItems="start" w="100%" gap="8px">
        {isMixed && (
          <DetailsCard
            title={'Company level'}
            author={answerData?.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
            answer={companyLevelTotal}
            metricRef={answerData?.metricRef}
            reportingUnitId={answerData?.answer?.reportingUnit.id}
            isGroup={isGroup}
            isNumeric
            choices={answerData?.answer?.datapoints?.[0]?.datapointChoices.map(
              (c) => c.choice.title
            )}
          />
        )}
        {isSubsidiaries
          ? answerData?.subsidiaries?.map((sub) => {
              return (
                <DetailsCard
                  title={sub.subsidiary.company.name}
                  author={sub.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                  answer={getSubsidiaryAnswer(row, sub, standardRef, currencyConversionData)}
                  subsidiary={sub.subsidiary}
                  metricRef={answerData.metricRef}
                  isAggregated={!!row.subRows?.length}
                  isGroup={isGroup}
                  isNumeric
                  choices={sub.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
                />
              );
            })
          : isBusinessUnits || isMixed
            ? answerData?.reportingUnits?.map((ru) => (
                <DetailsCard
                  title={ru.reportingUnit.name}
                  author={ru.answer?.datapoints?.[0]?.owner?.displayName ?? ''}
                  answer={getBusinessUnitAnswer(row, ru)}
                  metricRef={answerData.metricRef}
                  reportingUnitId={ru.reportingUnit.id}
                  isGroup={isGroup}
                  isNumeric
                  choices={ru.answer?.datapoints?.[0]?.datapointChoices.map((c) => c.choice.title)}
                />
              ))
            : QUARTERS_FIELDS.map((quarter) => (
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <CalendarIcon />
                    <Typography variant="body" color="text.muted">
                      {quarter}
                    </Typography>
                  </HStack>
                  {row.result?.[quarter] ? (
                    <Typography variant="body" color="text.muted">
                      {parseFloat(row.result?.[quarter].toFixed(2))}
                    </Typography>
                  ) : (
                    <Typography variant="body" color="text.hint">
                      N/A
                    </Typography>
                  )}
                </HStack>
              ))}
      </VStack>
    </VStack>
  );
};

export const MetricProgress = ({
  row,
  answersData,
  companyReportingUnit,
  isGroup,
  currencyConversionData,
}: {
  row: MetricsTableData;
  isGroup: boolean;
  answersData?: AggregatedQualitativeAnswers;
  companyReportingUnit?: string;
  currencyConversionData?: GetAssessmentCurrencyConversionQuery_['esrs_AssessmentCurrencyConversion'];
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const progress = checkProgress(row, answersData, companyReportingUnit);

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack>
          <Circle size="16px" alignItems="center">
            <PieChart
              lineWidth={32}
              data={[
                {
                  title: 'Collected',
                  value: progress,
                  color: colors['bg.progress'][color],
                },
                {
                  title: 'Missing',
                  value: 100 - progress,
                  color: colors['bg.unknown'][color],
                },
              ]}
            />
          </Circle>
          <Typography variant="body" cursor="default">
            {Number.isNaN(progress) ? 0 : progress}%
          </Typography>
        </HStack>
      </PopoverTrigger>
      <PopoverContent
        w="326px"
        border="none"
        boxShadow=" 0px 0px 24px -2px #0F0F2E1F"
        borderRadius="10px"
      >
        <PopoverBody p="16px">
          <CollectedPopover
            row={row}
            answersData={answersData}
            isGroup={isGroup}
            progress={progress}
            currencyConversionData={currencyConversionData}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
