import { ColumnDefResolved } from '@tanstack/react-table';
import {
  AggregatedMetricsTableData,
  MetricsTableData,
  showResultAsPercentage,
} from '../../DisclosureRequirements';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { IconButton, Tag, TruncatableText } from 'Atoms';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { Typography } from 'Tokens';
import { MetricRowTitle } from '../../DisclosureRequirements/Metrics/MetricsTable/MetricsTableComponents/MetricRowTitle/MetricRowTitle';

export const ReportMetricTable = ({
  metrics,
  currency,
  standardId,
}: {
  metrics: AggregatedMetricsTableData[];
  currency: string;
  standardId: string;
}) => {
  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          header: 'Metric',
          accessorKey: 'metric',
          meta: {
            width: '80%',
          },
          cell: ({ row }) => (
            <HStack pl={`${row.depth * 24}px`} spacing="8px">
              {row.getCanExpand() ? (
                <IconButton
                  variant={'ghost'}
                  size="xs"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              ) : (
                <MetricTypeIcon type={MetricTypes.number} />
              )}
              <MetricRowTitle row={row} isReport={true} />
            </HStack>
          ),
        },
        {
          header: 'Unit',
          meta: {
            width: '100px',
          },
          accessorKey: 'unit',
          cell: ({ row }) => (
            <TruncatableText
              variant="body"
              text={mapUnitToCompanyCurrency(
                row.original.metric.unitOfMeasurement ?? 'NA',
                currency
              )}
            />
          ),
        },
        {
          header: 'Total',
          meta: {
            width: '15%',
          },
          accessorKey: 'yearly',
          cell: ({ row }) => (
            <HStack>
              <Typography variant="body">
                {isNaN(row.original.result?.Year ?? 0)
                  ? '--'
                  : Math.floor(row.original.result?.Year ?? 0)}
              </Typography>

              {row.original.metric.showAsPercentage && (
                <Typography variant="body">
                  {showResultAsPercentage(
                    row.original.result?.Year,
                    row.getParentRow()?.original.result?.Year
                  )}
                </Typography>
              )}
            </HStack>
          ),
        },
      ],
      [metrics, currency, standardId]
    );
  return (
    <NestedTable<MetricsTableData>
      columns={nestedColumns}
      data={metrics}
      expanded={true}
      withOuterBorder={true}
      borderRadius="0px"
      rowHeight="36px"
      defaultExpanded={true}
    />
  );
};
