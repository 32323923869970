import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Financials, useBusinessUnitLazyQuery_ } from 'models';
import { useFinancials } from 'Features/Financials/Financials.hooks.ts';
import { allFinancialSections } from 'utils/financials.ts';
import { useCurrentCompany } from 'utils/hooks/General.hooks.ts';

export enum FinancialFilter {
  all = 'all',
  byId = 'byId',
}

export const useGetFilterData = () => {
  const [searchParams] = useSearchParams();
  const [getBusinessUnit] = useBusinessUnitLazyQuery_();
  const { company } = useCurrentCompany();

  const [filter, setFilter] = useState(FinancialFilter.all);
  const [filteredByName, setFilteredByName] = useState('Company');
  const [filteredById, setFilteredById] = useState('company');

  useEffect(() => {
    const nodeType = searchParams.get('nodeType');
    const nodeId = searchParams.get('nodeId');

    if (nodeType === 'businessUnit' && nodeId) {
      getBusinessUnit({ variables: { id: nodeId } }).then((result) => {
        setFilter(FinancialFilter.byId);
        setFilteredByName(result.data?.businessUnit?.name ?? 'Business Unit');
        setFilteredById(nodeId);
      });
    } else {
      setFilter(FinancialFilter.all);
      setFilteredByName(company?.name ?? 'Company');
      setFilteredById(nodeId ?? 'company');
    }
  }, [searchParams, getBusinessUnit, company]);

  return { filter, filteredByName, filteredById };
};

export const useContainsNegativeNumbers = () => {
  const { financials } = useFinancials({});

  const financialsArray = [
    financials.notEligibleFinancials,
    ...financials.businessUnits.map((bu) => bu.financials),
    ...financials.businessUnits.flatMap((bu) => bu.activities.map((act) => act.financials)),
  ].filter((fin): fin is Financials => !!fin);

  return financialsArray.some((fin) =>
    Object.entries(fin)
      .filter(([label]) => (allFinancialSections as string[]).includes(label))
      .some(([_, val]) => val < 0)
  );
};
