import { HStack, VStack } from '@chakra-ui/react';
import { Button, Checkbox, Tooltip } from 'Atoms';
import { Loader, Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { MetricsTableData } from '../..';
import { useEffect, useState } from 'react';
import { TimePeriodsEnums } from '../../../Requirement';
import { useGetDatapointValues } from '../../MetricsTable/MetricsTableComponents/InputFields/QuantitativeInputs';
import { HelpIcon } from 'Tokens/Icons/Status';

export const AdditionalInfoModal = ({
  isOpen,
  onClose,
  metric,
  companyReportingUnit,
}: {
  isOpen: boolean;
  onClose: () => void;
  metric: MetricsTableData['metric'];
  companyReportingUnit: string | undefined;
}) => {
  const [before, setBefore] = useState(false);
  const [after, setAfter] = useState(false);
  const { onDatapointChange, answer, dataPointPerYear, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit ?? ''
  );

  useEffect(() => {
    setBefore(dataPointPerYear?.metadata?.before?.isVisible === true);
    setAfter(dataPointPerYear?.metadata?.after?.isVisible === true);
  }, [dataPointPerYear]);

  const onConfirm = () => {
    const data = dataPointPerYear?.metadata;
    const metadata = {
      ...data,
      before: {
        ...(data?.before ?? {}),
        isVisible: before,
      },
      after: {
        ...(data?.after ?? {}),
        isVisible: after,
      },
    };
    onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: dataPointPerYear?.factValue,
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
      metadata: metadata,
    }).then(() => onClose());
  };

  if (loading) return <Loader />;

  return (
    <Modal
      isOpen={isOpen}
      isCentered={true}
      onClose={onClose}
      title="Additional information"
      onConfirm={onConfirm}
      actions={[
        <Tooltip
          minW={500}
          label="The additional information corresponds to entity-specific information, which is added where the granularity of the ESRS is not sufficient to disclose material information. Example: Data point 16 is in the ESRS covered through the related data points 16 a-d. However, an undertaking might find that it needs to introduce additional information in order to sufficiently disclose the material information on 16. The undertaking could in this case add the additional information before and/or after 16 (a)-(d), which will be regarded as entity-specific information on data point 16."
        >
          <Button leftIcon={<HelpIcon />} variant={'ghost'}>
            Learn more
          </Button>
        </Tooltip>,
      ]}
    >
      <VStack alignItems="start" spacing="16px">
        <Typography variant="body">
          If additional information is needed, choose whether to add it at the start or end of the
          paragraph. Selecting “Start of paragraph” places it before the indented data points, while
          “End of paragraph” places it after them.
        </Typography>
        <VStack alignItems="start" spacing="12px">
          <Typography variant="bodyStrong">Add complimentary texts:</Typography>
          <HStack spacing="8px">
            <Checkbox isChecked={before} onChange={(e) => setBefore(e.target.checked)} />
            <Typography variant="body">Start of paragraph</Typography>
          </HStack>
          <HStack spacing="8px">
            <Checkbox isChecked={after} onChange={(e) => setAfter(e.target.checked)} />
            <Typography variant="body">End of paragraph</Typography>
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  );
};
