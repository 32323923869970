import { ActivityResults, ObjectiveKeyEnum } from 'models';
import { SCEligibility } from './table-generator';
import { enablingTagGoesToAdaptation } from './TaxonomyTableGenerator.hooks';

const SCObjectivesToAbbreviationsMap: { [key: string]: string } = {
  mitigation: 'CCM',
  adaptation: 'CCA',
  water: 'WTR',
  circular: 'CE',
  pollution: 'PPC',
  biodiversity: 'BIO',
};

export type SubstantialContribution = {
  [key: string]: { eligibility: SCEligibility; financial: number };
};

const OBJECTIVE_SPLIT = ', ';

const getEligibleAligned = (
  activityResult: ActivityResults,
  type: string,
  objective: string
): number => {
  if (!activityResult.isMSSGAligned) {
    return 0;
  }
  const financials = activityResult?.cachedResult?.financials[type];
  if (
    objective === ObjectiveKeyEnum.adaptation ||
    objective === 'adaptationOnly' ||
    objective === 'adaptationEnabling'
  ) {
    if (type === 'revenue') {
      return 0;
    } else {
      return financials.adaptation;
    }
  } else if (objective === 'adaptationOnlyEnabling') {
    return financials.aligned ?? 0;
  }
  const resultsContainAdaptation =
    activityResult?.cachedResult?.objectivesState?.substantialContributionObjectives?.includes(
      ObjectiveKeyEnum.adaptation
    );
  if (resultsContainAdaptation) {
    // If financials are negative, we shouldn't "double count" them and subtract both adaptation and aligned
    if (financials.adaptation < 0 && financials.aligned < 0) {
      return financials.aligned ?? 0;
    }
    return (financials.aligned ?? 0) - (financials.adaptation ?? 0);
  } else {
    return financials.aligned ?? 0;
  }
};

const getEligibleNotAligned = (activityResult: ActivityResults, type: string): number => {
  const financials = activityResult?.cachedResult?.financials[type];
  if (!activityResult.cachedResult?.isAligned || !activityResult.isMSSGAligned) {
    return financials?.eligible ?? 0;
  } else {
    return (financials?.inProgress ?? 0) + (financials?.notAligned ?? 0);
  }
};

export const getActivityName = (activityRef: string, activities: any): string => {
  let activityName = activities[activityRef]?.name ?? '';
  const activityNumber = activities[activityRef]?.referenceNumber;
  if (!activityName.startsWith(activityNumber) && !!activityNumber) {
    activityName = `${activityNumber}. ${activityName}`;
  }
  return activityName;
};

export const getCode = (
  activityRef: string,
  activities: any,
  substantialContributions: SubstantialContribution,
  type: string,
  results?: ActivityResults[]
): string => {
  const activityNumber = activities[activityRef]?.referenceNumber;

  const substantialContributionObjectives: string[] =
    results?.[0]?.cachedResult?.objectivesState?.possibleSubstantialContributionObjectives ?? [];

  const hasAdaptationFinancials = (result: ActivityResults): boolean => {
    const financials = result?.cachedResult?.financials;
    if (financials?.capex?.adaptation === 0 || financials?.opex?.adaptation === 0) {
      return false;
    } else {
      return true;
    }
  };
  const anyResultsHaveAdaptationFinancials = results?.some(hasAdaptationFinancials);
  const codes: string[] = substantialContributionObjectives
    .map((objective: string) => {
      if (
        objective === ObjectiveKeyEnum.adaptation &&
        !anyResultsHaveAdaptationFinancials &&
        substantialContributionObjectives.length > 1 &&
        substantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation) &&
        type === 'revenue'
      ) {
        return undefined;
      }
      if (substantialContributions[objective]?.eligibility !== SCEligibility.nonEligible) {
        return objective;
      }
      return undefined;
    })
    .filter((objective: string | undefined): objective is string => objective !== undefined);

  if (codes.length > 0) {
    const objectivesString: string[] = codes
      .map((objective: string) => {
        if (!!SCObjectivesToAbbreviationsMap[objective] && !!activityNumber) {
          //Special cases for 7.1 and 7.2 circular objectives
          if (activityNumber === '7.1' && objective === ObjectiveKeyEnum.circular) {
            return `${SCObjectivesToAbbreviationsMap[objective]} 3.1`;
          } else if (activityNumber === '7.2' && objective === ObjectiveKeyEnum.circular) {
            return `${SCObjectivesToAbbreviationsMap[objective]} 3.2`;
          } else {
            return `${SCObjectivesToAbbreviationsMap[objective]} ${activityNumber}`;
          }
        } else return '';
      })
      .filter((objective: string) => objective !== '');
    if (objectivesString.length > 1) {
      return objectivesString.join(OBJECTIVE_SPLIT);
    } else if (objectivesString.length === 1) {
      return objectivesString[0];
    }
  }
  return '';
};

export const getEligibleAlignedAbsolute = (
  results: ActivityResults[],
  type: string,
  objective: string
): number => {
  let total = 0;
  results.forEach((result) => {
    total += getEligibleAligned(result, type, objective);
  });
  return total;
};

export const getEligibleNotAlignedAbsolute = (results: ActivityResults[], type: string): number => {
  let total = 0;
  results.forEach((result) => {
    total += getEligibleNotAligned(result, type);
  });
  return total;
};

export const calculateEnablingAdaptationSubstantialContribution = (
  results: ActivityResults[],
  type: string,
  total: number
): SubstantialContribution => {
  const financials = results.reduce((acc, result) => {
    return acc + getEligibleAligned(result, type, 'adaptationOnlyEnabling');
  }, 0);

  return {
    adaptation: {
      eligibility: SCEligibility.contributing,
      financial: total === 0 ? 0 : financials / total,
    },
  };
};

export const calculateAdaptationOnlySubstantialContribution = (
  results: ActivityResults[],
  type: string,
  total: number
): SubstantialContribution => {
  const financials = results.reduce((acc, result) => {
    return acc + getEligibleAligned(result, type, ObjectiveKeyEnum.adaptation);
  }, 0);
  return {
    adaptation: {
      eligibility: SCEligibility.contributing,
      financial: total === 0 ? 0 : financials / total,
    },
  };
};

export const calculateNonAlignedSubstantialContribution = (
  results: ActivityResults[],
  type: string,
  total: number
): SubstantialContribution => {
  const substantialContributionMap: SubstantialContribution = {
    adaptation: { eligibility: SCEligibility.nonEligible, financial: 0 },
    mitigation: { eligibility: SCEligibility.nonEligible, financial: 0 },
    water: { eligibility: SCEligibility.nonEligible, financial: 0 },
    circular: { eligibility: SCEligibility.nonEligible, financial: 0 },
    pollution: { eligibility: SCEligibility.nonEligible, financial: 0 },
    biodiversity: { eligibility: SCEligibility.nonEligible, financial: 0 },
  };

  const possibleSCObjectives: string[] =
    results[0].cachedResult?.objectivesState?.possibleSubstantialContributionObjectives;

  possibleSCObjectives.forEach((objective) => {
    substantialContributionMap[objective].eligibility = SCEligibility.eligible;
    substantialContributionMap[objective].financial = results.reduce((acc, result): number => {
      return acc + getEligibleNotAligned(result, type);
    }, 0);
  });

  if (results[0].cachedResult?.activityTag !== 'ENABLING' && possibleSCObjectives.length > 1) {
    if (type === 'revenue') {
      substantialContributionMap.adaptation.eligibility = SCEligibility.nonEligible;
      substantialContributionMap.adaptation.financial = 0;
    }
  }

  return {
    adaptation: {
      eligibility: substantialContributionMap.adaptation.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.adaptation.financial / total,
    },
    mitigation: {
      eligibility: substantialContributionMap.mitigation.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.mitigation.financial / total,
    },
    water: {
      eligibility: substantialContributionMap.water.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.water.financial / total,
    },
    circular: {
      eligibility: substantialContributionMap.circular.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.circular.financial / total,
    },
    pollution: {
      eligibility: substantialContributionMap.pollution.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.pollution.financial / total,
    },
    biodiversity: {
      eligibility: substantialContributionMap.biodiversity.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.biodiversity.financial / total,
    },
  };
};

export const calculateAlignedSubstantialContribution = (
  results: ActivityResults[],
  type: string,
  total: number,
  rowObjective: string,
  activityRef: string
): SubstantialContribution => {
  const substantialContributionMap: SubstantialContribution = {
    adaptation: { eligibility: SCEligibility.nonEligible, financial: 0 },
    mitigation: { eligibility: SCEligibility.nonEligible, financial: 0 },
    water: { eligibility: SCEligibility.nonEligible, financial: 0 },
    circular: { eligibility: SCEligibility.nonEligible, financial: 0 },
    pollution: { eligibility: SCEligibility.nonEligible, financial: 0 },
    biodiversity: { eligibility: SCEligibility.nonEligible, financial: 0 },
  };
  const SCObjectives: string[] =
    results[0].cachedResult?.objectivesState?.substantialContributionObjectives;
  const possibleSCObjectives: string[] =
    results[0].cachedResult?.objectivesState?.possibleSubstantialContributionObjectives;
  const rowObjectives: string[] = rowObjective.split('-');

  if (rowObjectives.includes('enabling')) {
    rowObjectives.splice(rowObjectives.indexOf('enabling'), 1);
  }
  if (rowObjectives.includes('adaptationEnabling')) {
    rowObjectives.splice(rowObjectives.indexOf('adaptationEnabling'), 1);
    rowObjectives.push(ObjectiveKeyEnum.adaptation);
  }
  if (rowObjectives.includes('transitional')) {
    rowObjectives.splice(rowObjectives.indexOf('transitional'), 1);
  }

  const notContributingObjectives: string[] = possibleSCObjectives.filter(
    (objective) => !SCObjectives.includes(objective)
  );

  if (rowObjectives.length > 1 && rowObjectives.includes(ObjectiveKeyEnum.mitigation)) {
    substantialContributionMap.mitigation.financial = results.reduce((acc, result) => {
      return acc + getEligibleAligned(result, type, ObjectiveKeyEnum.mitigation);
    }, 0);
  } else {
    rowObjectives.forEach((objective) => {
      substantialContributionMap[objective].financial = results.reduce((acc, result) => {
        return acc + getEligibleAligned(result, type, objective);
      }, 0);
    });
  }

  SCObjectives.forEach((objective) => {
    (substantialContributionMap as any)[objective].eligibility = SCEligibility.contributing;
  });
  notContributingObjectives.forEach((objective) => {
    (substantialContributionMap as any)[objective].eligibility = SCEligibility.notContributing;
  });

  if (type === 'revenue' && !enablingTagGoesToAdaptation.includes(activityRef)) {
    substantialContributionMap.adaptation.eligibility = SCEligibility.nonEligible;
    substantialContributionMap.adaptation.financial = 0;
  }

  return {
    adaptation: {
      eligibility: substantialContributionMap.adaptation.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.adaptation.financial / total,
    },
    mitigation: {
      eligibility: substantialContributionMap.mitigation.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.mitigation.financial / total,
    },
    water: {
      eligibility: substantialContributionMap.water.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.water.financial / total,
    },
    circular: {
      eligibility: substantialContributionMap.circular.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.circular.financial / total,
    },
    pollution: {
      eligibility: substantialContributionMap.pollution.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.pollution.financial / total,
    },
    biodiversity: {
      eligibility: substantialContributionMap.biodiversity.eligibility,
      financial: total === 0 ? 0 : substantialContributionMap.biodiversity.financial / total,
    },
  };
};

export const checkEnabling = (objective: string): 'E' | undefined => {
  if (objective.toLowerCase().includes('enabling')) {
    return 'E';
  }
};

export const checkTransitional = (objective: string): 'T' | undefined => {
  if (objective.split('-').includes('transitional')) {
    return 'T';
  }
};

export const getContribution = (
  alignedObjective: SubstantialContribution[string],
  nonAlignedObjective: SubstantialContribution[string]
) => {
  if (!!alignedObjective) {
    return alignedObjective;
  } else if (nonAlignedObjective.eligibility === SCEligibility.eligible) {
    return SCEligibility.notContributing;
  }
  return SCEligibility.nonEligible;
};
